import React from "react";
// Customizable Area Start
import {
  ScrollView,
  SafeAreaView,
  StyleSheet,
} from "react-native";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Autocomplete,
  AutocompleteProps,
  ListItemText,
  ListItem,
  Paper,
  PaperProps
} from "@mui/material";

import scale, { verticalScale } from "../../../components/src/Scale";
import PostCreationController, { CityList, Props, configJSON } from "./PostCreationController.web";
import Scale from "../../../components/src/Scale";
import HeaderNavigation from "../../../blocks/navigationmenu/src/HeaderNavigation.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";

import LoadTextInput from "../../../components/src/LoadTextInput";
import CustomButton from "../../../components/src/CustomButton.web.";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { KeyboardArrowDown } from "@mui/icons-material";
import { styled } from '@mui/material/styles';

const webStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    overflowX: 'hidden'
  },
  content: {
    display: "flex",
    flex: 1,
  },
  mainContent: {
    flex: 1,
    padding: "2rem",
    backgroundColor: '#E2E8F0',
  },
  cardContainer: {
    backgroundColor: '#FFFFFF',
    border: '2px solid #215089',
    borderRadius: '16px',
    padding: '2rem',
  },
  headerFont: {
    fontFamily: "Inter",
    fontSize: '30px',
    fontWeight: 700,
    color: "#215089"
  },

  title: {
    fontFamily: "Inter",
    fontSize: '16px',
    fontWeight: 400,
    color: "#0F172A",
    marginBottom:"10px"
  },
  titleBold: {
    fontFamily: "Inter",
    fontSize: '16px',
    fontWeight: 700,
    color: "#0F172A",
    marginTop: '40px',
    marginBottom: '16px'
  },
  cardBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    marginTop: '2%',
    marginBottom: '5%'
  },
  cardStyle: {
    backgroundColor: '#E9EEF3',
    borderRadius: '8px',
    padding: '20px 22px',
    boxShadow: '0px 4px 4px 0px #00000040',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#FEF5EC',
      '& .MuiSvgIcon-root': {
        color: '#B57730',
      },
      '& .primaryText': {
        color: '#B57730',
      },
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  listPrimaryText: {
    fontFamily: "Inter",
    fontSize: '18px',
    fontWeight: 700,
    color: "#0F172A"
  },
  listSecondaryText: {
    fontFamily: "Inter",
    fontSize: '16px',
    fontWeight: 400,
    color: "#334155",
    marginTop: '5px'
  },
  input: {
    height: '56px',
    radius: '8px',
    padding: '10px 8px 10px 8px',
    marginLeft: '-10px',
    marginRight: '40px',
    width: '327px'
  },
  modalBackdrop: {
    position: 'fixed' as 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',

  },
  modalContent: {
    backgroundColor: '#fff',
    borderRadius: '8px 8px 8px 8px',
    width: '371px',
    padding: '0px 20px',
    marginBottom: '100px',
  },
};

const StyledStateAutocomplete = styled((props: AutocompleteProps<CityList, false, false, false>) => (
  <Autocomplete {...props} />
))({
  '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      color: '#475569',
      fontSize: '16px',
      background:"#fff",
      paddingRight:"10px!important",
      display:"flex",
      justifyContent:"space-between",
      fontFamily: 'Inter',
      fontWeight: 400,
      '&.Mui-focused fieldset': {
          borderColor: '#CBD5E1',
      },
      '& fieldset': {
          border: '1px solid #CBD5E1',
      },
      '&:hover fieldset': {
          borderColor: '#CBD5E1',
      },
  },
  '& .MuiInputBase-input': {
      background:"#fff",
      padding: '10px 8px'
  },
  ".css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator":{
      display:"none"
  },
});

const CustomPaper = (props:PaperProps) => {
  return <Paper elevation={8} {...props} />;
};

type OnHandleChance = (event: React.ChangeEvent<{}>, newValue: CityList | null) => void;

function SelectState(onHandleChance: OnHandleChance) {
  return <StyledStateAutocomplete
    id="country-select-demo"
    data-test-id="selectCity"
    options={configJSON.StatesName}
    autoHighlight
    PaperComponent={CustomPaper}
    onChange={onHandleChance}
    getOptionLabel={(option) => option.name}
    renderOption={(props, option) => {
      return (
        <ListItem
          {...props}
          style={{
            padding: '12px 16px',
            borderRadius: '8px',
            cursor: 'pointer',
          }}
        >
          <ListItemText
            sx={{ color: '#0F172A', fontSize: '14px', fontWeight: 400, fontFamily: 'Inter' }}
            primary={option?.name}
          />
        </ ListItem>
      );
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        placeholder="State"
        InputProps={{
          ...params?.InputProps,
          endAdornment: (<KeyboardArrowDown />)
        }}
        variant="outlined"
      />
    )}
  />
}

// Customizable Area End

export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <SafeAreaView style={styles.safeAreaView}>
        <ScrollView>
          {/* Customizable Area Start */}
          <ScrollView>
            <>
              <HeaderNavigation navigation={this.props.navigation} id={""} />
              <Box sx={webStyles.content}>
                <NavigationMenu navigation={this.props.navigation} id={""} />
                <Box sx={webStyles.mainContent}>
                  <Box sx={webStyles.cardContainer}>
                    <Typography style={webStyles.headerFont}>Create Load</Typography>
                    <Box style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: 'wrap',
                      gap: '40px'
                    }}>
                      <LoadTextInput
                        name='weight'
                        title="Load Weight"
                        placeholder="2 Tons"
                        required={true}
                        isTextInputShown={true}
                        value={this.state.weight}
                        onChange={this.handleInputChange}
                        errorMsg={this.state.errorMsg.weightErrorMsg}
                        maxLength={6}
                      />
                      <LoadTextInput
                        name="miles"
                        title="Total Miles"
                        placeholder="5 KM"
                        required={true}
                        isTextInputShown={true}
                        value={this.state.miles}
                        onChange={this.handleInputChange}
                        errorMsg={this.state.errorMsg.milesErrorMsg}
                        maxLength={4}
                      />
                      <LoadTextInput
                        name="commodity"
                        title="Commodity"
                        placeholder="Goods"
                        required={true}
                        isTextInputShown={true}
                        value={this.state.commodity}
                        onChange={this.handleInputChange}
                        errorMsg={this.state.errorMsg.commodityErrorMsg}
                      />
                    </Box>

                    <Typography style={webStyles.titleBold}>Origin Address <span style={{ color: 'red' }}>*</span> :</Typography>

                    <Box style={{
                      display: "flex",
                      flexWrap: 'wrap',
                      flexDirection: "row",
                      gap: '40px'
                    }}>
                      <LoadTextInput
                        name='originAddress'
                        title="Address"
                        placeholder="Enter address"
                        isTextInputShown={true}
                        value={this.state.originAddress}
                        onChange={this.handleInputChange}
                        errorMsg={this.state.errorMsg.originAddressErrorMsg}
                      />
                      <LoadTextInput
                        name='originCity'
                        title="City"
                        placeholder="City"
                        isTextInputShown={true}
                        value={this.state.originCity}
                        onChange={this.handleInputChange}
                        errorMsg={this.state.errorMsg.originCityErrorMsg}
                      />

                      <Box sx={{ width: "327px" }}>
                        <Typography sx={webStyles.title}>State</Typography>
                          {SelectState(this.handleOriginCityChange)}
                       </Box>

                    </Box>

                    <Box style={{
                      display: "flex",
                      flexWrap: 'wrap',
                      flexDirection: "row",
                      gap: '40px',
                      marginTop: '40px'
                    }}>


                      <LoadTextInput
                        disabled = {true}
                        name='originCountry'
                        title="Country"
                        placeholder="Country"
                        isTextInputShown={true}
                        value={this.state.originCountry}
                        onChange={this.handleInputChange}
                        errorMsg={this.state.errorMsg.originCountryErrorMsg}
                      />


                      <LoadTextInput
                        name='originZipcode'
                        title="Zipcode"
                        data-test-id="originZipcode"
                        placeholder="Zipcode"
                        isTextInputShown={true}
                        value={this.state.originZipcode}
                        onChange={this.handleInputChange}
                        errorMsg={this.state.errorMsg.originZipcodeErrorMsg}
                      />
                    </Box>

                    <Typography style={webStyles.titleBold}>Destination Address <span style={{ color: 'red' }}>*</span> :</Typography>

                    <Box style={{
                      display: "flex",
                      flexWrap: 'wrap',
                      flexDirection: "row",
                      gap: '40px'
                    }}>
                      <LoadTextInput
                        name='desAddress'
                        title="Address"
                        placeholder="Enter address"
                        isTextInputShown={true}
                        value={this.state.desAddress}
                        onChange={this.handleInputChange}
                        errorMsg={this.state.errorMsg.desAddressErrorMsg}
                      />

                      <LoadTextInput
                        name='desCity'
                        title="City"
                        placeholder="City"
                        isTextInputShown={true}
                        value={this.state.desCity}
                        onChange={this.handleInputChange}
                        errorMsg={this.state.errorMsg.desCityErrorMsg}
                      />

                      <Box sx={{ width: "327px" }}>
                        <Typography sx={webStyles.title}>State</Typography>
                        {SelectState(this.handleDestinationCityChange)}
                      </Box>
                    </Box>

                    <Box style={{
                      display: "flex",
                      flexWrap: 'wrap',
                      flexDirection: "row",
                      gap: '40px',
                      marginTop: '40px'
                    }}>

                      <LoadTextInput
                        name='desCountry'
                        title="Country"
                        disabled={true}
                        placeholder="Country"
                        isTextInputShown={true}
                        value={this.state.desCountry}
                        onChange={this.handleInputChange}
                        errorMsg={this.state.errorMsg.desCountryErrorMsg}
                      />

                      <LoadTextInput
                        name='desZipcode'
                        title="Zipcode"
                        placeholder="Zipcode"
                        isTextInputShown={true}
                        value={this.state.desZipcode}
                        onChange={this.handleInputChange}
                        errorMsg={this.state.errorMsg.desZipcodeErrorMsg}
                      />
                    </Box>

                    <Box style={{
                      display: "flex",
                      flexWrap: 'wrap',
                      flexDirection: "row",
                      gap: '40px',
                      marginTop: '40px',
                    }}>
                      <LoadTextInput
                        name='startDate'
                        data-test-id = "startDate"
                        title="Start Date"
                        placeholder="Enter address"
                        required={true}
                        isDatePickerShown={true}
                        datePickerLabel="select date"
                        value={this.formateDate(this.state.endDate,"YYYY-MM-DD")}
                        onChange={this.handleDateChange}
                        errorMsg={this.state.errorMsg.startDateErrorMsg}
                      />
                      <LoadTextInput
                        name='endDate'
                        data-test-id = "endDate"
                        title="End Date"
                        placeholder="End Date"
                        required={true}
                        isDatePickerShown={true}
                        datePickerLabel="select date"
                        value={this.formateDate(this.state.endDate,"YYYY-MM-DD")}
                        onChange={this.handleDateChange}
                        errorMsg={this.state.errorMsg.endDateErrorMsg}
                      />
                      <LoadTextInput
                        name='rate'
                        title="Rate"
                        placeholder="Enter rate"
                        required={true}
                        isTextInputShown={true}
                        value={this.state.rate}
                        onChange={this.handleInputChange}
                        errorMsg={this.state.errorMsg.rateErrorMsg}
                      />
                    </Box>

                    <Box style={{
                      display: "flex",
                      flexWrap: 'wrap',
                      flexDirection: "row",
                      gap: '40px',
                      marginTop: '43px',
                    }}>
                      <LoadTextInput
                        data-test-id="emailWrapper"
                        dataTestId="email"
                        name="email"
                        title="Email Address"
                        placeholder="Enter email address"
                        isTextInputShown={true}
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        errorMsg={this.state.errorMsg.emailErrorMsg}
                      />
                      <LoadTextInput
                        data-test-id="phoneNumber"
                        name="phoneNo"
                        title="Load Broker Phone number"
                        placeholder="Enter phone number"
                        required={true}
                        isTextInputShown={true}
                        value={this.state.phoneNo}
                        onChange={this.handleInputChange}
                        errorMsg={this.state.errorMsg.phoneNoErrorMsg}
                      />
                      <LoadTextInput
                        name="creditScore"
                        title="Load Broker Credit Score"
                        placeholder="Enter credit score"
                        required={false}
                        isTextInputShown={true}
                        value={this.state.creditScore}
                        onChange={this.handleInputChange}
                        errorMsg={this.state.errorMsg.creditScoreErrorMsg}
                      />
                    </Box>

                    <Box style={{
                      display: "flex",
                      flexWrap: 'wrap',
                      flexDirection: "column",
                      gap: '15px',
                      marginTop: '43px',
                    }}>
                      <LoadTextInput
                        name="loadDetails"
                        title="Load Details"
                        placeholder="You must enter here"
                        isTextInputShown={true}
                        value={this.state.loadDetails}
                        onChange={this.handleInputChange}
                        errorMsg={this.state.errorMsg.loadDetailsErrorMsg}
                        width="700px"
                        height="109px"
                        multiline={true}
                        rows={4}
                        maxLength={100}
                      />

                      <span style={{
                        color: '#475569',
                        fontSize: '14px',
                        fontFamily: "Inter",
                        lineHeight: '18px'
                      }}>100 characters max</span>
                    </Box>

                    <div style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '20px',
                    }}>
                      <button
                        style={{
                          padding: '10px 20px',
                          marginRight: '10px',
                          border: 'none',
                          borderRadius: '8px',
                          cursor: 'pointer',
                          fontFamily: 'inter',
                          fontSize: '16px',
                          fontWeight: 700,
                          color: '#B57730',
                          width: '150px', backgroundColor: '#FDF1E2',
                        }}
                        onClick={() => { }}>CANCEL</button>

                      <div style={{ width: '150px' }}>
                        <CustomButton
                          data-test-id="postBtnWrapper"
                          text="POST"
                          testId="postBtn"
                          onClick={() => { this.createPostCreation() }}
                          customeWidth={true}
                        />
                      </div>
                    </div>
                  </Box>


                </Box>
              </Box>
            </>
            {this.state.showModal && <div style={webStyles.modalBackdrop}>
              <div style={webStyles.modalContent}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}>

                  <IconButton disabled>
                    <CheckCircleRoundedIcon sx={{ color: '#34D399' }} />
                  </IconButton>
                  <p style={{ fontSize: '20px', fontWeight: 400, fontFamily: "Inter", color: '#0F172A' }}>
                    Load create successfully
                  </p>
                  <IconButton aria-label="close" onClick={() => this.closeModal()}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </div>}
          </ScrollView>
          {/* Customizable Area End */}
        </ScrollView>
      </SafeAreaView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
  },
  inputTextField: {
    height: verticalScale(80),
    borderColor: "grey",
    borderWidth: 1,
    color: "black",
    fontSize: scale(16.7),
    borderBottomWidth: 0.3,
    margin: scale(3),
    padding: 10,
    marginTop: verticalScale(5)
  },

  inputTextField1: {
    height: verticalScale(50),
    borderColor: "grey",
    borderWidth: 1,
    color: "black",
    fontSize: scale(16.7),
    borderBottomWidth: 0.3,
    paddingLeft: 10,
    paddingRight: 10,
    margin: scale(3)
  },

  buttonStyle: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 22,
    backgroundColor: "#366ef9",
    height: scale(50),
    marginTop: verticalScale(20)
  },
  imageStyle: {
    width: scale(100),
    height: scale(100),
    borderRadius: scale(50),
    borderWidth: 1,
    borderColor: "black",
    marginTop: verticalScale(5),
    marginBottom: verticalScale(25),
    opacity: 0.6
  },
  buttonText: {
    color: "white"
  },
  editIcon: {
    position: "absolute",
    height: scale(20),
    width: scale(20),
    resizeMode: "contain",
    top: verticalScale(90),
    left: Scale(90)
  },

});
// Customizable Area End
