import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { SelectChangeEvent } from '@mui/material/Select'
import { City } from "./LoadBrokerSearchController.web";
import moment from "moment";

export interface AllLoads {
    id: number;
    attributes: LoadsAttributes
}

export interface LoadsAttributes {
    id: number;
    load_weight: string;
    total_miles: string;
    origin_address: {
        city: string;
        state: string;
        street: string;
        country: string;
        zip_code: string;
        door_number: string
    },
    destination_address: {
        city: string;
        state: string;
        street: string;
        country: string;
        zip_code: string;
        door_number: string;
    },
    start_date: string;
    end_date: string;
    price: string;
    load_broker_credit_score: string;
    load_broker_phone_number: string;
    created_at: string;
    updated_at: string;
    commodity?:string;
    details?:string
}

export interface Pagination {
    current_page: number,
    next_page: number,
    prev_page: number,
    total_count: number,
    total_pages: number
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    selectedValue: string;
    selectedOrigin: City;
    selectedDestination: City;
    startDate: Date | null;
    endDate: Date | null;
    allLoadsdata: AllLoads[];
    currentPage: number;
    itemsPerPage: number;
    loadDetails: LoadsAttributes | null;
    selectedLoadId: number | null;
    distanceFrom: string;
    distanceTo: string;
    loadWeightFrom: string;
    loadWeightTo: string;
    payRangeFrom: string;
    payRangeTo: string;
    creditScoreFrom: string;
    creditScoreTo: string;
    cityOptions:City[],
    originInputValue:string,
    destinationInputValue:string,
    originValue:City|null,
    destinationValue:City|null,
    totalPage:number,
    totalCount:number,
    loading:boolean,

    // Customizable Area End
}
interface SS { }

export default class LoadBoardListViewController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    showLoadDetailsAPICallId: string = "";
    getSearchedCitiesAPICallId: string = "";
    getSearchedByFilterAPICallId: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            selectedValue: 'van',
            selectedOrigin: {} as City,
            selectedDestination: {} as City,
            startDate: null,
            endDate: null,
            allLoadsdata: [],
            currentPage: 1,
            itemsPerPage: 4,
            loadDetails: null,
            selectedLoadId: null,
            distanceFrom: "",
            distanceTo: "",
            loadWeightFrom: "",
            loadWeightTo: "",
            payRangeFrom: "",
            payRangeTo: "",
            creditScoreFrom: "",
            creditScoreTo: "",
            cityOptions: [],
            originInputValue:"",
            destinationInputValue:"",
            originValue: null,
            destinationValue: null,
            totalPage: 0,
            totalCount: 0,
            loading:true,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.initialLoad();
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let webResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            switch (webApiRequestCallId) {
                case this.getSearchedByFilterAPICallId:
                    this.handleAllLoadsAPIResponse(webResponseJson);
                    break;

                case this.showLoadDetailsAPICallId:
                    this.handleLoadDetailsAPIResponse(webResponseJson);
                    break;

                case this.getSearchedCitiesAPICallId: 
                   this.setState({ cityOptions: webResponseJson.data });
                   break;
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start

    initialLoad = async () => {
        let searchByCity = await getStorageData("searchByCity");
        searchByCity = await JSON.parse(searchByCity)
        this.setState({
            selectedOrigin: searchByCity.distanceFrom,
            selectedDestination: searchByCity.distanceTo,
            originValue: searchByCity.distanceFrom,
            destinationValue: searchByCity.distanceTo
        }, () => {
            this.searchByFilter()
         }
        )
    }

    dateFormate = (date: string, format:string) => {
       return moment(date).format(format);
    }
    
    handleCardClick = (loadIdNumber : number) => {
        this.setState({ selectedLoadId : loadIdNumber });
        this.showLoadDetails(loadIdNumber) ;
    };

    handleChange = (event : SelectChangeEvent) => {
        this.setState({ selectedValue: event?.target?.value });
    };

    handleStartDateChange = (date: Date | null) => {
        this.setState({ startDate: date });
    }

    handleEndDateChange = (date: Date | null) => {
        this.setState({ endDate: date });
    };

    handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        this.setState({ currentPage: page }, () => {
            this.searchByFilter()
         }
        );
    }

    showLoadDetails = async (index: number) => {
        const header = {
            "Content-Type": configJSON.advancedsearchApiContentType,
            token: await getStorageData("LoginToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.showLoadDetailsAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.allLoadsAPIEndPoint}/${index}`);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleAllLoadsAPIResponse = (webResponseJson:{loads: { data: AllLoads[] },meta:Pagination}) => {
        if (webResponseJson) {

            const loadsData = webResponseJson.loads.data;
            const metaData = webResponseJson.meta;
            const firstLoadId = loadsData.length > 0 ? loadsData[0].id : null;
            
            this.setState({
                allLoadsdata: loadsData,
                selectedLoadId: firstLoadId,
                currentPage:metaData.current_page,
                totalPage: metaData.total_pages,
                totalCount:metaData.total_count,
                originValue: this.state.selectedOrigin,
                destinationValue: this.state.selectedDestination,
                loading:false,
            }, () => {
                if (firstLoadId) {
                    this.showLoadDetails(firstLoadId);
                }
            });
        }

    }

    handleLoadDetailsAPIResponse = (webResponseJson: { data: LoadsAttributes }) => {
            
        if (webResponseJson) {
            this.setState({ loadDetails: webResponseJson.data, loading:false });
        }
    }

    isValidInput = (value: string): boolean => {
        return /^(\d+(\.\d*)?|\.\d+)$/.test(value);
    };

    handleChangeDistanceFrom = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({distanceFrom:event.target.value});
    }

    handleChangeDistanceTo = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({distanceTo:event.target.value});
    }

    handleChangeLoadWeightFrom = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({loadWeightFrom:event.target.value});
    }

    handleChangeLoadWeightTo = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({loadWeightTo:event.target.value});
    }

    handleChangePayRangeFrom = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({payRangeFrom:event.target.value});
    }

    handleChangePayRangeTo = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({payRangeTo:event.target.value});
    }
    
    handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
       this.setState({ originInputValue: value },
            () => this.getAllSearchedCities(true)
        );
    };

    handleDestinationInputChange = (event: React.ChangeEvent<{}>, value: string) => {
        this.setState({ destinationInputValue: value },
            () => this.getAllSearchedCities(false)
        );
    };

    handleCityChange = (event: React.ChangeEvent<{}>, newValue: City | null) => {
        newValue && this.setState({ selectedOrigin: newValue });
    };

    handleDestinationCityChange = (event: React.ChangeEvent<{}>, newValue: City | null) => {
        newValue && this.setState({ selectedDestination: newValue });
    };

    getAllSearchedCities = async(origin:boolean) =>{
        const header = {
            "Content-Type": configJSON.advancedsearchApiContentType,
            token: await getStorageData("LoginToken")
        };

        const endpoint  = origin 
            ? `${configJSON.searchCityAPIEndPoint}${this.state.originInputValue}`
            : `${configJSON.searchCityAPIEndPoint}${this.state.destinationInputValue}`

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSearchedCitiesAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage); 
    }

    handleChangeCreaditScoreFrom = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({creditScoreFrom:event.target.value});
    }

    handleChangeCreaditScoreTo = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({creditScoreTo:event.target.value});
    }

    searchByFilter = async() => {

        const header = {
            "Content-Type": configJSON.advancedsearchApiContentType,
            token: await getStorageData("LoginToken")
        };

        let endpoint = `${configJSON.searchByFilterAPIEndPoint}?page=${this.state.currentPage}&per_page=4`

        if(this.state.loadWeightFrom && this.state.loadWeightTo){
            endpoint =`${endpoint}&filters[load_weight][]=${this.state.loadWeightFrom}&filters[load_weight][]=${this.state.loadWeightTo}`
        }

        if(this.state.distanceFrom && this.state.distanceTo){
            endpoint =`${endpoint}&filters[total_miles][]=${this.state.distanceFrom}&filters[total_miles][]=${this.state.distanceTo}`
        }

        if(this.state.startDate && this.state.endDate){
            endpoint =`${endpoint}&filters[start_date][]=${this.state.startDate}&filters[end_date][]=${this.state.endDate}`
        }

        if(this.state.payRangeFrom && this.state.payRangeTo){
            endpoint =`${endpoint}&filters[pay_range][]=${this.state.payRangeFrom}&filters[pay_range][]=${this.state.payRangeTo}`
        }

        if(this.state.creditScoreFrom && this.state.creditScoreTo){
            endpoint =`${endpoint}&filters[lbcs][]=${this.state.creditScoreFrom}&filters[lbcs][]=${this.state.creditScoreTo}`
        }

        if(this.state.selectedOrigin && this.state.selectedDestination){
            endpoint =`${endpoint}&filters[origin_name][]=${this.state.selectedOrigin.attributes.city_ascii}&filters[destination_name][]=${this.state.selectedDestination.attributes.city_ascii}`
        }
        
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSearchedByFilterAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage); 
    }
    // Customizable Area End
}