import React from "react";

// Customizable Area Start
import { View, StyleSheet, ViewStyle, TextStyle } from "react-native";

import Box from '@mui/system/Box';
import { Input} from '@builder/component-library';

import CustomButton from "../../../components/src/CustomButton.web.";
import { AuthPageTitle } from "../../../components/src/AuthPageTitle.web";
import UnauthLayout from "../../../components/src/UnauthLayout.web";
const config = require('./config')


const newStyles = {
  
  inputContainer: { 
    paddingHorizontal: '8px', 
    paddingVertical: '10px', 
    height: '56px', 
    borderRadius: 8, 
    borderStyle: 'solid', 
  },
  actionWrapper: { 
    marginTop: '8px', 
    marginBottom: '24px', 
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center', 
    justifyContent: 'space-between'
  },
  inputText: { fontSize: 16, fontFamily: 'Inter', fontWeight: '400' },
  mainTitle:{
    fontWeight: 700,
    fontSize:'20px',
    fontFamily: 'Arial, Helvetica',
    marginTop:'50%'
  
    },
  normalText:{
    fontSize:'16px',
    fontWeight:400,
    color:'#0F172A',
    fontFamily:"sans-serif",
    width:"70%",
    lineHeight:'24px'
  },
  errorText:{
    fontSize:12,
    fontFamily: 'Arial, Helvetica'
  }
  
}
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

export default class ForgotPasswordBlock extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <UnauthLayout currentWidth={this.state.width}>
        <Box component="h2" sx={newStyles.mainTitle}>
        Forgot Password
        </Box>
        <Box sx={newStyles.normalText} >
        {config.textforgotPassword}
        </Box>
        <View style={{ marginTop: '22px' }}>
          <View style={{ marginBottom: '16px' }}>
            <AuthPageTitle>Email</AuthPageTitle>
            <Input
              borderColor="#CBD5E1"
              data-test-id="txtInputEmail"
              borderHighlightColor="#FBE1C4"
              placeholderTextColor="#94A3B8"
              borderErrorColor="#F87171"
              containerStyle={newStyles.inputContainer as ViewStyle}
              style={{ flex: 1, outline: 'none' } as TextStyle}
              textStyle={newStyles.inputText as TextStyle}
              value={this.state.email}
              placeholder={"Your email"}
              onChangeText={(text) => this.handleChangeEmail(text)}
              error={this.state.emailError ? this.state.errorMessage : ""}
              errorStyle={newStyles.errorText}
            />
          </View>

          <Box sx={newStyles.actionWrapper}>
            
          </Box>
          <CustomButton 
            testId="btnForgotP" 
            onClick={this.continue} 
            text="CONTINUE"
          />
        </View>
      </UnauthLayout>
          
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  main: {
    display: "flex",
    alignItems: "center"
  },
  
  
});
