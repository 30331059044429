import React from "react";
import { StyleSheet } from "react-native";
import { TokenResponse,useGoogleLogin } from '@react-oauth/google';
import { Typography } from "@builder/component-library";

const imgGoogleLogo = require('./google_color.svg');

const customStyles = {
  centered: { 
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
  socialButtonContainer: {
    border: '1px solid #CBD5E1',
    borderRadius: 8,
    padding: 8,
    backgroundColor: '#FFFFFF',
    gap: '8px'  
  }
}

interface CustomGoogleLoginButtonProps {
  onSuccess: (response: TokenResponse) => void;
  onError: () => void;
}

export default function SocialLoginSection({ onSuccess,onError }: CustomGoogleLoginButtonProps) {
  const login = useGoogleLogin({
    onSuccess: (response) => onSuccess(response),
    onError: () => onError(),
    flow:'implicit'
  });
  return (
    <>
      <Typography style={styles.orText} component="p" variant="base">
        Or Sign In With
      </Typography>
      <div style={{ ...customStyles.centered, width: '100%' }}>
        <button 
            data-test-id="btnSocialLoginNav"
            style={{ ...customStyles.centered, ...customStyles.socialButtonContainer }}
            onClick={()=>login()}
          >
          <img src={imgGoogleLogo} alt="Google"/>
          <Typography component="span" variant="base" style={styles.socialName}>
            Google
          </Typography>
        </button>
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  orText: { textAlign: "center", marginTop: 20, marginBottom: 27 ,fontFamily:'Arial, Helvetica',color:'#475569'},
  socialName: { fontFamily: 'Arial, Helvetica', fontWeight: '400' }
})