import React from "react";

// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import HeaderNavigation from "../../navigationmenu/src/HeaderNavigation.web";
import {Box} from "@mui/material"

// Customizable Area End

import TermConditionController, {
  Props,
} from "./TermConditionController";

export default class TermCondition extends TermConditionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
       <Box sx={webStyles.container}>
        <HeaderNavigation navigation={this.props.navigation} id={""} />
        <Box sx={webStyles.content}>
          <NavigationMenu navigation={this.props.navigation} id={""} />
          <Box sx={webStyles.mainContent}>
            <Box sx={webStyles.cardContainer}>
            <div style={webStyles.headerFont}>Terms and Conditions</div>
            <Box sx= {webStyles.termsAndCon}>
                {this.state.description}
            </Box>
            </Box>
            </Box>
            </Box>
            </Box>

      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    display: "flex",
    overflowX: 'hidden',
    flexDirection: "column",
  },
  content: {
    flex: 1,
    display: "flex",
  },
  mainContent: {
    backgroundColor: '#E2E8F0',
    flex: 1,
    padding: "2rem",
  },
  cardContainer: {
    border: '2px solid #215089',
    backgroundColor: '#FFFFFF',
    borderRadius: '16px',
    padding: '2rem',
    height:'100%'
  },
  termsAndCon:{
    width:'1036px',
    fontSize:'20px',
    fontWeight:400,
    color:'#0F172A',
    lineHeight:'35px',
    fontFamily:'Inter',
    mt:'2.7rem',
    marginLeft:'7px',
  },
  headerFont: {
    fontFamily: "Inter",
    fontSize: '30px',
    fontWeight: 700,
    color: "#215089"
  },
}


// Customizable Area End
