import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible} from "./assets";

import { isEmail } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
interface ApiResponse {
  errors?: {
    otp: string;
  }[];
  data?: {
    id: string;
    type: string;
    attributes: {
      id: number;
      email: string;
      pin: number;
      activated: boolean;
    };
  };
  meta?: {
    token: string;
  };
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  checkedRememberMe: boolean;
  enablePasswordField: boolean;
  placeHolderPassword: string;
  placeHolderEmail: string;
  imgPasswordInVisible: any;
  imgPasswordVisible: any;
  btnTxtLogin: string;
  labelHeader: string;
  btnTxtSocialLogin: string;
  labelRememberMe: string;
  labelOr: string;
  btnTxtGoogleLogin: string;
  emailFocus: boolean;
  passwordFocus: boolean;
  emailError: boolean;
  passwordError: boolean;
  width: number;
  errorMessage: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationApiCallId: string = "";
  apiEmailLoginCallId: string = "";
  labelTitle: string = "";
  labelPassword: string = "";
  labelEmail: string = "";
  textSignup: string = "";
  requestEmailOtpCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      placeHolderPassword: configJSON.placeHolderPassword,
      checkedRememberMe: false,
      imgPasswordInVisible: imgPasswordInVisible,
      placeHolderEmail: configJSON.placeHolderEmail,
      btnTxtLogin: configJSON.btnTxtLogin,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelHeader: configJSON.labelHeader,
      labelOr: configJSON.labelOr,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtGoogleLogin: configJSON.btnTxtGoogleLogin,
      passwordError: false,
      emailError: false,
      passwordFocus: false,
      emailFocus: false,
      errorMessage: "",
      width: window.innerWidth,
    };


    this.labelPassword = configJSON.labelPassword;
    this.labelTitle = configJSON.labelTitle;
    this.labelEmail = configJSON.labelEmail;
    this.textSignup = configJSON.textSignup;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    window.addEventListener('resize', () => this.handleWidthChange())
    // Customizable Area End
  }

  // Customizable Area Start
  handleWidthChange = () => {
    this.setState({ width: window.innerWidth });
  }
  // Web Event Handling

  handleChangeEmail = (value: string) => {
    this.setState({ email: value, emailError: false, errorMessage: '' })
  }

  validateEmailPassword = () => {
    if (this.state.email === null || this.state.email.length === 0) {
      this.setState({ emailError: true,errorMessage:'Email is required ' });
      return false;
    }
    const isValidatedEMail = isEmail("", this.state.email)?.status;
    if (!isValidatedEMail) {
      this.setState({ emailError: true, errorMessage: 'Please enter a valid email address' });
      return false;
    }
    this.goToOtpAfterEmailValidation();
    return true;
  } 
  continue = () => {
    this.validateEmailPassword()
  }

  goToOtpAfterEmailValidation() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_forgot_password/otps'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      attributes: {
        email: this.state.email,
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleEmailResponse (response:ApiResponse){
    if(response.errors){
      this.setState({emailError:true,errorMessage:response.errors[0].otp})
    }
    if(response.data){
      if (response.meta?.token) {
      localStorage.setItem('token', response.meta.token);
    }
    if (response.data.attributes?.email) {
      localStorage.setItem('email', response.data.attributes.email);
    }
      const msg: Message = new Message(
        getName(MessageEnum.ForgotToOpt)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
    }
  }


  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiRequestCallId === this.requestEmailOtpCallId) {
        this.handleEmailResponse(responseJson);
      }
    }
    // Customizable Area End
  }
}
