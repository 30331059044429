import React, { useState } from "react";

const imgCheckedBg = require("./check_icon.png");

const customStyles = {
  checkboxWrapper: { display: 'inline-flex', alignItems: 'center', gap: 8, cursor: 'pointer' },
  checkbox: {
    accentColor: '#B57730',
    width: '20px',
    height: '20px',
    borderRadius: '6px',
    borderColor: '#64748B',
    borderStyle: 'solid',
    verticalAlign: 'middle',
    backgroundSize: 'cover',
    backgroundPosition: 'center center' 
  },
  checkboxLabel: { fontFamily: 'Arial, Helvetica', fontWeight: '300', fontSize: '14px' },
}

interface ICustomStyledCheckboxProps {
  name: string;
  label?: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  disabled?:boolean
}

export default function CustomStyledCheckbox({ name, label, checked, onChange ,disabled}: ICustomStyledCheckboxProps) {
  const [isChecked, setIsCheked] = useState(checked);

  const handleChange = () => {
    if(!disabled){

      setIsCheked(!isChecked);
      onChange(!isChecked);
    }
  }

  return (
    <label 
      data-test-id={name}
      htmlFor={name}
      onClick={handleChange} 
      style={customStyles.checkboxWrapper}>
      <input
        type="checkbox"
        name={name}
        disabled={disabled}
        checked={isChecked}
        readOnly
        style={{ 
          ...customStyles.checkbox, 
          appearance: 'none', 
          backgroundImage: isChecked ? `url(${imgCheckedBg})` : '',
          borderWidth: isChecked ? '0' : '1px',
        }}
      />
      {label && <span style={customStyles.checkboxLabel}>{label}</span>}
    </label>
  );
}