import React, { ReactNode } from "react";
import { StyleSheet, View } from "react-native";

import { Box } from "@mui/system";

import { BackgroundImage } from "./CustomBackgroundImage.web";

interface IUnauthLayoutProps {
  children: ReactNode;
  currentWidth: number;
}

const newStyles = {
  mainContainer: { 
    display: 'flex', 
    flexDirection: 'row', 
    minHeight: '100vh', 
    width: '99vw', 
    paddingVertical: '16px', 
    paddingRight: '15px' 
  },
  formContainer: {
    marginLeft: 'auto', 
    marginRight:'auto', 
    width: '25%', 
    minWidth: '370px', 
    justifyContent: 'center', 
    padding: '32px',
    // marginTop:'100px'
  },
  bannerContainer: {
    position: 'relative', 
    width: '50%', 
  },
  bannerBg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(33, 80, 137, 0.50)',
    borderTopLeftRadius: '16px',
    borderBottomLeftRadius: '16px',
  },
  logoWrapper: { flexShrink: 1, backgroundColor: '#DDE1ED', borderRadius: '10px', padding: '17px' },
  logoImg: { borderRadius: '10px' },
}

export default function UnauthLayout({ children, currentWidth }: IUnauthLayoutProps) {
  return (
    <View style={styles.main}>
        <Box sx={newStyles.mainContainer}>
          <Box sx={newStyles.formContainer}>
            {children}
          </Box>
          <Box sx={{ ...newStyles.bannerContainer, display: currentWidth < 740 ? 'none' : 'flex' }}>
            <BackgroundImage 
              resizeMode="cover" 
              source={require('./view_.jpg')} 
              style={styles.bannerBg}
            />
            <Box sx={newStyles.bannerOverlay}>
              <Box sx={newStyles.logoWrapper}>
                <img src={require('./logo.jpg')} alt="Logo" style={newStyles.logoImg} />
              </Box>
            </Box>
          </Box>
        </Box>
      </View>
  );
}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingVertical: 16
  },
  bannerBg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
  },
});