import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible} from "./assets";
interface ApiResponse {
  errors?: Error[];
  data?: AccountData;
}

interface Error {
  message: string ;
}

interface AccountData {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

interface AccountAttributes {
  activated: boolean;
  country_code: string;
  email: string;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  phone_number: string;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  user_profile_image: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  confirmPassword: string;
  checkedRememberMe: boolean;
  enablePasswordField: boolean;
  enableConfirmField:boolean;
  placeHolderPassword: string;
  placeHolderEmail: string;
  imgPasswordInVisible: any;
  imgPasswordVisible: any;
  btnTxtLogin: string;
  labelHeader: string;
  btnTxtSocialLogin: string;
  labelRememberMe: string;
  labelOr: string;
  btnTxtGoogleLogin: string;
  emailFocus: boolean;
  passwordFocus: boolean;
  passwordError: boolean;
  width: number;
  errorMessage: string;
  token:string;
  updateSuccess:boolean;
  passwordErrMessage:string | undefined;
  confirmPasswordError:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  labelPassword: string = "";
  labelTitle: string = "";
  textSignup: string = "";
  labelEmail: string = "";
  confirmPassowrdApiId :string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
    confirmPassword: "",
      password: "",
      enablePasswordField: true,
      enableConfirmField:true,
      checkedRememberMe: false,
      placeHolderPassword: configJSON.placeHolderPassword,
      placeHolderEmail: configJSON.placeHolderEmail,
      imgPasswordInVisible: imgPasswordInVisible,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelHeader: configJSON.labelHeader,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelRememberMe: configJSON.labelRememberMe,
      labelOr: configJSON.labelOr,
      btnTxtGoogleLogin: configJSON.btnTxtGoogleLogin,
      passwordError: false,
      emailFocus: false,
      passwordFocus: false,
      errorMessage: "",
      width: window.innerWidth,
      token:'',
      updateSuccess:false,
      passwordErrMessage:'',
      confirmPasswordError:''
    };


    this.labelTitle = configJSON.labelTitle;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.textSignup = configJSON.textSignup;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    window.addEventListener('resize', () => this.handleWidthChange())
    // Customizable Area End
  }

  // Customizable Area Start
  handleWidthChange = () => {
    this.setState({ width: window.innerWidth });
  }
  // Web Event Handling
  
  handleChangePassword = (value: string) => {
    this.setState({ password: value, passwordError: false },this.validatePassword)
  }
  hanldeChangeConfirmPassword = (value:string)=>{
    this.setState({confirmPassword:value},this.validateConfirmPassword)
  }

  validatePassword = () => {
    const { password } = this.state;
    let error = '';

    if (password.length < 8) {
      error = 'Password must be at least 8 characters long.';
    } else if (!/[A-Z]/.test(password)) {
      error = 'Password must contain at least one capital letter.';
    } else if (!/[a-z]/.test(password)) {
      error = 'Password must contain at least one lowercase letter.';
    } else if (!/\d/.test(password)) {
      error = 'Password must contain at least one number.';
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      error = 'Password must contain at least one special character.';
    }
    this.setState({ passwordErrMessage: error });
  };

  validateConfirmPassword = () => {
    const { password, confirmPassword } = this.state;
    let error = '';

    if (password && confirmPassword && password !== confirmPassword) {
      error = 'Both passwords should match';
    }

    this.setState({ confirmPasswordError: error });
  };

  newPass = ()=>{
    this.validatePassword()
    this.validateConfirmPassword()
    if(this.state.confirmPasswordError==='' && this.state.password!=''){
      this.confirmPassword()
    }
  }
  passwordChanged = ()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
  }

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  handleClickShowConfirmPassword = ()=>{
        this.setState({
            enableConfirmField:!this.state.enableConfirmField
        })
  }

  confirmPassword() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.confirmPassowrdApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_forgot_password/passwords'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: localStorage.getItem('token'),
      new_password:this.state.password,
      confirm_password: this.state.confirmPassword
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleConfirmPass = (response:ApiResponse)=>{
    if(response.data){
        this.setState({updateSuccess:true})
    }
    else{
      this.setState({passwordErrMessage:response.errors?.[0].message})
    }
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (apiRequestCallId === this.confirmPassowrdApiId) {
      this.handleConfirmPass(responseJson);
    }
    
    if (getName(MessageEnum.NavigationPayLoadMessage)) {
      if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
        const tokenFromOtp = message.getData(
          getName(MessageEnum.AuthTokenDataMessage)
        );
          this.setState({token:tokenFromOtp})
      }

    }
    // Customizable Area End
  }
}
