import React from "react";
import { StyleSheet } from "react-native";

import { Typography } from "@builder/component-library";
import { Box } from "@mui/system";

const customStyles = {
  container: { 
    width: '100%', 
    backgroundColor: "#FEE2E2", 
    position: "relative", 
    borderRadius: '4px',
  },
  decoration: { 
    width: 5, 
    height: "100%", 
    background: '#DC2626', 
    position: "absolute", 
    borderRadius: "8px 0 0 8px" 
  },
}

interface ICustomToastProps {
  message: string;
}

export default function CustomToast({ message }: ICustomToastProps) {
  return (
    <Box sx={customStyles.container}>
      <Box sx={customStyles.decoration} />
      <Typography style={styles.messageText}>{message}</Typography>
    </Box>
  );
}

const styles = StyleSheet.create({
  messageText: { 
    fontFamily: 'Arial, Helvetica', 
    fontSize: 16, 
    fontWeight: '400', 
    marginTop: 0, 
    color: '#DC2626', 
    margin: 10,
    textAlign: 'center'
  }
});