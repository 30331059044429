import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { logoIcon } from "./assets";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box style={webStyle.root}>
        <img src={logoIcon} alt="Logo" style={webStyle.logoIcon} />

        <List component="nav" style={webStyle.list}>

          {this.menuItem.map((item) => (
            <ListItem
              key={item.key}
              style={{
                ...webStyle.listItem,
                color: this.state.activeItem === item.key ? '#B57730' : '#78716C',
              }}
              onClick={() => this.handleNavigate(item.key)}
              data-testId={"navigateDashboard"}
            >
              <ListItemIcon>
                {React.cloneElement(item.icon, {
                  style: {
                    ...webStyle.iconButton,
                    color: this.state.activeItem === item.key ? '#B57730' : '#78716C',
                  }
                })}
              </ListItemIcon>
              <ListItemText
                primary={<Typography style={{
                  ...webStyle.listItemText,
                  color: this.state.activeItem === item.key ? '#B57730' : '#78716C',
                }}>{item.label}</Typography>}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  root: {
    width: '120px',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    paddingTop: 4,
    border: '0px 1px 0px 1px solid #E2E8F0',
  },

  logoIcon: {
    width: '60px',
    height: '60px',
    marginTop: '20px',
    marginBottom: '2rem'
  },

  iconButton: {
    height: '24px',
    width: '24px',
    marginLeft: '13px'
  },

  listItem: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center' as const,
    textAlign: 'center' as const,
    marginBottom: '20%'
  },

  list: {
    marginTop: '50%',
    marginBottom: '50%'
  },

  listItemText: {
    fontSize: '12px',
    fontWeight: 700,
    fontFamily: 'Inter'
  }

};
// Customizable Area End
