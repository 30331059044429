// Customizable Area Start
export const backArrow = require("../assets/backArrow.png");
export const filter = require("../assets/filter.png");
export const edit = require("../assets/edit.png");
export const truckIcon = require("../assets/trcukImage.png");
export const trcukdetailsIcon = require("../assets/trcukdetailsIcon.png")
export const button1 = require("../assets/button_.png")
export const button2 = require("../assets/button1_.png")
export const sendIcon = require("../assets/image.png")
export const person = require("../assets/person.png")
export const tickIcon = require("../assets/Icon.png");
export const crossIcon = require("../assets/image_.png")
// Customizable Area End
