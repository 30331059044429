import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";


// Customizable Area Start
import React from "react";
export type NavObject = TypeNav
export interface TypeNav {
    addListener: Function
    goBack: Function,
    getParam: Function,
    navigate: Function
}
interface LoadResponse {
    id: number;
    type: string;
    attributes: {
      load_weight: string;
      total_miles: string;
      load_broker_phone_number: string;
      origin_address: {
        city: string;
        state: string;
        address: string;
        country: string;
        zip_code: string;
      };
      destination_address: {
        city: string;
        state: string;
        address: string;
        country: string;
        zip_code: string;
      };
      price: string;
      status: string;
      start_date: string;
      end_date: string;
      commodity: string;
      load_details: string;
    }

    errors:{
        message:string
    }
  
}

interface LoadResponse2 {
    data: {
      id: number;
      status: string;
      load_broker_id: number;
      start_date: string;
      end_date: string;
      price: string;
      load_broker_phone_number: string;
      commodity: string;
      email: string;
      load_details: string;
      load_weight: string;
      total_miles: string;
      origin_address: {
        city: string;
        state: string;
        address: string;
        country: string;
        zip_code: string;
      };
      destination_address: {
        city: string;
        state: string;
        address: string;
        country: string;
        zip_code: string;
      };
      load_broker_credit_score: number;
      created_at: string;
      updated_at: string;
    };
    meta: {
      message: string;
    };
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: NavObject;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}


interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    fullName: string;
    loginemail: string;
    name: string;
    template_dis: string;
    phoneNumber: string;
    loginpassword: string;
    showPassword: boolean;
    errors: {
        [key: string]: string;
    };
    registrationSuccess: boolean;
    isModalOpen: boolean,
    isModelFuntionCall: boolean,
    isEditBtn: boolean,
    getUserData: [],
    getShowUserData: {
        attributes: {
            comment: string; id: number, name: string, description: string, status: string,
        };
    },
    getUserDataArchive: [],
    isArchiveModalOpen: boolean
    getorganizationsDetails: [],

    //create Quote
    itemName: string,
    itemPrice: string,
    itemQuantity: string,
    discount: string,
    description: string,
    //PUT for Achive
    serialIdforArchive: string,
    //Level 1
    leveOneModal: boolean;
    selectedRowData: {},
    statusForlevelOne: string;
    selectedStatus: string | null;
    userRole: string;
    userRolelevelOne: string;
    anchorEl: null,
    selectedOption: string,
    isEditModalOpen: boolean,
    isShowModalOpen: boolean,
    isStatusModalOpen: boolean,
    editClickDataLeveZero: [],
    itemNameEdit: string,
    itemPriceEdit: string,
    itemQuantityEdit: string,
    discountEdit: string,
    descriptionEdit: string,
    serialIdEdit: number;
    openDialog: boolean;
    total_page: number;
    currentPage: number;
    itemsPerPage: number;
    statusBtn: boolean;
    status: string;
    tabValue:number,
    selectedCardId:number
    cards:LoadResponse[]
    openModal:boolean
    chatS:boolean
    showToast:boolean
    toastRef : any
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface ApiData {
    header?: Record<string, string>;
    contentType?: string;
    method: string;
    endPoint: string;
    body?: LoginPayload | TemplateData | Payload | TemplateIdData | FormData// Adjust the type of body as needed
    type?: string;
}

interface LoginPayload {
    data: {
        email: string;
        password: string;
    };
}

interface TemplateData {
    template: {
        name: string;
        description: string;
    };
}

interface Payload {
    template: {
        name: string;
        description: string;
        kpis_attributes: {
            [key: number]: {
                name: string;
                description: string;
                point: number;
            };
        };


    };
}

interface ChangeEvent {
    target: {
        name: string;
        value: string;
    };
}


interface TemplateIdData {
    status: string;
    comment: string;
}

interface ChangeEvent {
    target: {
        name: string;
        value: string;
    };
}

export interface EventList {
    id: number,
    attributes: {
        name: string,
        description: string,
        status: string,
        comment: string,

    }
}



export default class MultilevelApprovalController extends BlockComponent<
    Props,
    S,
    SS
>
{

    // Customizable Area Start
    AttendancDetailsApi: string = "";
    AttendancePostApi: string = "";
    AttendancDetailsArchivedApi: string = "";
    templatesApi: string = "";
    ArchivedDataPutApi: string = "";
    UpdateApiPutApi: string = "";
    StatusDataPutApi: string = "";
    loginPostDeatilsApi: string = "";
    editUpdateLevelZeroApi: string = "";
    //Level 1
    getListApi: string = "";
    getShowListApi: string = "";
    levelOneTestingApi: string = "";
      showAllLoadsAPICallId:string = ''
      updateStatusId:string = ''

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            fullName: "",
            loginemail: "",
            name: "",
            template_dis: "",
            phoneNumber: "",
            loginpassword: "",
            showPassword: false,
            errors: {},
            registrationSuccess: false,
            isModalOpen: false,
            isModelFuntionCall: false,
            isEditBtn: false,
            getUserData: [],
            getShowUserData: {
                attributes: {
                    id: 0,
                    name: '',
                    description: '',
                    status: '',
                    comment: ''
                }
            },
            getUserDataArchive: [],
            isArchiveModalOpen: false,
            getorganizationsDetails: [],
            itemName: '',
            itemPrice: '',
            itemQuantity: '',
            discount: '',
            description: '',
            //Put for Archive data
            serialIdforArchive: '',
            //Level 1
            leveOneModal: false,
            selectedRowData: '',
            statusForlevelOne: '',
            selectedStatus: null,
            userRole: '',
            userRolelevelOne: '',
            anchorEl: null,
            selectedOption: "",
            isEditModalOpen: false,
            isStatusModalOpen: false,
            isShowModalOpen: false,
            editClickDataLeveZero: [],
            itemNameEdit: '',
            itemPriceEdit: '',
            itemQuantityEdit: '',

            discountEdit: '',
            descriptionEdit: '',
            serialIdEdit: 0,
            openDialog: false,
            total_page: 0,
            itemsPerPage: 5,
            currentPage: 1,
            statusBtn: false,
            status: '',
            tabValue:0,
            selectedCardId:this.state?.cards[0]?.id,
            cards:[],
            openModal:false,
            chatS:false,
            showToast:false,
            toastRef : React.createRef(),
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }



    // Customizable Area Start

    async componentDidMount(): Promise<void> {
        const tempVAlue = 0
        this.showAllLoads(tempVAlue)
        document.addEventListener('mousedown', this.handleClickOutside);

    }

    componentDidUpdate(prevProp: Props, prevState: S) {
        
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && !responseJson.errors) {
                switch (apiRequestCallId) {
                    case this.showAllLoadsAPICallId:
                        this.handleAllLoadData(responseJson)
                        break;

                    case this.updateStatusId:
                        this.handleUpdateRes(responseJson)
                        break
                }
            }
        }
    }

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ tabValue: newValue})
        this.showAllLoads(newValue)
       
      };
    isCardSelected = (cardId: number): boolean => {
        return cardId === this.state.selectedCardId;
      };
    handleCardClick = (cardId: number) => {
        this.setState({ selectedCardId: cardId });
      };
      showAllLoads = (statusVAlue:number) => {
        const header = {
          token: localStorage.getItem('LoginToken')
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.showAllLoadsAPICallId = requestMessage.messageId;
        let finalStatus = ''
        if(statusVAlue ===1){
            finalStatus = 'approved'
        }
        else if(statusVAlue ===2){
            finalStatus = 'rejected'
        }
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_load/my_loads?status=${finalStatus}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
       
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
    handleAllLoadData=(responseJson:{ data: LoadResponse[] })=>{
        this.setState({cards:responseJson?.data},()=>{
            this.setState({selectedCardId:this.state?.cards[0]?.id})
        })
    }

    handleClickOutside = (event:any) => {
        if (this.state.toastRef && this.state.toastRef.current && !this.state.toastRef.current.contains(event.target)) {
          this.closeToast();
        }
      };
  updateStatus = () => {
    const data = {
        status:this.state.selectedStatus
        };
    
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("LoginToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_load/loads/${this.state.selectedCardId}/update_status`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    this.updateStatusId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleUpdateRes(response:LoadResponse2){
    if(response.data){

        this.setState({openModal:false,showToast:true})
        this.showAllLoads(1)
    }
  }
  handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectedStatus: event.target.value });
  };

    handleClose= ()=>{
        this.setState({openModal:false})
    }
    handleClickOpen  = ()=>{
        this.setState({openModal:true})
    }
    backButn = ()=>{
        this.setState({chatS:false})
    }
    chatOpen = ()=>{
        this.setState({chatS:true})
    }
    closeToast=()=>{
        this.setState({showToast:false})
    }
    // Customizable Area End
}

