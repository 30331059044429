import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import HeaderNavigation from "../../navigationmenu/src/HeaderNavigation.web";

// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const filteredItems = this.state.LoggedInUserType === 'trucker'
    ? this.items.filter(item => item.title === 'Load Board' || item.title === 'My Loads')
    : this.items;

    return (
      //Merge Engine DefaultContainer
      <Box sx={webStyles.container}>
        <HeaderNavigation navigation={this.props.navigation} id={""} />
        <Box sx={webStyles.content}>
          <NavigationMenu navigation={this.props.navigation} id={""} />
          <Box sx={webStyles.mainContent}>
            <Box sx={webStyles.cardContainer}>
              <Typography style={webStyles.headerFont}>{webConfigJSON.labelTitleText}</Typography>
              <Box sx={webStyles.cardBox}>
                {filteredItems.map((item, itemIndex) => (
                  <Box sx={webStyles.cardStyle} key={itemIndex}>
                    <List>
                      <ListItem
                        alignItems="flex-start"
                        sx={webStyles.listItem}
                        onClick={() => this.handleNavigate(item.path)}
                        data-testId={"navigateDashboard"}
                      >
                        <ListItemIcon>
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={<Typography className="primaryText" sx={webStyles.listPrimaryText}>{item.title}</Typography>}
                          secondary={<Typography sx={webStyles.listSecondaryText}>{item.description}</Typography>}
                        />
                      </ListItem>
                    </List>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    overflowX: 'hidden'
  },
  content: {
    display: "flex",
    flex: 1,
  },
  mainContent: {
    flex: 1,
    padding: "2rem",
    backgroundColor: '#E2E8F0',
  },
  cardContainer: {
    backgroundColor: '#FFFFFF',
    border: '2px solid #215089',
    borderRadius: '16px',
    padding: '2rem',
  },
  headerFont: {
    fontFamily: "Inter",
    fontSize: '30px',
    fontWeight: 700,
    color: "#215089"
  },
  cardBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    marginTop: '2%',
    marginBottom: '5%'
  },
  cardStyle: {
    backgroundColor: '#E9EEF3',
    borderRadius: '8px',
    padding: '20px 22px',
    boxShadow: '0px 4px 4px 0px #00000040',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#FEF5EC',
      '& .MuiSvgIcon-root': {
        color: '#B57730',
      },
      '& .primaryText': {
        color: '#B57730',
      },
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  listPrimaryText: {
    fontFamily: "Inter",
    fontSize: '18px',
    fontWeight: 700,
    color: "#0F172A"
  },
  listSecondaryText: {
    fontFamily: "Inter",
    fontSize: '16px',
    fontWeight: 400,
    color: "#334155",
    marginTop: '5px'
  }
};
// Customizable Area End
