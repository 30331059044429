Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksListApiEndPoint = "tasks/task_lists";
exports.tasksApiEndPoint = "tasks/tasks";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.selectTaskPriority = "Select task prority";
exports.selectTaskStatus = "Select task status";
exports.selectAccount = "Select account";
exports.selectGroup = "Select group";
exports.selectTasks = "Select tasks";
exports.textTitle = "Title";
exports.textDescription = "Description";
exports.textAdd = "Add";
exports.textTasks = "Tasks";
exports.textTaskList = "Task Lists";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textSave = "Save";
exports.textClose = "Close";
exports.textAccounts = "Accounts";
exports.textGroups = "Groups";
exports.textAddTask = "Add Task";
exports.textAddTaskList = "Add Task List";
exports.textShowTaskList = "Show Task List";
exports.textShowTask = "Show Task";
exports.textId = "Id";
exports.textPriority = "Priority";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textOr = "OR";

exports.vehicaltracking = "Vehicles Tracking";
exports.all = "All";
exports.delivered = "Delivered";
exports.intransit = "In Transit";
exports.driverName = "Grave Digger";
exports.driverLocation = "Bengaluru, KA";
exports.trackingId = "Tracking ID";
exports.from = "From";
exports.to = "To";
exports.weight = "Weight";
exports.amount = "Amount";
exports.end_date = "End Date";
exports.truckDriver = "Truck Driver"
// Customizable Area End
