import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    TextField,
    Autocomplete,
    Paper,
    AutocompleteProps,
    Button,
    ListItem,
    ListItemText,
    ListItemIcon,
    Menu,
    MenuItem
} from "@mui/material";
import {KeyboardArrowDown, CalendarToday} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import HeaderNavigation from "../../navigationmenu/src/HeaderNavigation.web";
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import LoadBrokerSearchController, {
    City,
    Props,
    configJSON
} from "./LoadBrokerSearchController.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class LoadBrokerSearch extends LoadBrokerSearchController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: City, index: number) => (
        <ListItem
            {...props}
            key={index}
            onMouseEnter={() => this.handleMouseEnter(index)}
            onMouseLeave={this.handleMouseLeave}
            style={{
                backgroundColor: this.state.hoveredIndex === index ? '#FBE1C4' : 'transparent',
                padding: '12px 16px',
                borderRadius: '8px',
                cursor: 'pointer',
            }}
        >
            <ListItemIcon sx={{minWidth:"auto", marginRight:"15px", width:"16px", height:"16px"}}>
                <RoomOutlinedIcon sx={{ height: '16px', width: '16px', color: '#0F172A' }} />
            </ListItemIcon>
            <ListItemText
                sx={{ color: '#0F172A', fontSize: '14px', fontWeight: 400, fontFamily: 'Inter' }}
                primary={`${option?.attributes?.city_ascii}, ${option?.attributes?.state_name}`}
            />
        </ ListItem>
    );

    renderCustomUI = () => (
        <Paper sx={webStyles.paperBox}>
            <Box sx={webStyles.locationBox}>
                <LocationSearchingIcon sx={webStyles.locationIcon} />
                <Typography data-test-id="getUserLocation" onClick={()=> this.getUserLocation(123,345)} sx={{display:"none"}}></Typography>
                <Typography data-test-id="useCurrentLocation" onClick={this.handleLocationClick} sx={webStyles.locationText}>{configJSON.currentLocation}</Typography>
            </Box>
        </Paper>
    );

    renderemptyUI = () => (
        <></>
    );
    // Customizable Area End

    render() {
        // Customizable Area Start
        const truckTypeOpen = Boolean(this.state.anchorEl);
        return (
            //Merge Engine DefaultContainer
            <Box sx={webStyles.container}>
                <HeaderNavigation navigation={this.props.navigation} id={""} />
                <Loader loading={this.state.isLoading} />
                <Box sx={webStyles.content}>
                    <NavigationMenu navigation={this.props.navigation} id={""} />
                    <Box sx={webStyles.mainContent}>
                        <Box sx={webStyles.tabContainer}>
                            <Typography style={webStyles.headerFont}>{configJSON.labelTitleText}</Typography>

                            <Box display='flex' flexDirection='column' sx={{ width: '100%', marginBottom: '4%' }}>
                                <Box sx={webStyles.feildsContainer}>
                                    <Box sx={webStyles.filedBox}>
                                        <Typography sx={webStyles.typography}>{configJSON.origin} <span> *</span></Typography>
                                        <Box sx={{position:"relative"}}>
                                        <StyledOriginAutocomplete
                                            fullWidth
                                            inputValue={this.state.inputValue}
                                            value={this.state.selectedCity}
                                            onInputChange={this.handleInputChange}
                                            onChange={this.handleCityChange}
                                            onFocus={this.handleOnFocus}
                                            options={this.state.options}
                                            getOptionLabel={(option) => `${option.attributes.city_ascii}, ${option.attributes.state_name}`}
                                            renderOption={(props, option) => this.renderOption(props, option, this.state.options.indexOf(option))}
                                            PaperComponent={this.state.showCustomUI ? this.renderemptyUI : undefined}
                                            data-testId={"changeOrigin"}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                placeholder="Enter an origin"
                                                variant="outlined"
                                            />}
                                        />
                                        {this.state.showCustomUI && this.renderCustomUI()}
                                        </Box>
                                        <Typography sx={webStyles.typographyError}>{this.state.originCityError}</Typography>
                                    </Box>
                                    
                                    <Box sx={webStyles.filedBox}>
                                        <Typography sx={webStyles.typography}>{configJSON.destination} <span> *</span></Typography>
                                        <StyledOriginAutocomplete
                                            fullWidth
                                            inputValue={this.state.inputDestinationValue}
                                            onInputChange={this.handleDestinationInputChange}
                                            onChange={this.handleDestinationCityChange}
                                            options={this.state.options}
                                            data-testId={"changeDestination"}
                                            getOptionLabel={(option) => `${option.attributes.city_ascii}, ${option.attributes.state_name}`}
                                            renderOption={(props, option) => this.renderOption(props, option, this.state.options.indexOf(option))}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                placeholder="Enter a destination"
                                                variant="outlined"
                                            />}
                                        />
                                        <Typography sx={webStyles.typographyError}>{this.state.destinationCityError}</Typography>
                                    </Box>

                                    <Box sx={webStyles.filedBox}>
                                        <Typography sx={webStyles.typography}>{configJSON.startDate}</Typography>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                value={this.state.startDate}
                                                onChange={this.handleStartDateChange}
                                                format="eee, MMM dd"
                                                data-testId={"startDate"}
                                                maxDate={this.state.endDate || undefined}
                                                slots={{
                                                    openPickerIcon: CalendarToday
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        sx: {
                                                            '.MuiSvgIcon-root':{
                                                                color:"#0F172A"
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                borderRadius: '8px',
                                                                '&:hover fieldset': {
                                                                    borderColor: '#CBD5E1',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: '#CBD5E1',
                                                                },
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                color: '#475569',
                                                                fontSize: '16px',
                                                                fontFamily: 'Inter',
                                                                fontWeight: 400,
                                                            },
                                                        }
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Box>

                                    <Box sx={webStyles.filedBox}>
                                        <Typography sx={webStyles.typography}>{configJSON.endDate}</Typography>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                value={this.state.endDate}
                                                onChange={this.handleEndDateChange}
                                                minDate={this.state.startDate || undefined}
                                                format="eee, MMM dd"
                                                data-testId={"endDate"}
                                                slots={{
                                                    openPickerIcon: CalendarToday
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        sx: {
                                                            '.MuiSvgIcon-root':{
                                                                color:"#0F172A"
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                borderRadius: '8px',
                                                                '&:hover fieldset': {
                                                                    borderColor: '#CBD5E1',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: '#CBD5E1',
                                                                },
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                color: '#475569',
                                                                fontSize: '16px',
                                                                fontFamily: 'Inter',
                                                                fontWeight: 400,
                                                            },
                                                        }
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Box>

                                    <Box sx={webStyles.filedBox}>
                                        <Typography sx={webStyles.typography}>{configJSON.truckType}</Typography>
                                        <ButtonContainer
                                            data-test-id = "truck-type-button"
                                            id="demo-customized-button"
                                            onClick={this.handleClickTruckType}
                                            endIcon={<KeyboardArrowDown />}
                                        >
                                            {this.state.trucTypeInput}
                                        </ButtonContainer>
                                        <Menu
                                            data-test-id="menu-container"
                                            id="demo-customized-menu"
                                            anchorEl={this.state.anchorEl}
                                            open={truckTypeOpen}
                                            onClick={this.handleCloseTruckTypeList}
                                            MenuListProps={{
                                                'aria-labelledby': 'demo-customized-button',
                                                sx: { width: this.state.anchorEl && this.state.anchorEl.offsetWidth } // <-- The line that does all
                                            }}
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                        >
                                            {configJSON.trucTypeList.map((truckType: string) => (
                                                <MenuItem data-test-id="menu-list" onClick={()=> this.handleSelectTrucType(truckType)}>{truckType}</MenuItem>
                                            ))}
                                        </Menu>
                                    </Box>
                                </Box>
                                <Box display='flex' justifyContent='flex-end' sx={{marginTop: '3%' }}>
                                    <Button
                                        sx={webStyles.SearchButton}
                                        onClick={this.searchLoadsByCity}
                                        data-testId={"searchloads"}
                                    >
                                        {configJSON.searchLoads}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box >
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

export const StyledOriginAutocomplete = styled((props: AutocompleteProps<City, false, false, false>) => (
    <Autocomplete {...props} />
))({
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        color: '#475569',
        fontSize: '16px',
        fontFamily: 'Inter',
        fontWeight: 400,
        '& fieldset': {
            border: '1px solid #CBD5E1',
        },
        '&:hover fieldset': {
            borderColor: '#CBD5E1',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#CBD5E1',
        },
    },
    ".css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator":{
        display:"none"
    },
    '& .MuiInputBase-input': {
        padding: '10px 8px',
    },
});

const ButtonContainer = styled(Button)(({ theme })=> ({
    height:56,
    border:"1px solid #CBD5E1",
    borderRadius:8,
    display:"flex",
    justifyContent:"space-between",
    backgroung:"#FFFFFF",
    color:"#475569",
    fontWeight:400,
    textTransform:"none"as const,
    fontSize:16,
    fontFamily:"inter",
    padding:"10px 8px",
    "&:hover":{
       background:"#FFFFFF",
    }
 })) ;

const webStyles = {
    container: {
        display: "flex",
        flexDirection: "column",
        overflowX: 'hidden'
    },
    content: {
        display: "flex",
        flex: 1,
    },
    mainContent: {
        flex: 1,
        padding: "2rem",
        backgroundColor: '#E2E8F0',
    },
    tabContainer: {
        backgroundColor: '#FFFFFF',
        border: '2px solid #215089',
        borderRadius: '16px',
        padding: '2rem',
    },
    headerFont: {
        fontFamily: "Inter",
        fontSize: '30px',
        fontWeight: 700,
        color: "#215089"
    },
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
        display: 'flex',
        gap: '6px',
        marginTop: '2%',
    },
    feildsContainer: {
        display: 'flex',
        marginTop: '2%',
        alignItems: 'center',
        width: '100%',
        flexWrap:"wrap",
        justifyContent:"space-between",
        maxWidth:"700px"
    },
    filedBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        width: '47%',
        marginBottom:"30px",
        minWidth:220
    },
    typography: {
        color: '#0F172A',
        fontFamily: "Inter",
        fontSize: '16px',
        fontWeight: 400,
    },
    typographyError: {
        color: 'red',
        fontFamily: "Inter",
        fontSize: '16px',
        fontWeight: 400,
    },
    paperBox: {
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F1F5F9',
        borderRadius: '8px',
        boxShadow: '0px 2px 4px 0px #00000040',
        position:"absolute",
        zIndex:1,
        width:"-moz-available"
    },
    locationBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        padding: '10px 8px 10px 8px',
        cursor: 'pointer'
    },
    locationIcon: {
        color: '#0F172A',
        size: '22px',
        height:"24px",
        width:"24px"
    },
    locationText: {
        color: '#0F172A',
        fontFamily: "Inter",
        fontSize: '16px',
        fontWeight: 400,
    },
    textFeildStyle: {
        borderRadius: '8px',
        border: '1px solid #CBD5E1',
        padding: '10px 8px',
        '&:hover': {
            borderColor: '#A0AEC0',
        },
        '& .MuiInputBase-input': {
            padding: '10px 8px',
        },
    },
    SearchButton: {
        color: '#FFFFFF',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: 700,
        fontFamily: 'Inter',
        padding: '10px 16px 10px 16px',
        width: '100%',
        maxWidth:"304px",
        background: 'linear-gradient(99.09deg, #915F26 2.64%, #F19F40 100%)',
        '&:hover': {
            background: 'linear-gradient(99.09deg, #915F26 2.64%, #F19F40 100%)',
        }
    },
};
// Customizable Area End