import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    TextField,
    Button,
    TextFieldProps,
    Select,
    MenuItem,
    Divider,
    Pagination,
    ListItem,
    ListItemText,
    Autocomplete,
    AutocompleteProps
} from "@mui/material";

import { styled } from '@mui/material/styles';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import HeaderNavigation from "../../navigationmenu/src/HeaderNavigation.web";
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import LoadBoardListViewController, {
    AllLoads,
    Props,
    configJSON
} from "./LoadBoardListViewController";
import { trcukdetailsIcon, truckIcon } from "./assets";
import { City } from "./LoadBrokerSearchController.web";
import { KeyboardArrowDown } from "@mui/icons-material";
import Loader from "../../../components/src/Loader.web";


const StyledAutocomplete = styled((props: AutocompleteProps<City, false, false, false>) => (
    <Autocomplete {...props} />
))({
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        color: '#475569',
        fontSize: '16px',
        background:"#fff",
        paddingRight:"10px!important",
        display:"flex",
        justifyContent:"space-between",
        fontFamily: 'Inter',
        fontWeight: 400,
        '&.Mui-focused fieldset': {
            borderColor: '#CBD5E1',
        },
        '& fieldset': {
            border: '1px solid #CBD5E1',
        },
        '&:hover fieldset': {
            borderColor: '#CBD5E1',
        },
    },
    '& .MuiInputBase-input': {
        background:"#fff",
        padding: '10px 8px'
    },
    ".css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator":{
        display:"none"
    },
});

// Customizable Area End

export default class LoadBoardListView extends LoadBoardListViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: City, index: number) => {
        return (
            <ListItem
                {...props}
                style={{
                    padding: '12px 16px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                }}
            >
                <ListItemText
                    sx={{ color: '#0F172A', fontSize: '14px', fontWeight: 400, fontFamily: 'Inter' }}
                    primary={`${option?.attributes?.city_ascii}, ${option?.attributes?.state_name}`}
                />
            </ ListItem>)
    }


    renderFilterBox = () => {
        return (
            <Box sx={webStyles.filterBox}>
                <Typography sx={webStyles.fontStyle_2}>{configJSON.filter}</Typography>
                <Box sx={webStyles.feildBox}>
                    <Box sx={webStyles.texfeildContainer}>
                        <Typography sx={webStyles.fontStyle_3}>{configJSON.sortDistance}</Typography>
                        <Box sx={webStyles.feildsConatiner}>
                            <CustomTextField 
                                placeholder="5"
                                data-test-id = "distanceFrom"
                                value={this.state.distanceFrom} 
                                onChange={this.handleChangeDistanceFrom}
                            />
                            <Typography sx={{ ...webStyles.fontStyle_3, color: '#475569 !important' }}>{configJSON.Textto}</Typography>
                            <CustomTextField
                                placeholder="500"
                                data-test-id = "distanceTo"
                                value={this.state.distanceTo} 
                                onChange={this.handleChangeDistanceTo} 
                            />
                        </Box>
                    </Box>

                    <Box sx={webStyles.texfeildContainer}>
                        <Typography sx={webStyles.fontStyle_3}>{configJSON.truckType}</Typography>
                        <Select
                            value={this.state.selectedValue}
                            onChange={(event)=>this.handleChange(event)}
                            fullWidth
                            variant="outlined"
                            IconComponent={ArrowDropDownIcon}
                            displayEmpty
                            sx={webStyles.selectBoxStyle}
                            data-test-id={"change_truck_type"}
                        >
                            {configJSON.trucTypeList.map((truckType:string,index:number) => (
                                <MenuItem key={index} value={truckType}>{truckType}</MenuItem>
                            ))}
                        </Select>
                    </Box>

                    <Box sx={webStyles.texfeildContainer}>
                        <Typography sx={webStyles.fontStyle_3}>{configJSON.loadWeight}</Typography>
                        <Box sx={webStyles.feildsConatiner}>
                            <CustomTextField 
                                placeholder="0 Tons"
                                data-test-id = "loadWeightFrom"
                                value={this.state.loadWeightFrom} 
                                onChange={this.handleChangeLoadWeightFrom} 
                            />
                            <Typography sx={{ ...webStyles.fontStyle_3, color: '#475569 !important' }}>{configJSON.Textto}</Typography>
                            <CustomTextField 
                                placeholder="500 Tons"
                                data-test-id = "loadWeightTo"
                                value={this.state.loadWeightTo} 
                                onChange={this.handleChangeLoadWeightTo} 
                            />
                        </Box>
                    </Box>

                    <Box sx={webStyles.texfeildContainer}>
                        <Typography sx={webStyles.fontStyle_3}>{configJSON.payRange}</Typography>
                        <Box sx={webStyles.feildsConatiner}>
                            <CustomTextField
                                placeholder="$ min"
                                data-test-id = "payRangeFrom"
                                value={this.state.payRangeFrom}
                                onChange={this.handleChangePayRangeFrom}
                            />
                            <Typography sx={{ ...webStyles.fontStyle_3, color: '#475569 !important' }}>{configJSON.Textto}</Typography>
                            <CustomTextField
                                placeholder="$ max"
                                data-test-id = "payRangeTo"
                                value={this.state.payRangeTo}
                                onChange={this.handleChangePayRangeTo}
                            />
                        </Box>
                    </Box>

                    <Box sx={webStyles.texfeildContainer}>
                        <Typography sx={webStyles.fontStyle_3}>{configJSON.originName}</Typography>

                        <StyledAutocomplete
                            fullWidth
                            inputValue={this.state.originInputValue}
                            value={this.state.selectedOrigin}
                            onInputChange={this.handleInputChange}
                            onChange={this.handleCityChange}
                            options={this.state.cityOptions}
                            getOptionLabel={(option) => `${option?.attributes?.city_ascii}, ${option?.attributes?.state_name}`}
                            renderOption={(props, option) => this.renderOption(props, option, this.state.cityOptions.indexOf(option))}
                            data-test-id={"changeOrigin"}
                            renderInput={(params)=><TextField
                                {...params }
                                InputProps={{
                                    ...params?.InputProps,
                                    endAdornment: (<KeyboardArrowDown />)
                                }}
                                variant="outlined"
                            />}
                        />
                    </Box>

                    <Box sx={webStyles.texfeildContainer}>
                        <Typography sx={webStyles.fontStyle_3}>{configJSON.destinationName}</Typography>
                        <StyledAutocomplete
                            fullWidth
                            onInputChange={this.handleDestinationInputChange}
                            value={this.state.selectedDestination}
                            inputValue={this.state.destinationInputValue}
                            options={this.state.cityOptions}
                            renderOption={(props, option) => this.renderOption(props, option, this.state.cityOptions.indexOf(option))}
                            getOptionLabel={(option) => `${option?.attributes?.city_ascii}, ${option?.attributes?.state_name}`}
                            data-test-id={"changeDestination"}
                            onChange={this.handleDestinationCityChange}
                            renderInput={(params) =><TextField
                                {...params }
                                variant="outlined"
                                InputProps={{
                                    ...params?.InputProps,
                                    endAdornment: ( <KeyboardArrowDown />),
                                }}
                            />}
                        />
                    </Box>

                    <Box sx={webStyles.texfeildContainer}>
                        <Typography sx={webStyles.fontStyle_3}>{configJSON.startDate}</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={this.state.startDate}
                                onChange={this.handleStartDateChange}
                                format="eee, MMM dd"
                                data-testId={"startDate"}
                                maxDate={this.state.endDate || undefined}
                                slotProps={{
                                    textField: {
                                        sx: webStyles.datePickerTextField
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Box>

                    <Box sx={webStyles.texfeildContainer}>
                        <Typography sx={webStyles.fontStyle_3}>{configJSON.endDate}</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={this.state.endDate}
                                onChange={this.handleEndDateChange}
                                minDate={this.state.startDate || undefined}
                                format="eee, MMM dd"
                                data-testId={"endDate"}
                                slotProps={{
                                    textField: {
                                        sx: webStyles.datePickerTextField
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Box>

                    <Box sx={webStyles.texfeildContainer}>
                        <Typography sx={webStyles.fontStyle_3}>{configJSON.creditScore}</Typography>
                        <Box sx={webStyles.feildsConatiner}>
                            <CustomTextField
                                placeholder="350"
                                data-test-id = "LBCSFrom"
                                value={this.state.creditScoreFrom}
                                onChange={this.handleChangeCreaditScoreFrom}
                            />
                            <Typography sx={{ ...webStyles.fontStyle_3, color: '#475569 !important' }}>{configJSON.Textto}</Typography>
                            <CustomTextField
                                placeholder="800"
                                data-test-id = "LBCSTo"
                                value={this.state.creditScoreTo}
                                onChange={this.handleChangeCreaditScoreTo}
                            />
                        </Box>
                    </Box>

                    <Button
                        variant="contained"
                        fullWidth
                        sx={webStyles.SearchButton}
                        data-test-id={"searchloads"}
                        onClick={this.searchByFilter}
                    >
                        {configJSON.search}
                    </Button>
                </Box>
            </Box>
        )
    }

    renderFilteredData = () => {
        const {allLoadsdata, totalCount} = this.state;
        return (
            <Box sx={webStyles.cardStyle_2}>
                <Box data-test-id="filterDataBox" sx={webStyles.cardHeader}>
                    <Typography sx={webStyles.fontStyle_4}>{this.state.originValue?.attributes.city_ascii} to {this.state.destinationValue?.attributes.city_ascii} : {totalCount} Loads found</Typography>
                    
                    {allLoadsdata.map((loads:AllLoads) => (
                            <Box sx={webStyles.cardStyle} 
                                style={{
                                    boxShadow: loads.id === this.state.selectedLoadId 
                                        ? '0px 4px 4px 0px #A77F51'
                                        :"0px 4px 4px 0px #a6a6a6",
                                    border: loads.id === this.state.selectedLoadId 
                                        ?'2px solid #915F26'
                                        :'0.5px solid #BAC9DA'
                                    }} 
                                    data-test-id={"render_card"} 
                                    onClick={() => this.handleCardClick(loads.id)}
                                >
                                <Box sx={webStyles.cardDataBox}>
                                    <Box sx={webStyles.contentBox}>
                                        <Box sx={webStyles.locationContainer}>
                                            <Typography sx={webStyles.fontStyle_5}>{loads.attributes.origin_address?.city}</Typography>
                                            < CompareArrowsOutlinedIcon sx={{ fontSize: '20px', color: '#0F172A' }} />
                                            <Typography sx={webStyles.fontStyle_5}>{loads.attributes.destination_address?.city}</Typography>
                                        </Box>
                                        <ArrowBackIosIcon />
                                    </Box>
                                    <Box sx={webStyles.truckLoadDetails}>
                                        <Box sx={webStyles.cardDataBox}>
                                            <Box sx={webStyles.cardWeightData}>
                                                <WidgetsIcon sx={{ color: '#215089' }} />
                                                <Typography sx={webStyles.fontStyle_6}>{`Tons : ${loads.attributes.load_weight}`}</Typography>
                                            </Box>

                                            <Box sx={webStyles.cardWeightData}>
                                                <LinearScaleIcon sx={{ color: '#215089' }} />
                                                <Typography sx={webStyles.fontStyle_6}>{`KM : ${loads.attributes.total_miles}`}</Typography>
                                            </Box>

                                            <Box sx={webStyles.cardWeightData}>
                                                <Typography sx={webStyles.fontStyle_6}>Truck Type</Typography>
                                                <Typography sx={webStyles.fontStyle_6}>: Van </Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <img src={truckIcon} />
                                        </Box>
                                    </Box>
                                    <Divider sx={webStyles.dividerStyle} />
                                    <Box sx={webStyles.contentBox}>

                                        <Typography sx={webStyles.fontStyle_6}>{`Load Posted : ${this.dateFormate(loads.attributes.start_date,"dddd, MMMM D")}`}</Typography>
                                        <Typography sx={{ ...webStyles.fontStyle_6, fontSize: '24px !important', fontWeight: 700 }}> $ { Math.trunc(+loads.attributes.price)}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        ))
                    }

                </Box>
            </Box>
        )
    }

    renderTruckDetails = () => {
        const {loadDetails} = this.state;

        if (!loadDetails) {
            return null; 
        }

        return (
            <Box sx={webStyles.truckDetailsBox}>
                <Typography sx={webStyles.fontStyle_4}></Typography>
                <Box sx={webStyles.truckIconBox}>
                    <Box sx={webStyles.trcukPathBox}>
                        <Box sx={webStyles.truckDetails}>
                            <Typography sx={webStyles.fontStyle_7}>From</Typography>
                            <Typography sx={webStyles.fontStyle_add}>{loadDetails.origin_address.city}</Typography>
                        </Box>
                        <Box sx={webStyles.truckDetails}>
                            <Typography sx={webStyles.fontStyle_7}>To</Typography>
                            <Typography sx={webStyles.fontStyle_add}>{loadDetails.destination_address.city}</Typography>
                        </Box>
                    </Box>

                    <Box sx={webStyles.trcukPathBox}>
                        <Box sx={webStyles.truckDetails}>
                            <Typography sx={webStyles.fontStyle_7}>{configJSON.loadWeight}</Typography>
                            <Typography sx={webStyles.fontStyle_8}>{loadDetails.load_weight}</Typography>
                        </Box>
                        <Box sx={webStyles.truckDetails}>
                            <Typography sx={webStyles.fontStyle_7}>{configJSON.amount}</Typography>
                            <Typography sx={webStyles.fontStyle_8}>${loadDetails.price}</Typography>
                        </Box>
                    </Box>
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <img src={trcukdetailsIcon} />
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={'1rem'} p={'30px'}>
                    <Box display={'flex'} justifyContent={'space-between'} sx={{margin:"10px 0"}}>
                        <Typography sx={webStyles.fontStyle_9}>{configJSON.startDate}</Typography>
                        <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{loadDetails.start_date && this.dateFormate(loadDetails.start_date, "ddd, MMM DD")}</Typography>
                    </Box>
                    <Divider sx={webStyles.dividerStyle} />

                    <Box display={'flex'} justifyContent={'space-between'} sx={{margin:"10px 0"}}>
                        <Typography sx={webStyles.fontStyle_9}>{configJSON.endDate}</Typography>
                        <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{loadDetails.end_date && this.dateFormate(loadDetails.end_date, "ddd, MMM DD")}</Typography>
                    </Box>
                    <Divider sx={webStyles.dividerStyle} />

                    <Box display={'flex'} justifyContent={'space-between'} sx={{margin:"10px 0"}}>
                        <Typography sx={webStyles.fontStyle_9}>{configJSON.comodity}</Typography>
                        <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{loadDetails.commodity}</Typography>
                    </Box>
                    <Divider sx={webStyles.dividerStyle} />

                    <Box display={'flex'} justifyContent={'space-between'} sx={{margin:"10px 0"}}>
                        <Typography sx={webStyles.fontStyle_9}>{configJSON.totalMiles}</Typography>
                        <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{loadDetails.total_miles}</Typography>
                    </Box>
                    <Divider sx={webStyles.dividerStyle} />

                    <Box display={'flex'} justifyContent={'space-between'} sx={{margin:"10px 0"}}>
                        <Typography sx={{ ...webStyles.fontStyle_9 }}>
                            {configJSON.originAddress.split(' ')[0]}
                            <br />
                            {configJSON.originAddress.split(' ')[1]}
                        </Typography>
                        <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>
                            {loadDetails.origin_address.door_number}, {loadDetails.origin_address.street} <br />
                            {loadDetails.origin_address.city}, {loadDetails.origin_address.state} <br />
                            {loadDetails.origin_address.country} - {loadDetails.origin_address.zip_code}
                        </Typography>
                    </Box>
                    <Divider sx={webStyles.dividerStyle} />

                    <Box display={'flex'} justifyContent={'space-between'} sx={{margin:"10px 0"}}>
                        <Typography sx={{ ...webStyles.fontStyle_9 }}>
                            {configJSON.destinationAddress.split(' ')[0]}
                            <br />
                            {configJSON.destinationAddress.split(' ')[1]}
                        </Typography>
                        <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>
                            {loadDetails.destination_address.door_number}, {loadDetails.destination_address.street} <br />
                            {loadDetails.destination_address.city}, {loadDetails.destination_address.state} <br />
                            {loadDetails.destination_address.country} - {loadDetails.destination_address.zip_code}
                        </Typography>
                    </Box>
                    <Divider sx={webStyles.dividerStyle} />

                    <Box display={'flex'} justifyContent={'space-between'} sx={{margin:"10px 0"}}>
                        <Typography sx={webStyles.fontStyle_9}>{configJSON.loadDetails}</Typography>
                        <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{loadDetails.details}</Typography>
                    </Box>

                </Box>
            </Box>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { currentPage, totalPage,loading } = this.state;
        return (
            //Merge Engine DefaultContainer
            <Box sx={webStyles.container}>
                <HeaderNavigation navigation={this.props.navigation} id={""} />
                <Loader loading={loading} />
                <Box sx={webStyles.content}>
                    <NavigationMenu navigation={this.props.navigation} id={""} />
                    <Box sx={webStyles.mainContent}>
                         <Box sx={webStyles.tabContainer}>
                                <Typography sx={webStyles.fontStyle_1}>{this.state.originValue?.attributes.city_ascii} to {this.state.destinationValue?.attributes.city_ascii}</Typography>
                                <Box sx={webStyles.container_2}>
                                    {this.renderFilterBox()}
                                    {this.renderFilteredData()}
                                    {this.renderTruckDetails()}
                                </Box>
                                <Box sx={webStyles.paginationBox}>
                                    <Pagination
                                        count={totalPage}
                                        page={currentPage}
                                        onChange={this.handlePageChange}
                                        data-testId={"pagination"}
                                    />
                                </Box>
                            </Box>
                    </Box>
                </Box>
            </Box>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        color: '#475569',
        backgroundColor: '#FFFFFF',
        border: '1px solid #CBD5E1',
        '&:hover': {
            borderColor: '#CBD5E1',
        },
        '&.Mui-focused': {
            borderColor: '#CBD5E1',
        },
        '&.MuiOutlinedInput-notchedOutline': {
            borderColor: '#CBD5E1 !important',
        },
        '& input': {
            padding: '10px 8px 10px 8px',
            width: '90px',
            height: '40px',
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '16px',
            color: '475569'
        },
    },

}));

const webStyles = {
    container: {
        display: "flex",
        flexDirection: "column",
        overflowX: 'hidden'
    },
    content: {
        display: "flex",
        flex: 1,
    },
    mainContent: {
        flex: 1,
        padding: "2rem",
        backgroundColor: '#E2E8F0',
    },
    tabContainer: {
        backgroundColor: '#FFFFFF',
        border: '2px solid #215089',
        borderRadius: '16px',
        padding: '2rem',
    },
    container_2: {
        display: 'flex',
        gap: '30px',
        marginTop: '2%'
    },
    fontStyle_1: {
        fontFamily: "Inter",
        fontSize: "30px",
        fontWeight: 700,
        color: "#215089"
    },
    filterBox: {
        width: '20%',
        backgroundColor: '#FBE1C4',
        borderRadius: '8px',
        padding: '20px'
    },
    fontStyle_2: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        color: "#0C1C30"
    },
    fontStyle_3: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        color: "#0F172A"
    },
    feildBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        marginTop: '1rem'
    },
    texfeildContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    feildsConatiner: {
        display: 'flex',
        gap: '7px',
        alignItems: 'center'
    },
    selectBoxStyle: {
        borderRadius: '8px',
        backgroundColor: '#FFFFFF',
        padding: '10px 8px',
        height: '56px',
        border: '1px solid #CBD5E1',
        '&:hover': {
            borderColor: '#CBD5E1',
        },
        '&.Mui-focused': {
            borderColor: '#CBD5E1',
        },
        '& .MuiSelect-select': {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '16px',
            color: '#475569',
        },
        '& .MuiSvgIcon-root': {
            color: '#475569',
        },
    },
    datePickerTextField: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            backgroundColor: '#FFFFFF',
            '&:hover fieldset': {
                borderColor: '#CBD5E1',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#CBD5E1',
            },
        },
        '& .MuiInputBase-input': {
            color: '#475569',
            fontSize: '16px',
            fontFamily: 'Inter',
            fontWeight: 400,
        },
    },
    SearchButton: {
        color: '#FFFFFF',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: 700,
        fontFamily: 'Inter',
        padding: '10px 16px 10px 16px',
        background: 'linear-gradient(99.09deg, #915F26 2.64%, #F19F40 100%)',
        '&:hover': {
            background: 'linear-gradient(99.09deg, #915F26 2.64%, #F19F40 100%)',
        }
    },
    fontStyle_4: {
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 400,
        color: "#0C1C30"
    },
    cardStyle: {
        border: '2px solid #915F26',
        borderRadius: '16px',
        cursor: 'pointer',
        padding: '1rem'
    },
    locationContainer: {
        display: 'flex',
        gap: '5px',
        alignItems: 'center'
    },
    contentBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    cardDataBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
    },
    cardWeightData: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center'
    },
    dividerStyle: {
        borderStyle: 'dotted',
        borderWidth: '1px',
        borderColor: '#BAC9DA',
    },
    cardStyle_2: {
        width: '35%',
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    truckLoadDetails: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    fontStyle_5: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        color: "#1C1917"
    },
    fontStyle_6: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        color: "#0F172A"
    },
    paginationBox: {
        marginTop: '1rem',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    truckDetailsBox: {
        width: '40%',
        // marginTop: '4%',
        borderRadius: '16px',
        boxShadow: "0px 4px 4px 0px #00000040"
    },
    truckIconBox: {
        backgroundColor: '#E9EEF3',
        borderRadius: '16px',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem'
    },
    trcukPathBox: {
        width:"65%",
        display: 'flex',
        gap: '5rem',
        alignItems: 'center',
        justifyContent:"space-between"
    },
    truckDetails: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        width:"50%",
    },
    fontStyle_7: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400,
        color: "#0F172A"
    },
    fontStyle_8: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        color: "#1C1917",
        textAlign: 'Left',
        width:"60%"
    },
    fontStyle_add: {
        fontWeight: 700,
        color: "#1C1917",
        fontFamily: "Inter",
        fontSize: "16px",
    },
    fontStyle_9: {
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 400,
        color: "#475569",
        width:"40%"
    },
}
// Customizable Area End