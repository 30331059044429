import React from "react";

// Customizable Area Start
import { View, StyleSheet, } from "react-native";

import Box from '@mui/system/Box';

import CustomButton from "../../../components/src/CustomButton.web.";
import UnauthLayout from "../../../components/src/UnauthLayout.web";
import CheckIcon from '@mui/icons-material/Check';
const config = require('./config')


const newStyles : { [key: string]: React.CSSProperties }  = {
    errorMessage: {
      color: 'red',
      // marginTop: '10px',
      fontSize: '12px',
      textAlign: 'center',
      fontFamily:'Arial, Helvetica'
    },
   
  actionWrapper: { 
    marginBottom: '24px', 
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center', 
    justifyContent: 'space-between'
  },
  termAndConWrapper: {
    marginTop: "17px",
    marginBottom: "23px",
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px",
  },
  mainTitle:{
    fontWeight: 700,
    fontSize:'20px',
    fontFamily: 'Arial, Helvetica',
    marginTop:'60%',
    height:'30px'
    },
  inputText: { fontSize: 16, fontFamily: 'Inter', fontWeight: '400' },
  
    checkboxLabel: { fontFamily: 'Arial, Helvetica', fontWeight: '300', fontSize: '14px' },
    normalText:{
      fontSize:'16px',
      fontWeight:400,
      color:'#0F172A',
      fontFamily:"Montserrat",
      width:"89%",
    },
    termAndCon: {
        color: "#215089",
        fontFamily: "Arial, Helvetica",
        fontSize: "16px",
        fontWeight: "700",
        textDecoration: "none",
        cursor: "pointer",
        marginTop: "2px",
    },

    deactive:{
        color: "#215089",
        opacity:0.5,
        fontFamily: "Arial, Helvetica",
        fontSize: "16px",
        fontWeight: "700",
        textDecoration: "none",
        cursor: "not-allowed",
        marginTop: "2px",
    },

   resendCode:{
    color:'#475569',
    fontSize:'16px',
    fontWeight:400,
    fontFamily:"Arial, Helvetica",
    margin:'auto',
    marginTop:'-10px',
    marginBottom:''
   },
   optCon:{
    width:'70%',
    margin:'20px auto',
    display:'flex',
    gap:'10px'
},
   boxes:{
    width:'56px',
    padding:'10px',
    outline:'none',
    textAlign:'center',
    border:'1px solid #CBD5E1',
    borderRadius:8,
    height:'56px',
    fontSize:'18px'
},
errorBoxes:{
  width:'56px',
  padding:'10px',
  outline:'none',
  textAlign:'center',
  border:'1px solid #DC2626',
  borderRadius:8,
  height:'56px',
  fontSize:'18px'
},
successTitile:{
  fontSize:'36px',
  fontWeight:700,
  fontFamily:"Arial, Helvetica",
},
newBox:{
textAlign:'center',
display:'flex',
flexDirection:'column',
justifyContent:'space-evenly',
alignItems:'center',
gap:'30px',
marginTop:'50%'
},
newText:{
marginTop:'40px',
fontFamily:"Arial, Helvetica",
fontSize:'20px',
fontWeight:400,
lineHeight:'35px',
width:'70%'
} ,
btn:{
width:'100%',
marginTop:'30px'
}
  
}
// Customizable Area End

import OtpRegistrationPageController, {
  Props,
} from "./OtpRegistrationPageController";

export default class OtpRegistrationPage extends OtpRegistrationPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <UnauthLayout currentWidth={this.state.width}>
               { !this.state.succesPage &&
               (
                <>
                
                <Box component="h2" sx={newStyles.mainTitle}>
                Verify Email Address
                </Box>
                <Box sx={newStyles.normalText} >
                The 4 digits code has been sent to your {this.state.email}. Incase you don’t receive any mail in your INBOX.Please check in SPAM and other folders.Please enter the code below.
            </Box>
        <View style={{ marginTop: '22px' }}>
          <View style={{ marginBottom: '16px' }}>
            <div style={newStyles.optCon}>
            {
                this.state.otpValue.map((data,index)=>{
                    const inputStyle = (this.state.invalidIndexes.includes(index) || this.state.otpFocus)
                    ? newStyles.errorBoxes
                    : newStyles.boxes;
                    return(
                        <>
                        <input style={inputStyle} 
                        type="text"
                        value={data?'*':''}
                        data-test-id={`textOtp-${index}`}
                        maxLength={1}
                        onChange={(event)=>this.handleChange(index,event)} 
                        onKeyDown={(event)=>this.handleOnKeyDown(event,index)}
                        />
                        </>
                    )
                })
            }
            </div>
            {this.state.errorMessage && (
                <div style={newStyles.errorMessage}>
                  {this.state.errorMessage}
                </div>
              )}
            <div style={newStyles.resendCode}>
              <p>Resend code in <span style={newStyles.termAndCon}> {this.state.timer}</span> s</p>
            </div>

          </View>

          <Box sx={newStyles.actionWrapper}>
            
          </Box>
          <CustomButton 
            testId="btnForgotP" 
            onClick={this.otpConfirm} 
            text="VERIFY"
          />
          <Box sx={newStyles.termAndConWrapper}>
          <span style={newStyles.checkboxLabel}>I didn’t received the code?</span>
                  <span
                    data-test-id="btnTermAndCondition"
                    style={this.state.timerActive? newStyles.deactive:newStyles.termAndCon}
                    onClick={this.resetTimer}
                  >
                    Send again
                  </span>
                </Box>
        </View>
        </>
               )
        }
         {
          this.state.succesPage &&
          (
            <>
            <Box sx={newStyles.newBox}>
            <Box component="h2" sx={newStyles.successTitile}>
              {!this.state.newPage ?'Verified !':'Congratulations !'}
            </Box>
            <Box sx={newStyles.tickicons}>
              <CheckIcon style={{color:'white',width:'50.23px',height:'50.07',border:'2px solid #34D399',borderRadius:'40px',background:'#34D399'}} />
            </Box>
            <Box sx={newStyles.newText}>
            {!this.state.newPage?'Your have successfully verified the account.':'Your account has been  successfully created.'}
            </Box>

            <Box sx={newStyles.btn}>
             {!this.state.newPage? <CustomButton
                testId="btnForgotP"
                onClick={this.continue}
                text="CONTINUE"
                />:
                <CustomButton
                testId="btnForgotP"
                onClick={this.sendTodashboard}
                text="CONTINUE"
                />}
                </Box>
            </Box>
          </>
          )

        }
      </UnauthLayout>
          
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  main: {
    display: "flex",
    alignItems: "center"
  },

});
