import React, { Component } from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  MenuItem,
} from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
interface MyProps {
  dataTestId?: string;
  title?: string;
  placeholder?: string;
  required?: boolean;
  isTextInputShown?: boolean;
  isDatePickerShown?: boolean;
  datePickerLabel?: string;
  isSelectInputShown?: boolean;
  selectInputPlaceholder?: string;
  value: string;
  onChange: (name: string, val: string) => void;
  name?: string;
  errorMsg?: string;
  width?: string;
  height?: string;
  multiline?: boolean;
  rows?: number;
  maxLength?: number;
  disabled?:boolean;
  maxDate?:Date,
  minDate?:Date
}

interface MyState {
}

const webStyles = {
  title: {
    fontFamily: "Inter",
    fontSize: '16px',
    fontWeight: 400,
    color: "#0F172A"
  },
  input: {
    height: '56px',
    borderRadius: '8px',
    padding: '10px 8px 10px 8px',
    marginLeft: '-10px',
    width: '327px'
  }
}

export default class LoadTextInput extends Component<MyProps, MyState> {
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.props.onChange(name, value);
  };
  handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    this.props.onChange(this.props.name || '', value);
  }

  render() {
    const { title, placeholder, required, isTextInputShown,
      isDatePickerShown, datePickerLabel, isSelectInputShown, value,
      selectInputPlaceholder, name, errorMsg, width, height, multiline, maxLength, disabled = false, maxDate, minDate } = this.props;

    const inputStyle = { ...webStyles.input, width: width || webStyles.input.width, height: height || webStyles.input.height };

    return (
      <Box style={{
        display: "flex",
        flexDirection: "column",
      }}>
        <Typography style={webStyles.title}>{title} {required && <span style={{ color: 'red' }}>*</span>}</Typography>

        {isTextInputShown &&
          <Box sx={{
            width: '327px'
          }}>
            <TextField
              data-test-id={this.props.dataTestId}
              id="outlined-basic"
              name={name}
              variant="outlined"
              placeholder={placeholder}
              style={inputStyle}
              disabled={disabled}
              inputProps={
                {
                  sx: {
                    '&::placeholder': {
                      color: '#475569',
                      opacity: 1
                    },
                  },
                  maxLength: maxLength,
                }
              }
              multiline={multiline}
              rows={this.props.rows}
              value={value}
              onChange={this.handleChange}
            />
            {errorMsg && (
              <div style={{
                color: 'red',
                fontSize: '12px',
                fontFamily: 'Arial, Helvetica',
                marginTop: multiline ? '20px' : '0px',
              }}>
                {errorMsg}
              </div>
            )}
          </Box>
        }

        {isDatePickerShown &&
          <Box sx={{
            width: '327px'
          }}>
            <div style={{ width: '327px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                  <DatePicker

                    format="YYYY-MM-D"
                    onChange={(date) => this.props.onChange(this.props.name ? this.props.name : '', JSON.stringify(date))}
                    slots={{
                      openPickerIcon: CalendarTodayIcon,
                    }}
                    minDate={minDate || undefined}
                    maxDate={maxDate || undefined}
                    slotProps={{
                      openPickerIcon: { fontSize: '20px' },
                      openPickerButton: {
                        sx: {
                          color: '#0F172A',
                        },
                      },
                      textField: {
                        placeholder: datePickerLabel,
                        hiddenLabel: true,
                        sx: {
                          width: '100%',
                          '& .MuiInputBase-input::placeholder': {
                            color: '#475569',
                            opacity: 1,
                          },
                        },
                      }
                    }}


                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            {errorMsg && (
              <div style={{
                color: 'red',
                fontSize: '12px',
                marginTop: '10px',
                fontFamily: 'Arial, Helvetica'
              }}>
                {errorMsg}
              </div>
            )}
          </Box>
        }

        {isSelectInputShown &&
          <Box sx={{
            width: '327px'
          }}>
            <FormControl sx={{ m: 1, width: 300 }}>

              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={value}
                style={webStyles.input}
                onChange={this.handleSelectChange}
                sx={{
                  height: 50,
                  borderRadius:8
                }}

                displayEmpty={true}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <span style={{
                      fontFamily: 'Inter',
                      marginLeft: '-10px',
                      color: '#475569',
                      opacity: 0.9,
                      fontSize: '16px'
                    }}>{selectInputPlaceholder}</span>;
                  }

                  return selected;
                }}
                IconComponent={KeyboardArrowDownIcon}
              >

                {['Test Data 1',
                  'Test Data 2',
                  'Test Data 3',
                  'Test Data 4',
                  'Test Data 5',].map((name) => (

                    <MenuItem
                      key={name}
                      value={name}
                    >
                      {name}
                    </MenuItem>
                  ))}
              </Select>

            </FormControl>
            {errorMsg && (
              <div style={{
                color: 'red',
                fontSize: '12px',
                fontFamily: 'Arial, Helvetica'
              }}>
                {errorMsg}
              </div>
            )}
          </Box>

        }


      </Box>
    );
  }
}

