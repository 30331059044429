import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible} from "./assets";
interface ApiResponse {
    messages?: Messages[];
    errors?: Error[];
}

interface Messages {
    otp: string;
}

interface Error {
    pin?: string;
    token?: string;
    otp?:string;
}


interface ApiResponse2 {
  errors?: {
    otp: string;
  }[];
  data?: {
    id: string;
    type: string;
    attributes: {
      id: number;
      email: string;
      pin: number;
      activated: boolean;
    };
  };
  meta?: {
    token: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  checkedRememberMe: boolean;
  enablePasswordField: boolean;
  placeHolderPassword: string;
  placeHolderEmail: string;
  imgPasswordInVisible: any;
  imgPasswordVisible: any;
  btnTxtLogin: string;
  labelHeader: string;
  btnTxtSocialLogin: string;
  labelRememberMe: string;
  labelOr: string;
  btnTxtGoogleLogin: string;
  otpFocus: boolean;
  passwordFocus: boolean;
  emailError: boolean;
  passwordError: boolean;
  width: number;
  errorMessage: string | undefined;
  otpValue:string[];
  invalidIndexes: number[];
  timer:number;
  timerActive:boolean;
  token:string | null,
  finalOtp:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OtpPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  labelPassword: string = "";
  labelTitle: string = "";
  textSignup: string = "";
  labelEmail: string = "";
  checkingOtpApiId:string = "";
  timerInterval: NodeJS.Timeout | null = null;
  sendOtpApiId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderPassword: configJSON.placeHolderPassword,
      placeHolderEmail: configJSON.placeHolderEmail,
      imgPasswordInVisible: imgPasswordInVisible,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelHeader: configJSON.labelHeader,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelRememberMe: configJSON.labelRememberMe,
      labelOr: configJSON.labelOr,
      btnTxtGoogleLogin: configJSON.btnTxtGoogleLogin,
      passwordError: false,
      emailError: false,
      otpFocus: false,
      passwordFocus: false,
      errorMessage: "",
      width: window.innerWidth,
      otpValue:Array(4).fill(''),
      invalidIndexes:[],
      timer:59,
      timerActive:true,
      token:localStorage.getItem('token'),
      finalOtp:''
    };


    this.labelTitle = configJSON.labelTitle;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.textSignup = configJSON.textSignup;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    window.addEventListener('resize', () => this.handleWidthChange())
    this.startTimer();
    // Customizable Area End
  }

  // Customizable Area Start
  handleWidthChange = () => {
    this.setState({ width: window.innerWidth });
  }
  // Web Event Handling
  startTimer = () => {
    this.setState({ timer: 59, timerActive: true });
    this.timerInterval = setInterval(() => {
        this.setState((prevState) => {
            if (prevState.timer > 0) {
                return { ...prevState, timer: prevState.timer - 1 };
            } else {
                if (this.timerInterval) {
                    clearInterval(this.timerInterval);
                }
                return { ...prevState, timerActive: false };
            }
        });
    }, 1000);
}
  resetTimer = () => {
    if (!this.state.timerActive) {
      this.startTimer();
      this.sendOtpAgain()
    }
  }

  handleChange(index:number, event:React.ChangeEvent<HTMLInputElement>) {
    if (isNaN(Number(event.target.value))) return;
    const updatedOTP = this.state.otpValue.map((value, newIdx) => (newIdx === index ? event.target.value : value));
    this.setState({otpValue:updatedOTP})

    if(event.target.value && event.target.nextElementSibling){
      (event.target.nextElementSibling as HTMLInputElement).focus();
    }
  }
  handleOnKeyDown = (event:any,index:number)=>{
    if (event.key === "Backspace") {
      if (this.state.otpValue[index] === "") {
          if (event.target.previousElementSibling) {
              (event.target.previousElementSibling as HTMLInputElement).focus();
          }
      } else {
          const updatedOTP = this.state.otpValue.map((value, newIdx) => 
              (newIdx === index ? "" : value)
          );
          this.setState({ otpValue: updatedOTP });
      }
  }
  }
  
  otpConfirm = ()=>{
      const missing = this.state.otpValue.map((data:string,index:number)=>{
        if(data===''){
            return index
        }
        else{
          return undefined
        }
      }).filter((item): item is number => item !== undefined);
    this.setState({ invalidIndexes: missing })
   
    if (missing.length === 4) {
      this.setState({
        invalidIndexes: missing,
        errorMessage: "OTP code is required." 
      });
      return;
    }

    const converted = this.state.otpValue.join('');

    if (converted.length < 4) {
      this.setState({
        errorMessage: "Invalid OTP length. Please enter a 4-digit OTP."
      });
      return;
    }

      this.setState({finalOtp:converted},()=>{this.checkingOtp()})
  }

  checkingOtp() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkingOtpApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: localStorage.getItem('token'),
      otp_code: this.state.finalOtp
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOtpRes(response:ApiResponse){
    if(response.errors){
      this.setState({errorMessage:response.errors[0].pin,otpFocus:true})

      response.errors.forEach((error) => {
        if(error.pin){
          this.setState({errorMessage:error.pin})
        }
        if(error.otp){
          this.setState({errorMessage:error.otp})
        }
        if(error.token){
          this.setState({errorMessage:error.token})
        }
      });
    }else{
      const msg: Message = new Message(
        getName(MessageEnum.NavigationNewPasswordMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.AuthTokenDataMessage), this.state.token)
        this.send(msg);
    }
  }


  sendOtpAgain() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendOtpApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_forgot_password/otps'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const data = {
      attributes: {
        email: localStorage.getItem('email'),
      },
    };
    const httpBody = {
      data: data,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleResendOtp= (response:ApiResponse2)=>{
    if(response.data){
      if (response.meta?.token) {
      localStorage.setItem('token', response.meta.token);
    }
  }
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (apiRequestCallId === this.checkingOtpApiId) {
      this.handleOtpRes(responseJson);
    }
    if(apiRequestCallId === this.sendOtpApiId){
      this.handleResendOtp(responseJson);
    }
      
    

    // Customizable Area End
  }
}
