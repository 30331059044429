import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData} from "../../../framework/src/Utilities";
import React from "react";
import { Box, Typography } from "@mui/material";

  interface Address {
    city: string;
    state: string;
    address: string;
    country: string;
    zip_code: string;
  };
  interface ApplicantAttributes {
    trucker_id: number;
    user_profile_image_url: string | undefined;
    first_name: string;
    last_name: string;
    phone_number: string;
    insurance: string;
    authority: string;
    driving_license_number: string;
    mc_number: string;
    status: string;
  };
  interface Applicant  {
  id: string;
  type: string;
  attributes: ApplicantAttributes;
  }
  interface ErrRes {
    error?: string;
    message?: string;    
  }
  interface StatusesResponse {
        label: string;
  };
  interface DriverDetails {
    id: string;
    type: string,
    attributes:{
        load_weight:string,
        total_miles:string,
        applications_count:0,
        origin_address: Address;
        destination_address: Address;
        price: string;
        status: string;
        start_date: string;
        end_date: string;
        commodity: string;
        load_details: string;
        avatarSrc: string;
    },
  }
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    tabValue: number;
    loadsDataList: DriverDetails[];
    selectedCardId: string;
    status: string;
    applicantsList: Applicant[];
    expandedApplicantId: string;
    selectedStatuses: string[];
    statuses: string[];
    allStatus: StatusesResponse[];
    menuAnchorEl: any;
    selectedMenuId: string | null;
    isOpenApprove: boolean;
    applicantName: string;
    applicationId: string;
    errorMessage: string;
    successMessage: string;
    isOpenReject: boolean;
    isChatShow:boolean;
    // Customizable Area End
}
interface SS { }

export default class MyLoadsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    showAllMyLoadsAPICallId:string = '';
    applicationsAPICallId:string = ''; 
    showAllStatusAPICallId:string = '';
    showFilterLoadsAPICallId:string = '';
    getApprovedAPICallId:string = '';
    getRejectedAPICallId:string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),            
        ];

        this.state = {
            tabValue: 0,
            selectedCardId: '0',
            status: '',
            loadsDataList: [],
            applicantsList: [],
            expandedApplicantId: '0',
            selectedStatuses: [],
            statuses: [],
            allStatus: [],
            menuAnchorEl: null, 
            selectedMenuId: null,
            isOpenApprove: false,
            applicantName: '',
            applicationId: '',
            errorMessage: '',
            successMessage:'',
            isOpenReject: false,
            isChatShow: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.showAllMyLoads()
        this.getAllStatus()
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start  
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && !responseJson.errors) {
                if (apiRequestCallId === this.showAllMyLoadsAPICallId) {
                    this.handleAllMyLoadData(responseJson);
                } else if (apiRequestCallId === this.applicationsAPICallId) {
                    this.handleApplicationsData(responseJson);
                } else if (apiRequestCallId === this.showAllStatusAPICallId) {
                    this.handleAllStatusData(responseJson);
                } else if (apiRequestCallId === this.showFilterLoadsAPICallId) {
                    this.handleAllMyLoadData(responseJson);
                } else if (apiRequestCallId === this.getApprovedAPICallId) {
                    this.handleSuccessApprovedData(responseJson);
                } else if (apiRequestCallId === this.getRejectedAPICallId) {
                    this.handleSuccessApprovedData(responseJson);
                } 
            }
        }     
        // Customizable Area End
    }
    // Customizable Area Start
    handleAllStatusData = (responseJson:{ statuses: StatusesResponse[] })=>{   
        const statusLabels = responseJson.statuses.map(status => status.label);
        this.setState({statuses:statusLabels})
    }
    handleAllMyLoadData=(responseJson:{ data: DriverDetails[] })=>{   
        this.setState({loadsDataList:responseJson.data},()=>{            
            responseJson.data.length && this.setState({selectedCardId:this.state?.loadsDataList[0].id})
            responseJson.data.length && this.getApplicationsData(this.state?.loadsDataList[0].id)
        })
    }
    handleApplicationsData=(responseJson:{ data: Applicant[] })=>{    
        this.setState({applicantsList:responseJson.data })
    }
    handleSuccessApprovedData = (responseJson: ErrRes )=>{ 
        if(responseJson.error){
            this.setState({errorMessage:responseJson.error }, ()=>{
                setTimeout(()=>{
                    this.setState({errorMessage:'' })
                }, 5000)
            })
        }
        if(responseJson.message){
            this.setState({successMessage:responseJson.message }, ()=>{
                setTimeout(()=>{
                    this.setState({successMessage:'' })
                }, 5000)
            })
        }
        this.handleCloseApproveModal();
        this.handleCloseRejectModal();
        this.setState({applicantName: "", applicationId:''})
    }
    showAllMyLoads = async () => {      
            this.showAllMyLoadsAPICallId = await this.apiCall({
                method: configJSON.getApiMethodType,
                endPoint: configJSON.myLoadsPath,
                token: await getStorageData("LoginToken")
              });
    };
    apiCall = async (data: any) => {
        const { method, endPoint, body, token, isJsonStringify } =
          data;
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
          "Content-Type": configJSON.apiContentType,
          token: token,
        };
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        body &&
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            isJsonStringify ? JSON.stringify(body) : body
          );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
      };
    getApplicationsData = async (selectedId:string) => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: await getStorageData("LoginToken")
        };
        const requestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.applicationsAPICallId = requestMsg.messageId;

        requestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.applicationsPath}?load_id=${selectedId}`);
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMsg.id, requestMsg);
    };
    getAllStatus = async() => {
        this.showAllStatusAPICallId = await this.apiCall({
            method: configJSON.getApiMethodType,
            endPoint: configJSON.getStatusesPath,
            token: await getStorageData("LoginToken")
        });
    }
    getFilterLoads = async() => {
        const newPath = this.state.selectedStatuses.map(status => `statuses[]=${status}`).join('&');         
        this.showFilterLoadsAPICallId = await this.apiCall({
            method: configJSON.getApiMethodType,
            endPoint: `${configJSON.myLoadsPath}?${newPath}`,
            token: await getStorageData("LoginToken")
        });
    }
    handleApproved = async() => {
        this.setState({errorMessage:'', successMessage:'' })
        this.getApprovedAPICallId = await this.apiCall({
            method: configJSON.patchApiMethodType,
            endPoint: `${configJSON.applicationsPath}/${this.state.applicationId}/approve`,
            token: await getStorageData("LoginToken")
        });       
    };
    handleRejected = async() => {
        this.getRejectedAPICallId = await this.apiCall({
            method: configJSON.patchApiMethodType,
            endPoint: `${configJSON.applicationsPath}/${this.state.applicationId}/reject`,
            token: await getStorageData("LoginToken")
        });       
    };
    handleOpenApproveModal = (applicationId: string, firstName: string, lastName: string) => {
        this.setState({ isOpenApprove: true, applicantName: firstName +" "+ lastName, applicationId })       
      }
    handleCloseApproveModal = () => {
        this.setState({ isOpenApprove: false })
    }
    handleCloseRejectModal = () => {
        this.setState({ isOpenReject: false })
    }
    handleOpenRejectModal = (applicationId: string, firstName: string, lastName: string) => {
        this.setState({ isOpenReject: true, applicantName: firstName +" "+ lastName, applicationId })       
    }
    handleCardClick = (cardId: string) => {
        this.setState({ selectedCardId: cardId },()=>{
            this.getApplicationsData(cardId)
        });       
    };
    isCardSelected = (cardId: string): boolean => {
        return cardId === this.state.selectedCardId;
    };
    handleApplicant = (applicantId: string) => {
        this.setState({
          expandedApplicantId: applicantId,
        });
    };
    handleApplicantDetail = () => {
        this.setState({
          expandedApplicantId: '0',
        });
    };
    handleChange = (event: any) => {
        const value = event.target.value;
        const { selectedStatuses, statuses } = this.state;
        if (value.includes("All")) {
            this.setState({
                selectedStatuses: selectedStatuses.length === statuses.length ? [] : statuses
            }, ()=>{ this.getFilterLoads()});
            return;
        }
        this.setState({
            selectedStatuses: value
        }, ()=>{ this.getFilterLoads()});
      };
      handleButtonClick = (event: any, cardId: string) => {
        this.setState({ menuAnchorEl: event.currentTarget as HTMLElement, selectedMenuId: cardId, });
      };
    
      handleEditMenuClose = () => {
        this.setState({ menuAnchorEl: null });
      };
      handleNavigation = (route: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      };
      getBorder = (cardItemId: string) =>{
        return this.isCardSelected(cardItemId) ? '2px solid #7b6344' : ''
      }
      getBoxShadow = (cardItemId: string) =>{
        return this.isCardSelected(cardItemId) ? '0px 4px 4px 0px #a87f51' : '0px 4px 4px 0px #00000040'
      }
      showSuccMessage = () => {
        const {errorMessage, successMessage} = this.state;
        const webStyles = {
          msgContainer: { 
            width: '100%', marginTop:'20px',
          },
          messageText: { 
            fontFamily: 'Arial, Helvetica',fontSize: 16, fontWeight: '400',marginTop: 0, margin: 10,            
          }}
          
        return (
            <Box style={webStyles.msgContainer}>
                {successMessage && 
                <Typography style={{...webStyles.messageText, padding:'5px', borderRadius: '4px', textAlign: 'center',backgroundColor:'#e8fff7', borderLeft: '5px solid #34D399', color:'#34D399'}}>
                    {successMessage}
                </Typography>}
                {errorMessage && <Typography style={{...webStyles.messageText,  padding:'5px',borderRadius: '4px',textAlign: 'center', backgroundColor:'#FEE2E2', borderLeft:'5px solid #DC2626', color:'#DC2626'}}>
                    {errorMessage}
                </Typography>}
            </Box>
          )
      }
      closeChat = ()=>{
        this.setState({isChatShow:false})
      }
      chatStart = (firstName: string, lastName: string)=>{        
        this.setState({isChatShow:true, applicantName: firstName +" "+ lastName,})
      }
    // Customizable Area End
}