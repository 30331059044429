import React, { ReactNode } from 'react'
import { StyleSheet } from 'react-native'

export function AuthPageTitle({ children }: { children: ReactNode }) {
  return <label style={styles.text}>{children}</label>
}

const styles = StyleSheet.create({
  text: {
   lineHeight: 2,
   fontSize: 14,
   fontWeight: '700',
   fontFamily: 'Inter'
  }
})
