import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
} from "@mui/material";
import {
  styled
} from "@mui/material/styles";
import { ChatContainer, 
  MessageList, 
  MessageInput, 
  ConversationHeader, 
  Avatar, 
  Message, 
  MainContainer, 
  Sidebar, 
  Search, 
  ConversationList, 
  Conversation} from '@chatscope/chat-ui-kit-react';
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import HeaderNavigation from "../../navigationmenu/src/HeaderNavigation.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { MoreVert } from "@mui/icons-material";

const usersList = [
  {
    name:"Gunther Beard",
    count:1,
    info:"Lorem Ipsum is simply dummy text of the printing",
    time:"15 min",
    status:true,
    available:true
  },
  {
    name:"Burgundy Flemming",
    count:2,
    info:"Lorem Ipsum is simply dummy text of the printing",
    time:"30 min",
    status:false,
  },
  {
    name:"Ingredia Nutrisha",
    info:"Lorem Ipsum is simply dummy text of the printing",
    time:"5 hour",
    status:false,
  },
  {
    name:"Douglas Lyphe",
    count:1,
    info:"Lorem Ipsum is simply dummy text of the printing",
    time:"mon",
    status:false,
  },
  {
    name:"Gunther Beard",
    count:1,
    info:"Lorem Ipsum is simply dummy text of the printing",
    time:"15 min",
    status:true,
  },
  {
    name:"Burgundy Flemming",
    count:2,
    info:"Lorem Ipsum is simply dummy text of the printing",
    time:"30 min",
    status:false,
  },
  {
    name:"Ingredia Nutrisha",
    info:"Lorem Ipsum is simply dummy text of the printing",
    time:"5 hour",
    status:false,
  },
  {
    name:"Douglas Lyphe",
    count:1,
    info:"Lorem Ipsum is simply dummy text of the printing",
    time:"mon",
    status:true,
  },
]

const messageLists = [
  {
    direction: true,
    message: 'It is a long established fact that a reader.',
    sentTime: '15 mins ago'
  },
  {
    direction: false,
    message: 'Lorem Ipsum is simply dummy text of the printing..',
    sentTime: '15 mins ago'
  },
  {
    direction: true,
    message: 'It is a long established fact that a reader.',
    sentTime: '15 mins ago'
  },
  {
    direction: false,
    message: 'Lorem Ipsum is simply dummy text of the printing..',
    sentTime: '15 mins ago'
  },
  {
    direction: true,
    message: 'It is a long established fact that a reader.',
    sentTime: '15 mins ago'
  },
]

const MainContainerBox = styled(Box)(({ theme })=> ({
  display: "flex",
  flexDirection: "column",
  overflowX: 'hidden',
  height: "100vh",
  "*":{
    fontFamily:"inter"
  },
  ".cs-conversation" : {
    marginTop:25,
    "& .cs-avatar":{
    height:91,
    width:91
  }},
  ".cs-status--available .cs-status__bullet":{
     background:"#80EA4E"
  },
  ".cs-conversation__name":{
    fontSize:18,
    color:"#1E293B",
    fontWeight:700,
    marginBottom:10,
  },
  ".cs-conversation__info-content":{
    color:"#78716C",
    fontSize:16,
    lineHeight:2
  },
  ".cs-conversation__unread": {
    backgroundColor: "#F19F40", 
    color: "#ffffff",
    borderRadius: "50%",
    height:"41px",
    width:"41px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    top:"3.5rem",
    fontSize:20,
    fontWeight:700,
    fontFamily:"inter"
  },
  ".cs-conversation__info":{
    whiteSpace:"wrap"
  },
  ".cs-main-container > .cs-sidebar.cs-sidebar--left":{
    maxWidth:"547px",
    width:"40%",
    border:0
  },
  ".cs-conversation__last-activity-time":{
    alignItems:"flex-start"
  },
  ".cs-conversation__content":{
    flexGrow:0,
    width:"50%"
  },
  ".cs-conversation-header":{
    background:"#215089",
    borderRadius:25,
    padding:"30px"
  },
  ".cs-conversation-header__actions":{
    color:"#ffffff",
    ".css-i4bv87-MuiSvgIcon-root":{
      fontSize:"2.5rem"
    }
  },
  ".cs-conversation-header__content":{
    justifyContent:"space-around"
  },
  ".cs-conversation-header__avatar, .cs-avatar.cs-avatar--md":{
    height:90,
    width:88
  },
  ".cs-conversation-header__user-name":{
    background:"#215089",
    color:"#FFFFFF",
    fontSize:24,
    fontWeight:700
  },
  ".cs-conversation-header__info":{
    background:"#215089",
    color:"#FFFFFF",
    fontSize:16,
    fontWeight:400
  },
  ".cs-main-container":{
    border:0,
    height:"auto!important"
  },
  ".cs-chat-container":{
    border:"1px solid #DEE5ED",
    borderRadius:24,
    height:"auto",
    marginLeft:25
  },
  ".cs-message--incoming .cs-message__content": {
    backgroundColor: "#E9EEF3",
    padding:"30px 20px",
    fontSize:18,
    marginTop:20
  },
  ".cs-message--outgoing .cs-message__content":{
    backgroundColor: "#BAC9DA",
    padding:"30px 20px",
    fontSize:18,
    marginTop:20
  },
  ".cs-message-input":{
    borderTop:0,
    boxShadow:"4px 4px 0px 0 #00000033",
    borderRadius:36,
    margin:20,
    marginBottom:30 
  },
  ".cs-message-input__content-editor-wrapper,.cs-message-input__content-editor":{
    backgroundColor:"#fff"
  },
  ".cs-button--attachment, .cs-button--send": {
    color: "#A8A29E",
    alignSelf:"center"
  },
  ".cs-search":{
    backgroundColor:"#fff",
    borderRadius:4,
    border:"1px solid #CBD5E1",
    maxWidth:344,
  },
  ".cs-search__input":{
    backgroundColor:"#fff",
    "& ::placeholder":{
      color:"#94A3B8"
    }
  },
  ".cs-search__search-icon":{
    color:"#94A3B8"
  },
  
})) ;
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainContainerBox data-data-id="chat-main-container">
        <HeaderNavigation navigation={this.props.navigation} id={""} />
        <Box sx={webStyles.content}>
          <NavigationMenu navigation={this.props.navigation} id={""} />
          <Box sx={webStyles.mainContent}>
            <Box sx={webStyles.cardContainer}>
              <Typography style={webStyles.headerFont}>{configJSON.labelTitleText}</Typography>
              <Box sx={webStyles.cardBox}>
                <MainContainer
                  responsive
                  style={{
                    height: '600px'
                  }}
                >
                  <Sidebar
                    position="left"
                    style={{ height: '100%', overflowY: 'auto' }} // Ensure the sidebar can scroll
                    scrollable={true}
                  >
                    <Search 
                      data-test-id="search-bar"
                      value={this.state.searchUser}
                      onChange={this.handleChangeSearch}
                      placeholder="Search" />

                    <ConversationList>
                    {usersList.map((userList, index)=>{
                      return (
                          <Conversation
                            key={index}
                            info={userList.info}
                            name={userList.name}
                            lastActivityTime={<span style={{
                              color: "#94A3B8",
                              fontSize:18
                            }}>{userList.time}</span>}
                            unreadCnt={userList.count}
                          >
                            <Avatar
                              name="Lilly"
                              size="fluid"
                              src="https://chatscope.io/storybook/react/assets/lilly-aj6lnGPk.svg"
                              status={userList.status ? "available" : "invisible"}
                            />
                          </Conversation>
                      )
                    })}
                    </ConversationList>
                    
                  </Sidebar>

                  <ChatContainer>
                    <ConversationHeader>
                      <ConversationHeader.Back />
                      <Avatar
                        name="Zoe"
                        src="https://chatscope.io/storybook/react/assets/zoe-E7ZdmXF0.svg"
                      />
                      <ConversationHeader.Content
                        info="Active Now"
                        userName="Hugh Natasha"
                      />
                      <ConversationHeader.Actions>
                        <MoreVert />
                      </ConversationHeader.Actions>
                    </ConversationHeader>
                    <MessageList>
                      {messageLists.map((mList,index) => (
                        <Message
                          key={index}
                          model={{
                            direction: mList.direction? "incoming":"outgoing",
                            message: mList.message,
                            position: 'single',
                            sender: 'Zoe',
                            sentTime: '15 mins ago'
                          }}
                        >
                        </Message>
                      ))}
                    </MessageList>
                    <MessageInput
                      placeholder="Type a message"
                    />
                  </ChatContainer>
                </MainContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      </MainContainerBox>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    overflowX: 'hidden',
    height:"100vh"
  },
  content: {
    display: "flex",
    flex: 1,
  },
  mainContent: {
    flex: 1,
    padding: "2rem",
    backgroundColor: '#E2E8F0',
  },
  cardContainer: {
    backgroundColor: '#FFFFFF',
    border: '2px solid #215089',
    borderRadius: '16px',
    padding: '2rem',
  },
  headerFont: {
    fontFamily: "Inter",
    fontSize: '30px',
    fontWeight: 700,
    color: "#215089"
  },
  cardBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    marginTop: '2%',
    marginBottom: '5%'
  },
};
// Customizable Area End
