import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import HeaderNavigation from "../../navigationmenu/src/HeaderNavigation.web";
import {Box,TextField,Grid} from "@mui/material"
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import CustomButton from "../../../components/src/CustomButton.web.";
import { tickIcon ,crossIcon} from "./assets";
const newStyles  = {
  inputContainer: {
    height: '56px',
    border: '1px solid #CBD5E1',
    borderRadius: 12,
    borderStyle: 'solid',
    width: '327px',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: 400
  },
}
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
       <Box sx={webStyles.container}>
        <HeaderNavigation navigation={this.props.navigation} id={""} />
        <Box sx={webStyles.content}>
          <NavigationMenu navigation={this.props.navigation} id={""} />
          <Box sx={webStyles.mainContent}>
            <Box sx={webStyles.cardContainer}>
            <div style={webStyles.headerFont}>Change Password</div>
            <Box sx={{ flexGrow: 1, p: 3 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6} sx={{marginLeft:'-20px'}} >
                      <Grid container spacing={4} sx={{ width: '800px'  }} >

                        <Grid item xs={12} sm={6} sx={{ mb: 2 }} >

                          <label style={{
                            lineHeight: 2,
                            fontSize: 14,
                            fontWeight: 700,
                            fontFamily: 'Inter'
                          }} >Old Password</label>

                          <TextField
                            data-test-id="oldpass"
                            name="oldPassword"
                            style={newStyles.inputContainer}
                            type={this.state.enableOldPasswordField ? 'password' : 'text'}
                            placeholder={"Your Old Password"}
                            value={this.state.oldPassword}
                          // error={this.state.emailError ? this.state.errorMessage : ""}
                            onChange={this.handleChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Button
                                    data-test-id="btnTogglePassword"
                                    aria-label="toggle password visibility"
                                    onPress={this.handleClickShowPassword}
                                    style={{backgroundColor: "transparent"} }
                                    textStyle={{ color: '#94A3B8' }}
                                    icon={this.state.enableOldPasswordField ? (
                                      <VisibilityOffOutlinedIcon style={{ color: "#94A3B8" }} />
                                    ) : (
                                      <RemoveRedEyeOutlinedIcon style={{ color: "#94A3B8" }} />
                                    )}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                          {this.state.oldPasserrormessage && (
                              <div style={{color: 'red',
                              marginTop:'4px',
                              marginLeft:'-110px',
                              fontSize: '12px',
                              textAlign: 'center',
                              width:'100%',
                              fontFamily:'Arial, Helvetica'}}>
                                {this.state.oldPasserrormessage}
                              </div>
                            )}

                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ mb: 2 }} >
                        <label style={{
                            lineHeight: 2,
                            fontSize: 14,
                            fontWeight: 700,
                            fontFamily: 'Inter'
                          }} >New Password</label>
                          <TextField
                            data-test-id="txtInputLastName"
                            name="newPassword"
                            style={newStyles.inputContainer}
                            type={this.state.enableNewPasswordField ? 'password' : 'text'}
                            placeholder={"Your New Password"}
                            value={this.state.newPassword}
                          // error={this.state.emailError ? this.state.errorMessage : ""}
                          onChange={this.handleChange}

                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  data-test-id="btnTogglePassword"
                                  aria-label="toggle password visibility"
                                  onPress={this.handleClickShowNewPassword}
                                  style={{backgroundColor: "transparent"} }
                                  textStyle={{ color: '#94A3B8' }}
                                  icon={this.state.enableNewPasswordField ? (
                                    <VisibilityOffOutlinedIcon style={{ color: "#94A3B8" }} />
                                  ) : (
                                    <RemoveRedEyeOutlinedIcon style={{ color: "#94A3B8" }} />
                                  )}
                                />
                              </InputAdornment>
                            ),
                          }}
                          />
                          {this.state.newPasserrorMessage && (
                            <div style={{
                              display:'flex',
                              color: 'red',
                            marginTop:'4px',
                            fontSize: '12px',
                            textAlign: 'center',
                            fontFamily:'Arial, Helvetica',
                            }}>
                              {this.state.newPasserrorMessage}
                            </div>
                          )}

                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                          <label style={{
                            lineHeight: 2,
                            fontSize: 14,
                            fontWeight: 700,
                            fontFamily: 'Inter'
                          }} >Confirm New Password</label>
                          <TextField
                            data-test-id="txtInputEmail"
                            name="confirmPassword"
                            style={newStyles.inputContainer}
                            type={this.state.enableConfirmPasswordField ? 'password' : 'text'}
                            placeholder={"Your new password"}
                            value={this.state.confirmPassword}
                          // error={this.state.emailError ? this.state.errorMessage : ""}
                            onChange={this.handleChange}

                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Button
                                    data-test-id="btnTogglePassword"
                                    aria-label="toggle password visibility"
                                    onPress={this.handleClickShowConfirmPassword}
                                    style={{backgroundColor: "transparent"} }
                                    textStyle={{ color: '#94A3B8' }}
                                    icon={this.state.enableConfirmPasswordField ? (
                                      <VisibilityOffOutlinedIcon style={{ color: "#94A3B8" }} />
                                    ) : (
                                      <RemoveRedEyeOutlinedIcon style={{ color: "#94A3B8" }} />
                                    )}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                          {this.state.confirmPasserrorMessager && (
                            <div style={{color: 'red',
                            marginTop:'4px',
                            marginLeft:'-100px',
                            fontSize: '12px',
                            textAlign: 'center',
                            fontFamily:'Arial, Helvetica',
                            width:'100%'}}>
                              {this.state.confirmPasserrorMessager}
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop:'280px',
                    gap: 2,
                  }}
                >
                  <button style={{ ...styles.modalButton, ...styles.declineButton }} >CANCEL</button>
                  <div style={{ height: '44px' }}>
                    <CustomButton
                      text="Change Password"
                      testId="termAndcon"
                      onClick={this.ChangPass}
                    />
                  </div>
                </Box>



            
            </Box>
            </Box>
            </Box>
            </Box>
            {this.state.showToast && (
        <div style={{position: 'fixed',...styles.backdrop}}>
          <div ref={this.state.toastRef} style={{ position: 'relative',...styles.toast}}>
            <div style={styles.icon}>
              <span role="img" aria-label="check">
                <img src={tickIcon} alt="" />
              </span>
            </div>
            <div style={styles.message}>Password Changed Successfully</div>
            <button style={{ position: 'relative',...styles.closeButton}} onClick={this.cloasToast}>
              <img src={crossIcon} alt="" />
            </button>
          </div>
        </div>
      )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    overflowX: 'hidden'
  },
  content: {
    display: "flex",
    flex: 1,
  },
  mainContent: {
    flex: 1,
    padding: "2rem",
    backgroundColor: '#E2E8F0',
  },
  cardContainer: {
    backgroundColor: '#FFFFFF',
    border: '2px solid #215089',
    borderRadius: '16px',
    padding: '2rem',
    height:'774px'
  },
  headerFont: {
    fontFamily: "Inter",
    fontSize: '30px',
    fontWeight: 700,
    color: "#215089",
    marginBottom:'30px'
  },
}
const styles = {
  modalButton: {
    padding: '10px 20px',
    marginRight: '10px',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontFamily: 'inter',
    fontSize: '16px',
    fontWeight: 700,
    color: '#B57730',
    width: '117px'
  },
  declineButton: {
    backgroundColor: '#FDF1E2',
  },
  errorMessage: {
    color: 'red',
    // marginTop: '10px',
    fontSize: '12px',
    textAlign: 'center',
    fontFamily:'Arial, Helvetica'
  },

  backdrop: {
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    zIndex: 999,
  },
  toast: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '16px 24px',
    display: 'flex',
    alignItems: 'center',
    marginBottom:'70px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  icon: {
    marginRight: '16px',
    fontSize: '24px',
  },
  message: {
    fontSize: '20px',
    color: '#0F172A',
    fontWeight: 400,
    fontFamily:'Inter'
  },
  closeButtonHover: {
    color: '#333',
  },
  closeButton: {
    background: 'none',
    color: '#999',
    border: 'none',
    left:'12px',
    cursor: 'pointer',
  },
 
}


// Customizable Area End
