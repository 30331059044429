import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
interface ApiRes {
  message:string,
  error:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  oldPassword:string
  newPassword:string
  confirmPassword:string
  enableOldPasswordField:boolean,
  enableNewPasswordField:boolean,
  enableConfirmPasswordField:boolean,
  [key: string]: any;
  newPasserrorMessage:string,
  confirmPasserrorMessager:string,
  oldPasserrormessage:string
  showToast:boolean
  toastRef :any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  upadatePasswordId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      oldPassword:'',
      newPassword:'',
      confirmPassword:'',
      enableOldPasswordField:true,
      enableNewPasswordField:true,
      enableConfirmPasswordField:true,
      newPasserrorMessage:'',
      confirmPasserrorMessager:'',
      oldPasserrormessage:'',
      showToast:false,
      toastRef : React.createRef()
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.upadatePasswordId){
      this.handlePassChangRes(responseJson)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const {name,value}= event.target;
    this.setState({ [name]: value });
  };
  handleClickShowPassword = () => {
    this.setState({
      enableOldPasswordField: !this.state.enableOldPasswordField,
    });
  };
  handleClickShowNewPassword = () => {
    this.setState({
      enableNewPasswordField: !this.state.enableNewPasswordField,
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });
  };
 

  updatePass = () => {
    const data = {
          old_password: this.state.oldPassword,
          new_password: this.state.newPassword,
          confirm_new_password: this.state.confirmPassword
        };
    
        const httpBody = {
          data: data,
        };
    
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("LoginToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_settings/settings/update_password'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.upadatePasswordId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handlePassChangRes=(response:ApiRes)=>{
    if(response.error){
      if(response.error ===  "New password and confirm password do not match"){
        this.setState({oldPasserrormessage:''})
      }
      else{
        this.setState({oldPasserrormessage:response.error})
      }
    }
    if(response.message){
      this.setState({oldPasserrormessage:''})
      this.setState({showToast:true})
    }
  }


  validatePassword = () => {
    const { newPassword } = this.state;
    let error = '';

    if (newPassword.length < 8) {
      error = 'Password must be at least 8 characters long.';
    } else if (!/[A-Z]/.test(newPassword)) {
      error = 'Password must contain at least one capital letter.';
    } else if (!/[a-z]/.test(newPassword)) {
      error = 'Password must contain at least one lowercase letter.';
    } else if (!/\d/.test(newPassword)) {
      error = 'Password must contain at least one number.';
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
      error = 'Password must contain at least one special character.';
    }
    this.setState({ newPasserrorMessage: error });
  };

  validateConfirmPassword = () => {
    const { newPassword, confirmPassword } = this.state;
    let error = '';

    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      error = 'Both passwords should match';
    }

    this.setState({ confirmPasserrorMessager: error });
  };

  ChangPass = ()=>{
    this.validatePassword()
    this.validateConfirmPassword()
    if(this.state.confirmPasserrorMessager==='' && this.state.newPassword!=''){
      this.updatePass()
    }
  }
  cloasToast =  ()=>{
    this.setState({showToast:false})
}
handleClickOutside = () => {
    this.setState({showToast:false})
  
};
  // Customizable Area End
}
