import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from 'react';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CommuteIcon from '@mui/icons-material/Commute';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  LoggedInUserType: string | null;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  items = [
    {
      icon: <FormatListBulletedIcon sx={{ height: '60px', width: '60px', color: '#0F172A' }} />,
      title: 'Load Board',
      description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore',
      key: 'LoadBrokerSearch',
      path: 'LoadBrokerSearch'
    },
    {
      icon: <AddCircleOutlineIcon sx={{ height: '60px', width: '60px', color: '#0F172A' }} />,
      title: 'Create Load',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      key: 'postcreation',
      path: 'PostCreation'
    },
    {
      icon: <CommuteIcon sx={{ height: '60px', width: '60px', color: '#0F172A' }} />,
      title: 'Vehicles Tracking',
      description: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi',
      key: 'vehical tracking',
      path: 'Task'
    },
    {
      icon: <WidgetsIcon sx={{ height: '60px', width: '60px', color: '#0F172A' }} />,
      title: 'My Loads',
      description: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi',
      key: 'loadboard',
      path: 'TaskList'
    }
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      LoggedInUserType: null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    const LoggedInUserType = await getStorageData("LoggedInUserType");
    this.setState({LoggedInUserType})
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleNavigate = (path: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
