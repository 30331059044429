import React from "react";
// Customizable Area Start
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
  Box,
  Typography,
  Grid,
  Avatar,
  Menu, MenuItem
} from "@mui/material";
import {
  createTheme,
  Theme,
} from "@mui/material/styles";
import "@mui/styles";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
const DatePicker = require("react-datepicker");
import { AuthPageTitle } from "../../../components/src/AuthPageTitle.web";
import "react-datepicker/dist/react-datepicker.css";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import HeaderNavigation from "../../navigationmenu/src/HeaderNavigation.web";
import CustomButton from "../../../components/src/CustomButton.web.";
import { tickIcon ,crossIcon} from "./assets";
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const newStyles = {
  inputContainer: {
    paddingHorizontal: '8px',
    paddingVertical: '10px',
    height: '56px',
    border: '1px solid #CBD5E1',
    borderRadius: 12,
    borderStyle: 'solid',
    width: '327px',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: '400'
  },
  inputText: {},
}
function getStyles(name: string, personName:  string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  IField,
} from "./CustomisableUserProfilesController";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    ) {
      return "number";
    }
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    ) {
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.value,
                field.field_type
              )
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true,
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );
    }

    if (field.field_type === "date" || field.field_type === "datetime") {
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(
                  this.state.profile[field.name] as string
                ).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date: Date) =>
                this.changeFormValue(field.name, date, field.field_type)
              }
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );
    }

    if (field.field_type === "checkbox") {
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.checked,
                field.field_type
              )
            }
            color="primary"
          />
          {field.title}
        </div>
      );
    }
    if (field.field_type === "boolean") {
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(
                  field.name,
                  event.target.value,
                  field.field_type
                );
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel
                value={"true"}
                control={<Radio />}
                label="True"
              />
              <FormControlLabel
                value={"false"}
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
          </FormControl>
        </div>
      );
    }
  };

  render() {
    // Customizable Area Start
    return (
      <>
        <Box sx={webStyles.container}>
          <HeaderNavigation navigation={this.props.navigation} id={""} />
          <Box sx={webStyles.content}>
            <NavigationMenu navigation={this.props.navigation} id={""} />
            <Box sx={webStyles.mainContent}>
              <Box sx={webStyles.cardContainer}>
                <Typography style={webStyles.headerFont}>Personal Details</Typography>

                <Box sx={{ flexGrow: 1, p: 3 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6} >
                      <Grid container spacing={4} sx={{ width: '800px' }} >

                        <Grid item xs={12} sm={6} sx={{ mb: 2 }} >

                          <AuthPageTitle>First name</AuthPageTitle>
                          <TextField
                            data-test-id="txtInputFirstName"
                            name="first_name"
                            style={newStyles.inputContainer}
                            placeholder={"First Name"}
                            value={this.state.first_name}
                            onChange={this.handleChange}
                          />

                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ mb: 2 }} >
                          <AuthPageTitle>Last name</AuthPageTitle>
                          <TextField
                            data-test-id="txtInputLastName"
                            name="lastName"
                            style={newStyles.inputContainer}
                            placeholder={"Last Name"}
                            value={this.state.lastName}
                          onChange={this.handleChange}
                          />

                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                          <AuthPageTitle>Email Address</AuthPageTitle>
                          <TextField
                            data-test-id="txtInputEmail"
                            name="email"
                            style={newStyles.inputContainer}
                            placeholder={"Email"}
                            value={this.state.email}
                            onChange={this.handleChange}
                          />

                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ mb: 2 }} >
                          <AuthPageTitle>Phone number</AuthPageTitle>
                          <TextField
                            data-test-id="txtInputPhoneNumber"

                            style={newStyles.inputContainer}
                            placeholder={"Phone Number"}
                            name="phoneNumber"
                            value={this.state.phoneNumber}
                            onChange={this.handleChange}
                          />
                        </Grid>

                        {this.state.trucker &&
                        <>
                         <Grid item xs={12} sm={6} sx={{ mb: 2 }} >
                          <AuthPageTitle>Truck Type</AuthPageTitle>
                          <FormControl sx={{ width: 327,  }}>
                            <Select
                            data-test-id = "truckerType"
                              multiple
                              displayEmpty
                              name = "trucker"
                              value={this.state.truckType}
                              onChange={this.handleChange2}
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <p>Select</p>;
                                }

                                return selected.join(', ');
                              }}
                              MenuProps={MenuProps}
                              style={{...newStyles.inputContainer,height:'60px',borderRadius:'8px'}}
                              inputProps={{ 'aria-label': 'Without label' }}
                              IconComponent={KeyboardArrowDownIcon} 
                              sx={{
                                '& .MuiSvgIcon-root': {
                                  color: 'black'
                                },
                              }}
                    
                            >
                              <MenuItem disabled value="">
                                <p>Select</p>
                              </MenuItem>
                              {this.names.map((name:string) => (
                                <MenuItem
                                  key={name}
                                  value={name}
                                  style={getStyles(name, this.state.truckType, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ mb: 2 }} >
                          <AuthPageTitle>Insurance</AuthPageTitle>
                          <TextField
                            data-test-id="txtinsurance"

                            style={newStyles.inputContainer}
                            placeholder={"Insurance"}
                            name="insurance"
                            value={this.state.insurance}
                            onChange={this.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ mb: 2 }} >
                          <AuthPageTitle>Driving License Number</AuthPageTitle>
                          <TextField
                            data-test-id="txtlicenseNumber"

                            style={newStyles.inputContainer}
                            placeholder={"Driving License Number"}
                            name="licenseNumber"
                            value={this.state.licenseNumber}
                            onChange={this.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ mb: 2 }} >
                          <AuthPageTitle>MC Number</AuthPageTitle>
                          <TextField
                            data-test-id="txtmcNumber"

                            style={newStyles.inputContainer}
                            placeholder={"MC Number"}
                            name="mcNumber"
                            value={this.state.mcNumber}
                            onChange={this.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ mb: 2 }} >
                          <AuthPageTitle>Authority</AuthPageTitle>
                          <TextField
                            data-test-id="txtauthority"

                            style={newStyles.inputContainer}
                            placeholder={"No Issues"}
                            name="authority"
                            value={this.state.authority}
                            onChange={this.handleChange}
                          />
                        </Grid>
                        </>}

                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={2} sx={{mb:this.state.trucker?'400px':''}}>
                      <Box display="flex" flexDirection="column" alignItems="center" sx={{ ml: 'auto', mr: 0, width: '920px', mb: 10 }}>

                        {this.state.avatar ? (
                          <Avatar
                            alt="User Avatar"
                            src={this.state.avatar}
                            sx={{ width: 164, height: 164, mb: 2 }}
                          onClick={this.handleOpenMenu}
                          />
                        ) : (
                          this.EditProfileCircle()
                        )}
                        <Menu
                          anchorEl={this.state.anchorEl}
                          open={Boolean(this.state.anchorEl)}
                          onClose={this.handleCloseMenu}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          >
                          
                            <MenuItem onClick={this.handleUploadPicture} style={{background:'#FDF1E2',color:'#B57730',fontFamily:'Inter',fontWeight:700,fontSize:'16px',width:'238px',height:'57px',marginTop:'-8px'}}>
                            <input
                              style={{display:'none',}}
                              accept="image/*"
                              id="upload-photo"
                              type="file"
                              ref={this.state.fileInputRef}
                              onChange={this.handleImageChange}
                            />
                            <FileUploadOutlinedIcon sx={{mr:2}}/>
                              Upload picture
                            
                            </MenuItem>
                          <MenuItem onClick={this.handleopenNewmodal} style ={{background:'#BAC9DA',color:'#64748B',fontFamily:'Inter',fontWeight:700,fontSize:'16px',width:'238px',height:'57px',marginBottom:'-8px'}}>
                            <DeleteOutlineIcon sx={{mr:2}}/>
                            Remove Picture
                          </MenuItem>
                        </Menu>

                      </Box>
                    </Grid>


                  </Grid>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    height: '40vh',
                    gap: 2,
                  }}
                >
                  <button style={{ ...styles.modalButton, ...styles.declineButton }} >CANCEL</button>
                  <div style={{ height: '44px' }}>
                    <CustomButton
                      text="SAVE CHANGES"
                      testId="termAndcon"
                      onClick={this.saveAndNext}
                    />
                  </div>
                </Box>



              </Box>
            </Box>
          </Box>
        </Box>
       {this.state.showModal && <div style={styles.modalBackdrop}>
        <div style={styles.modalContent}>
          <button style={styles.modalClose} onClick={this.handleCloseModal}>&times;</button>
          <p style={{fontSize:'24px',fontWeight:700,fontFamily:"Inter"}}>
          Are you sure you want to remove the photo ?
          </p>
          <div style={{ border:'1px solid #E2E8F0 ', borderWidth:'0px 0px 1px 0px', width:'105.5%',position:'relative',right:'15px'}}>
          </div>
          <div style={styles.modalActions}>
            <button style={{ ...styles.modalButton, ...styles.declineButton }} onClick={this.handleCloseModal }>Cancel</button>
            <CustomButton
                  text="Remove"
                  testId="termAndcon"
                  onClick={this.handleRemovePicture}
                  customeWidth={true}
                />
          </div>
        </div>
      </div>}
      {this.state.showToast && (
        <div style={{position: 'fixed',...styles.backdrop}}>
          <div  ref={this.state.toastRef} style={{ position: 'relative',...styles.toast}}>
            <div style={styles.icon}>
              <span role="img" aria-label="check">
                <img src={tickIcon} alt="" />
              </span>
            </div>
            <div style={styles.message}>Profile Update Successfully</div>
            <button style={{ position: 'relative',...styles.closeButton}} onClick={this.cloasToast}>
              <img src={crossIcon} alt="" />
            </button>
          </div>
        </div>
      )}

      </>
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    display: "flex",
    overflowX: 'hidden',
    flexDirection: "column",
  },
  content: {
    flex: 1,
    display: "flex",
  },
  mainContent: {
    flex: 1,
    backgroundColor: '#E2E8F0',
    padding: "2rem",
  },
  cardContainer: {
    backgroundColor: '#FFFFFF',
    padding: '2rem',
    border: '2px solid #215089',
    borderRadius: '16px',
  },
  headerFont: {
    fontFamily: "Inter",
    fontSize: '30px',
    fontWeight: 700,
    color: "#215089"
  },
}
const styles = {
  modalButton: {
    padding: '10px 20px',
    marginRight: '10px',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontFamily: 'inter',
    fontSize: '16px',
    fontWeight: 700,
    color: '#B57730',
    width: '117px'
  },
  declineButton: {
    backgroundColor: '#FDF1E2',
  },

  modalBackdrop: {
    position: 'fixed' as 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px 8px 32px 8px',
    width: '39%',
    position: 'relative' as 'relative',
    fontFamily:'inter',
    fontSize:'18px',
    fontWeight:400,
    color:'#0F172A',
  },
  modalClose: {
    position: 'absolute' as 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
  },

  backdrop: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    zIndex: 999,
  },
  toast: {
    backgroundColor: '#fff',
    padding: '16px 24px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    marginBottom:'70px'
  },
  icon: {
    fontSize: '24px',
    marginRight: '16px',
  },
  message: {
    fontSize: '20px',
    fontWeight: 400,
    color: '#0F172A',
    fontFamily:'Inter'
  },
  closeButton: {
    background: 'none',
    border: 'none',
    color: '#999',
    cursor: 'pointer',
    left:'12px'
  },
  closeButtonHover: {
    color: '#333',
  },
}
// Customizable Area End
