import { Text } from 'react-native'
import { Typography } from '@builder/component-library'
import { styled } from '@mui/styles'

export const CommonText = styled(Typography)({
  fontFamily: 'Inter'
});

export const GeneralText = styled(Text)({
  fontFamily: 'Inter'
});

export const GeneralCenterText = styled(GeneralText)({
  textAlign: 'center',
  fontSize: '14px',
  wordWrap: 'break-word'
});

export const PageTitle = styled(CommonText)({
  fontWeight: 700,
  fontSize:'20px',
  fontFamily: 'Arial, Helvetica'
});

export const InputLabel = styled(CommonText)({
  fontSize: '14px',
  fontWeight: 'bold',
});

export const CheckboxLabel = styled(CommonText)({
  fontSize: '14px',
  fontWeight: 'normal',
});

export const CustomLink = styled(Text)({
  color: '#215089',
  fontSize: '16px',
  fontWeight: 'bold',
  textDecoration: 'none',
});