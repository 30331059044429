import React from "react";

const customStyles = {
  container: { 
    width: '100%', 
    display: 'inline-flex', 
    justifyContent: 'flex-start', 
    alignItems: 'center',
    gap: '4px',
  },
  successSymbol: {
    width: '12px', 
    height: '12px',
    backgroundColor: '#34D399',
    borderRadius: '50%'
  },
  errorSymbol: {
    position: 'relative',
    width: '12px', 
    height: '12px',
    backgroundColor: '#DC2626',
    borderRadius: '50%'
  },
  errorIcon: {
    position: 'absolute',
    top: '50%',
    left: '25%',
    width: '5px', 
    height: '1.40px',
    backgroundColor: '#FFF',
    borderRadius: '10px'
  },
  labelText: { 
    color: '#0F172A',
    fontFamily: 'Montserrat',
    fontSize: '14px',
  }
}

interface IPasswordChecklistProps {
  password: string;
}

export default function PasswordChecklist({ password }: IPasswordChecklistProps) {
  const requirements = [
    {
      label:"Password must be at least 8 characters long.",
      condition: /^.{8,}$/.test(password)
    },
    {
      label: "At least one capital letter",
      condition: /^(?=.*[A-Z])/.test(password)
    },
    {
      label: 'At least one lowercase letter',
      condition: /^(?=.*[a-z])/.test(password)
    },
    {
      label: 'At least one number',
      condition: /^(?=.*[0-9])/.test(password)
    },
    {
      label: 'At least one special character',
      condition: /^(?=.*[!@#\$%\^&\*])/.test(password)
    },
  ];

  return (
    <div style={{ 
      marginTop: '4px',
      marginBottom: '28px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
      gap: '8px' 
    }}>
      {requirements.map((requirement, index) => (
        <div key={index} style={customStyles.container}>
          {requirement.condition ? <div style={customStyles.successSymbol} /> :
            <div style={customStyles.errorSymbol as React.CSSProperties}>
              <div style={customStyles.errorIcon as React.CSSProperties} />
            </div>
          }
          <div style={customStyles.labelText}>{requirement.label}</div>
        </div>
      ))}
    </div>
  );
}