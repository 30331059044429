import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
interface ApiRes {
  message:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isOpen: boolean;
  isOpenLogout: boolean;
  logoutMessage: string
  selectedItem: string;
  activeSection: string;
  deleteAccount: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HeaderNavigationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  logoutAPICallId: string = "";

  deleteAccountID: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isOpen: false,
      selectedItem: '',
      activeSection: '',
      isOpenLogout: false,
      logoutMessage: '',
      deleteAccount: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webApiRequestCallId === this.logoutAPICallId) {
        if (webResponseJson.message) {
          this.handleNavigateToLoginAccount();
          this.handleCloseLogoutModal();
          this.setState({ logoutMessage: webResponseJson?.message });
        }
      }
      if(webApiRequestCallId === this.deleteAccountID){
        this.handleDeleteRes(webResponseJson)
      }
      // Customizable Area End
    }
  }

  // Customizable Area Start

  toggleDropdown = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleOpenLogoutModal = () => {
    this.setState({ isOpenLogout: true })
  }

  handleCloseLogoutModal = () => {
    this.setState({ isOpenLogout: false })
  }

  handleNavigateToLoginAccount = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  handleMenuItemClick = (item: string) => {
    this.setState({ selectedItem: item }, () => { this.getSidebarPosition() });
    if (item === 'Personal Details') {

      const message = new Message(
        getName(MessageEnum.NavigationMessage));

      message.addData(getName(
        MessageEnum.NavigationTargetMessage), 'CustomisableUserProfiles');
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
    else if (item === 'Change Password') {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage),
        'Settings2');
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
    else if (item === 'Notifications') {
      const message = new Message(getName(
        MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailNotification');
      this.send(message);
    }
    else if (item === 'Delete Account') {
      this.setState({ deleteAccount: true })
    }
    else if(item === 'Terms and Conditions'){
      const message = new Message(getName(
        MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), 'TermsAndCon');
      this.send(message);
    }
    else if(item === 'Logout'){
      this.handleOpenLogoutModal()
    }
  };
  getSidebarPosition = () => {
    const { selectedItem } = this.state;
    switch (selectedItem) {
      case 'Personal Details':
        return { top: '78px' };
      case 'Change Password':
        return { top: '112px' };
      case 'Notifications':
        return { top: '145px' };
      case 'Delete Account':
        return { top: '172px' };
      default:
        return { top: '78px' };
    }
  };
  handleSectionClick = (section: string) => {
    this.setState({ activeSection: section });
  };
  handleDeleteClose = ()=>{
    this.setState({deleteAccount:false})
  }
  // Logout API
  logoutAPI = async () => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: await getStorageData("LoginToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.logoutAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logoutAPIEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIEndpoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleDeleteAccount = () => {
    const header = {
      token: localStorage.getItem("LoginToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${localStorage.getItem("LoginId")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    this.deleteAccountID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleDeleteRes= (response:ApiRes)=>{
    if(response.message){
      localStorage.setItem("LoginToken",'')
      localStorage.setItem("LoginId",'')
      const message = new Message(getName(
        MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock');
      this.send(message);
    }
  }
  // Customizable Area End
}
