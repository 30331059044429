import React, { useState } from "react";

import { Button } from "@mui/material";
import { Box } from '@mui/system';

const customStyles = {
  wrapper: { 
    borderRadius: '8px', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center' ,
    height:'100%'
  },
}

interface ICustomButtonProps {
  text: string;
  testId: string;
  onClick: () => void;
  customeWidth?:boolean
}

export default function CustomButton({ text, testId, onClick,customeWidth }: ICustomButtonProps) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box 
      component="div" 
      style={{ ...customStyles.wrapper, background: isHovered ? '#915F26' : 'linear-gradient(99deg, #915F26 0%, #F19F40 100%)' }} 
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Button
        data-test-id={testId}
        style={{...styles.removeBackground, ...styles.innerLayout,width:customeWidth? '117px':'100%'}}
        onClick={onClick}
      >
        {text}
      </Button>
    </Box>
  );
}

const styles = { 
  removeBackground: {
    backgroundColor: 'transparent',
  },
  innerLayout: {
    paddingHorizontal: 16, 
    paddingVertical: 10, 
    justifyContent: 'center', 
    alignItems: 'center' ,
    marginTop:'5px',
    color:'white',
    fontWeight:700,
    fontSize:'16px',
    height:'100%'
  },
}