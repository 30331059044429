import React from "react";
import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  TextField,
  Modal,
  Grid,
  Dialog,
  IconButton,
  TextareaAutosize,
  Pagination,
  Tabs,
  Tab,
  Divider,
  Stack,
  Card, CardContent,
  // Customizable Area End
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

// Customizable Area Start
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import HeaderNavigation from "../../navigationmenu/src/HeaderNavigation.web";
import PhoneIcon from '@mui/icons-material/Phone';
const configJSON = require("./config");
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { trcukdetailsIcon, truckIcon,button1,button2,sendIcon ,person, tickIcon ,crossIcon} from "./assets";
import { ChatContainer, 
  MessageList, 
  MessageInput, 
  ConversationHeader, 
  Avatar, 
  Message, 
 } from '@chatscope/chat-ui-kit-react';

const messageLists = [
  {
    direction: true,
    message: 'It is a long established fact that a reader.',
    sentTime: '15 mins ago'
  },
  {
    direction: false,
    message: 'Lorem Ipsum is simply dummy text of the printing..',
    sentTime: '15 mins ago'
  },
  {
    direction: true,
    message: 'It is a long established fact that a reader.',
    sentTime: '15 mins ago'
  },
  {
    direction: false,
    message: 'Lorem Ipsum is simply dummy text of the printing..',
    sentTime: '15 mins ago'
  },
  {
    direction: true,
    message: 'It is a long established fact that a reader.',
    sentTime: '15 mins ago'
  },
  
]

const CustomMessageInput = () => { 
  return (
    <>
      <MessageInput
        placeholder="Type a message"
        sendButton={false} 
        />
      <button  className="custom-send-button">
        <img
          src={sendIcon}
          alt="Send"
          style={{ width: '24px', height: '24px' }} 
          />
      </button>
          </>
    
  );
};

const MainContainerBox:any = styled(Box)(({ theme })=> ({
  display: "flex",
  flexDirection: "column",
  height: "1124px",
  "*":{
    fontFamily:"inter"
  },
  ".cs-status--available .cs-status__bullet":{
     background:"#80EA4E"
  },
  ".cs-conversation__name":{
    fontSize:18,
    color:"#1E293B",
    fontWeight:700,
    marginBottom:10,
  },
  ".cs-conversation__info-content":{
    color:"#78716C",
    fontSize:16,
    lineHeight:2
  },
  
  ".cs-main-container > .cs-sidebar.cs-sidebar--left":{
    maxWidth:"547px",
    width:"40%",
    border:0
  },
  ".cs-conversation__last-activity-time":{
    alignItems:"flex-start"
  },
  ".cs-conversation__content":{
    flexGrow:0,
    width:"50%"
  },
  ".cs-conversation-header":{
    background:"#215089",
    borderRadius:25,
    padding:"30px"
  },
  ".cs-conversation-header__actions":{
    color:"#ffffff",
    ".css-i4bv87-MuiSvgIcon-root":{
      fontSize:"2.5rem"
    }
  },
  ".cs-conversation-header__content":{
    justifyContent:"space-around"
  },
  ".cs-conversation-header__avatar, .cs-avatar.cs-avatar--md":{
    height:78,
    width:78
  },
  ".cs-conversation-header__user-name":{
    background:"#215089",
    color:"#FFFFFF",
    fontSize:24,
    fontWeight:700
  },
  ".cs-conversation-header__info":{
    background:"#215089",
    color:"#FFFFFF",
    fontSize:16,
    fontWeight:400
  },
  ".cs-main-container":{
    border:0,
    height:"auto!important"
  },
  ".cs-chat-container":{
    border:"1px solid #DEE5ED",
    borderRadius:24,
    height:"auto",
    marginLeft:25,
    overflowY:'auto'
    
  },
  ".cs-message--incoming .cs-message__content": {
    backgroundColor: "#E9EEF3",
    padding:"30px 20px",
    fontSize:18,
    marginTop:20
  },
  ".cs-message--outgoing .cs-message__content":{
    backgroundColor: "#BAC9DA",
    padding:"30px 20px",
    fontSize:18,
    marginTop:20
  },
  ".cs-message-input":{
    borderTop:0,
    boxShadow:"4px 4px 0px 0 #00000033",
    borderRadius:36,
    margin:20,
    marginBottom:30 ,
  },
  ".cs-message-input__content-editor-wrapper,.cs-message-input__content-editor":{
    backgroundColor:"#fff"
  },
  ".cs-button--attachment, .cs-button--send": {
    color: "#A8A29E",
    alignSelf:"center"
  },
  ".cs-search":{
    backgroundColor:"#fff",
    borderRadius:4,
    border:"1px solid #CBD5E1",
    maxWidth:344,
  },
  ".cs-search__input":{
    backgroundColor:"#fff",
    "& ::placeholder":{
      color:"#94A3B8"
    }
  },
  ".cs-search__search-icon":{
    color:"#94A3B8"
  },

  ".custom-send-button": {
    background: "none",
    border: "none",
    position: "relative",
    left: "220px",
    bottom:'60px',
    cursor: "pointer",
  },
  ".cs-back-button": {
    fontSize: "1rem", 
  },
})) ;

// Customizable Area End

import MultilevelApprovalController, {
  Props,
  EventList,
} from "./MultilevelApprovalController.web";

export default class MultilevelApproval extends MultilevelApprovalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  chatScreen = ()=>{
    return(
      <MainContainerBox data-data-id="chat-main-container">
       <ChatContainer>
                    <ConversationHeader>
                      <ConversationHeader.Back onClick={this.backButn}  className="cs-back-button"  />
                      <Avatar
                        name="Zoe"
                        src={person}
                      />
                      <ConversationHeader.Content
                        info="Active Now"
                        userName="Hugh Natasha"
                      />
                      <ConversationHeader.Actions>
                      </ConversationHeader.Actions>
                    </ConversationHeader>
                    <MessageList>
                      {messageLists.map((mList,index) => (
                        <Message
                          key={index}
                          model={{
                            direction: mList.direction? "incoming":"outgoing",
                            message: mList.message,
                            position: 'single',
                            sender: 'Zoe',
                            sentTime: '15 mins ago'
                          }}
                        >
                        </Message>
                      ))}
                    </MessageList>
                    
                  </ChatContainer>
                    <CustomMessageInput />
                  </MainContainerBox>
    )
  }
  modalForUpdateStatus = ()=>{
    const selectedCard :any = this.state.cards.find(card => card.id === this.state.selectedCardId)
    const check = selectedCard?.attributes?.status
    const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      if (value !== this.state.selectedStatus) {
        this.setState({ selectedStatus: value });
      }
    };
    
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
      '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
      },
    }));
    const CustomNativeSelect = styled(NativeSelect)({
      '&:before': {
        borderBottom: 'none', 
      },
      '&:after': {
        borderBottom: 'none', 
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none', 
      }
    });
    
    return(
      <>
       <BootstrapDialog
        onClose={this.handleClose}
        aria-labelledby="customized-dialog-title"
        open={this.state.openModal}
        // ref={this.state.toastRef}
        PaperProps={{
          sx: {
            borderRadius:'0px 0px 32px 8px' 
          },
          
        }}
      >
        
        <DialogTitle  sx={{fontSize:'24px',fontWeight:700,fontFamily:'Inter', m: 0, p: 2 }} id="customized-dialog-title">
        Update Status
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={this.handleClose}
          sx={{
            position: 'absolute',
            right: 10,
            top: 15,
            color: 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography sx={{fontSize:'20px',fontWeight:700,color:'#64748B',width:'520px',paddingLeft:'20px',paddingRight:'30px',paddingTop:'20px'}}>
            Status

              <Box sx={{ marginTop:'18px',marginBottom:'18px', minWidth: 520, border:'1px solid #CBD5E1',borderRadius:'8px',height:'57px' }}>
                <FormControl fullWidth>
                  <CustomNativeSelect
                    onChange={handleStatusChange}
                    value={this.state.selectedStatus || check} 
                    
                    inputProps={{
                      name: 'age',
                      id: 'uncontrolled-native',
                    }}
                    sx={{height:'57px',fontSize:'20px',border:'0px solid white',color:'#475569',paddingLeft:'20px'}}
                  >
                     <option value="in_transit">In Transit</option>
                    <option value="Weather Delay">Weather Delay</option>
                    <option value="road_closure">Road Closure</option>
                    <option value="break_down">Break Down</option>
                    <option value="delivered">Delivered</option>
                    <option value="pending">Pending</option>
                  </CustomNativeSelect>
                </FormControl>
              </Box>
          </Typography>
          
        </DialogContent>
        <DialogActions sx={{height:'90px',marginRight:'15px'}}>
        <Button variant="contained" sx={webStyles.cancelButton} data-testId={"delete_api"} onClick={this.handleClose}>Cancel</Button>
                    <Button variant="contained" sx={webStyles.LogoutButton} data-test-id="updateBtn" onClick={this.updateStatus}>Update</Button>
        </DialogActions>
      </BootstrapDialog>
      </>
    )
  }

  renderTruckDetails = () => {
     const selectedCard :any = this.state.cards.find(card => card.id === this.state.selectedCardId)
  
    return (
        <Box sx={webStyles.truckDetailsBox}>
            <Box sx={webStyles.truckIconBox}>
                <Box sx={webStyles.trcukPathBox}>
                    <Box sx={webStyles.truckDetails}>
                        <Typography sx={webStyles.fontStyle_7}>From</Typography>
                        <Typography sx={webStyles.fontStyle_8}>{selectedCard?.attributes?.origin_address?.city}</Typography>
                    </Box>
                    <Box sx={webStyles.truckDetails}>
                        <Typography sx={webStyles.fontStyle_7}>To</Typography>
                        <Typography sx={webStyles.fontStyle_8}>{selectedCard?.attributes?.destination_address?.city}</Typography>
                    </Box>
                </Box>

                <Box sx={webStyles.trcukPathBox}>
                    <Box sx={webStyles.truckDetails}>
                        <Typography sx={webStyles.fontStyle_7}>{configJSON.loadWeight}</Typography>
                        <Typography sx={webStyles.fontStyle_8}>{selectedCard?.attributes?.load_weight}</Typography>
                    </Box>
                    <Box sx={webStyles.truckDetails}>
                        <Typography sx={webStyles.fontStyle_7}>{configJSON.amount}</Typography>
                        <Typography sx={webStyles.fontStyle_8}>${selectedCard?.attributes?.price}</Typography>
                    </Box>
                </Box>
                <Box display={'flex'} justifyContent={'flex-end'}>
                    <img src={trcukdetailsIcon} />
                </Box>
            </Box>
            <Box display={'flex'} flexDirection={'column'} gap={'1rem'} p={'30px'}>
                <Box display={'flex'} gap={'7.5rem'}>
                    <Typography sx={webStyles.fontStyle_9}>{configJSON.startDate}</Typography>
                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{selectedCard?.attributes?.start_date}</Typography>
                </Box>
                <Divider sx={webStyles.dividerStyle} />

                <Box display={'flex'} gap={'8rem'}>
                    <Typography sx={webStyles.fontStyle_9}>{configJSON.endDate}</Typography>
                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{selectedCard?.attributes?.end_date}</Typography>
                </Box>
                <Divider sx={webStyles.dividerStyle} />

                <Box display={'flex'} gap={'8rem'}>
                    <Typography sx={webStyles.fontStyle_9}>{configJSON.comodity}</Typography>
                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{'Goods'}</Typography>
                </Box>
                <Divider sx={webStyles.dividerStyle} />

                <Box display={'flex'} gap={'8rem'}>
                    <Typography sx={webStyles.fontStyle_9}>{configJSON.totalMiles}</Typography>
                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{selectedCard?.attributes?.total_miles}</Typography>
                </Box>
                <Divider sx={webStyles.dividerStyle} />

                <Box display={'flex'} gap={'8rem'}>
                    <Typography sx={{ ...webStyles.fontStyle_9 }}>
                        {configJSON.originAddress.split(' ')[0]}
                        <br />
                        {configJSON.originAddress.split(' ')[1]}
                    </Typography>
                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>
                    {selectedCard?.attributes?.origin_address?.address}, 
                        {selectedCard?.attributes?.origin_address?.city}, {selectedCard?.attributes?.origin_address?.state} <br />
                        {selectedCard?.attributes?.origin_address?.country} - {selectedCard?.attributes?.origin_address?.zip_code}
                    </Typography>
                </Box>
                <Divider sx={webStyles.dividerStyle} />

                <Box display={'flex'} gap={'6.5rem'}>
                    <Typography sx={{ ...webStyles.fontStyle_9 }}>
                        {configJSON.destinationAddress.split(' ')[0]}
                        <br />
                        {configJSON.destinationAddress.split(' ')[1]}
                    </Typography>
                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>
                    {selectedCard?.attributes?.destination_address?.address}, 
                        {selectedCard?.attributes?.destination_address?.city}, {selectedCard?.attributes?.destination_address?.state} <br />
                        {selectedCard?.attributes?.destination_address?.country} - {selectedCard?.attributes?.destination_address?.zip_code}
                    </Typography>
                </Box>
                <Divider sx={webStyles.dividerStyle} />

                <Box display={'flex'} gap={'8rem'}>
                    <Typography sx={webStyles.fontStyle_9}>{configJSON.loadDetails}</Typography>
                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{configJSON.loarem}</Typography>
                </Box>
            </Box>
        {(this.state.tabValue === 1) &&
          <div style={{ padding: '16px' }}>
          <Card sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px', height: '108px' }}>
            <Box sx={{ backgroundColor: '#B57730', borderRadius: '10px 0px 0px 10px', width: '22px', height: '108px' }} />
            <CardContent sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <img width={'71px'} height={'54px'} src={truckIcon} />
              <Typography variant="h6" sx={{ fontFamily: 'Inter', fontSize: '24px', fontWeight: 700, color: '#1E293B', margin: 'auto' }}>
              {selectedCard?.attributes?.status}
              </Typography>
              <IconButton onClick={this.handleClickOpen } color="primary">
                <img src={button1} height={'66px'} width={'66px'} alt="" />
              </IconButton>
            </CardContent>
          </Card>

          <Card sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ backgroundColor: '#B57730', width: '22px', height: '203px', borderRadius: '10px 0px 0px 10px' }} />
            <CardContent sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="body1" sx={webStyles.addresText}>822 E. 20th Street,</Typography>
                <Typography sx={webStyles.addresText} variant="body1">Los Angeles,</Typography>
                <Typography sx={webStyles.addresText} variant="body1">California</Typography>
                <Typography sx={webStyles.addresText} variant="body1">90011</Typography>
              </Box>
              <IconButton color="primary">
                <img src={button2} height={'66px'} width={'66px'} alt="" />
              </IconButton>
            </CardContent>
          </Card>
        </div>
        }
            
        </Box>
    )
}
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
       <Box sx={webStyles.container}>
        <HeaderNavigation navigation={this.props.navigation} id={""} />
        <Box sx={webStyles.content}>
          <NavigationMenu navigation={this.props.navigation} id={""} />
          <Box sx={webStyles.mainContent}>
            <Box sx={webStyles.tabContainer}>
              <Typography sx={webStyles.headerFont}>{configJSON.labelTemplateName}</Typography>
              <Box sx={webStyles.root}>
                <Tabs
                  data-testId={"tabChange"}
                  value={this.state.tabValue}
                  sx={webStyles.tabs}
                  onChange={this.handleChange}
                  TabIndicatorProps={{ style: { display: 'none' } }}
                >
                  <Tab label={configJSON.btnApplied} sx={webStyles.tab} />
                  <Tab label={configJSON.btnApprove} sx={webStyles.tab} />
                  <Tab label={configJSON.btnReject} sx={webStyles.tab} />
                </Tabs>
              </Box>

              <Box sx={webStyles.cardContainer}>
                <Box sx={webStyles.styledCardContainer}>
                  {this.state.cards.map((cardItem) => (
                    <Box
                      sx={{
                        ...webStyles.cardStyle,
                        border: this.isCardSelected(cardItem?.id) ? '1px solid #915F26' : '',
                      }}
                      key={cardItem?.id}
                      onClick={() => this.handleCardClick(cardItem?.id)}
                    >
                      <Box sx={webStyles.cardHeader}>
                        <Box sx={webStyles.avatarContainer}>
                          <Box sx={webStyles.nameContainer}>
                            <Typography
                              sx={{
                                ...webStyles.fontSyle_1
                              }}>
                              {cardItem?.id}
                            </Typography>              
                          </Box>
                        </Box>

                      </Box>
                    

                      <Box display={'flex'} flexDirection={'column'} sx={webStyles.boxPadding}>

                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Box display='flex' gap='10px' alignItems='center' >
                          <Typography sx={{
                            ...webStyles.fontSyle_4,
                
                          }}
                          >{cardItem?.attributes?.load_weight} - {cardItem?.attributes?.total_miles}</Typography>
                        </Box>

                        <Box display='flex' justifyContent='flex-end' alignItems='center'>
                          <Typography sx={{
                            ...webStyles.fontSyle_3
                          }}
                          >
                           <PhoneIcon sx={{position:'relative',top:'5px'}} /> {cardItem?.attributes?.load_broker_phone_number}
                          </Typography>
                        </Box>
                        </Box>

                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Stack direction="column" alignItems="flex-start"  >
                          <Stack direction="row" alignItems="center">
                            <Box
                              sx={{
                                width: 12,
                                height: 12,
                                backgroundColor: '#b0bec5',
                                borderRadius: '50%',
                                mt:'15px'
                              }}
                            />
                            <Typography sx={{ml:'8px',position:'relative',top:'10px'}}  variant="body1" color="textPrimary">
                              {cardItem?.attributes?.origin_address?.city}
                            </Typography>
                          </Stack>

                          <Box
                            sx={{
                              width: '1px',
                              height: '30px', 
                              backgroundColor: '#b0bec5', 
                              ml:'5px'
                            }}
                          />

                          <Stack direction="row" alignItems="center" >
                            <Box
                              sx={{
                                width: 12,
                                height: 12,
                                backgroundColor: '#0a1929', 
                                borderRadius: '50%',
                                mb:'15px'
                              }}
                            />
                            <Typography sx={{ml:'8px',position:'relative',bottom:'8px'}} variant="body1" color="textPrimary">
                              {cardItem?.attributes?.destination_address?.city}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Box >
                          <Box sx={webStyles.chatBoxStyle} >
                            <Typography sx={{fontSize:'36px',fontWeight:700}}>${cardItem?.attributes?.price}</Typography>
                          </Box>
                        </Box>
                      </Box>
                      </Box>

                      <Box sx={webStyles.statusBox}>
                        <Typography sx={webStyles.fontSyle_5}>{cardItem?.attributes?.status}</Typography>

                        <Box >
                          <Box onClick = {this.chatOpen} data-test-id = "chatNow" sx={webStyles.chatBoxStyle1} >
                            <ChatBubbleOutlineOutlinedIcon />
                            <Typography>Chat Now</Typography>
                          </Box>
                        </Box>
                      </Box>

                    </Box>
                  ))}


                </Box>
                 {!this.state.chatS? this.renderTruckDetails():
                  this.chatScreen()}
                </Box>


              </Box>
              </Box>
              </Box>
              </Box>
              {this.modalForUpdateStatus()}
              {this.state.showToast && (
        <div style={{position: 'fixed',...styles.backdrop}}>
          <div  ref={this.state.toastRef} style={{ position: 'relative',...styles.toast}}>
            <div style={styles.icon}>
              <span role="img" aria-label="check">
                <img src={tickIcon} alt="" />
              </span>
            </div>
            <div style={styles.message}>Status Updated Successfully</div>
            <button style={{ position: 'relative',...styles.closeButton}} onClick={this.closeToast}>
              <img src={crossIcon} alt="" />
            </button>
          </div>
        </div>
      )}

      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  backdrop: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    zIndex: 999,
  },
  toast: {
    backgroundColor: '#fff',
    padding: '16px 24px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    marginBottom:'70px'
  },
  icon: {
    fontSize: '24px',
    marginRight: '16px',
  },
  message: {
    fontSize: '20px',
    fontWeight: 400,
    color: '#0F172A',
    fontFamily:'Inter'
  },
  closeButton: {
    background: 'none',
    border: 'none',
    color: '#999',
    cursor: 'pointer',
    left:'12px'
  },
  closeButtonHover: {
    color: '#333',
  },
}
const webStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    overflowX: 'hidden'
  },
  content: {
    display: "flex",
    flex: 1,
  },
  mainContent: {
    flex: 1,
    padding: "2rem",
    backgroundColor: '#E2E8F0',
  },
  tabContainer: {
    backgroundColor: '#FFFFFF',
    border: '2px solid #215089',
    borderRadius: '16px',
    padding: '2rem',
  },
  infoBox: {
    width: '40%',
  },
  headerFont: {
    fontFamily: "Inter",
    fontSize: '30px',
    fontWeight: 700,
    color: "#215089"
  },
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
    display: 'flex',
    gap: '6px',
    marginTop: '5%',
  },
  tabs: {
    borderRadius: '50px',
    backgroundColor: '#BAC9DA',
    color: '#64748B',
    padding: '6px 12px 6px 12px',
  },
  tab: {
    width: '140px',
    padding: '6px 12px 6px 12px',
    borderRadius: '50px',
    fontFamily: "Inter",
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'initial',
    color: '#64748B',
    '&.Mui-selected': {
      backgroundColor: '#FBE1C4',
      color: '#B57730',
    },
  },
  
  cardContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  styledCardContainer: {
    width: '55%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  cardStyle: {
    marginTop: '4%',
    boxShadow: '0px 4px 4px 0px #00000040',
    border: '1px solid #FBE1C4',
    borderRadius: '16px',
    cursor: 'pointer'
  },
  
  
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 20px 10px 20px'
  },
  fontSyle_1: {
    fontFamily: "Inter",
    fontSize: '24px',
    fontWeight: 700,
  },
  fontSyle_2: {
    fontFamily: "Inter",
    fontSize: '14px',
    fontWeight: 400,
    color: "#FFFFFF"
  },
  avatarContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  locationContainer: {
    display: 'flex',
    gap: '2px',
    alignItems: 'center'
  },
  avatarStyle: {
    height: '40px',
    width: '40px'
  },
  dividerStyle: {
    borderStyle: 'dotted',
    borderWidth: '1px',
    borderColor: '#BAC9DA',
  },
  boxPadding: {
    padding: '10px 0px 10px 20px',
    
  },
  fontSyle_3: {
    fontFamily: "Inter",
    fontSize: '16px',
    fontWeight: 400,
    color: "white",
    backgroundColor:'#215089',
    width:'199px',
    borderRadius:'3px 0px 0px 3px',
    textAlign:'center'
  },
  fontSyle_4: {
    fontFamily: "Inter",
    fontSize: '18px',
    fontWeight: 400,
    color: "#0F172A"
  },
  fontSyle_5: {
    fontFamily: "Inter",
    fontSize: '18px',
    fontWeight: 400,
    color: "#FFFFFF"
  },
  statusBox: {
    backgroundColor: '#915F26',
    borderRadius: '0px 0px 16px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding:'10px 10px 10px 20px'
  },
  chatBoxStyle: {
    height: '40px',
    padding: '28px 15px 6px 15px ',
    color:'#475569',
  },
  chatBoxStyle1: {
    width: '130px',
    height: '40px',
    backgroundColor: '#FBE1C4',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    color:'#B57730'
  },
  truckDetailsBox: {
    width: '40%',
    marginTop: '4%',
    borderRadius: '16px',
    boxShadow: "0px 4px 4px 0px #00000040",
    height:'100%'
},
truckIconBox: {
    backgroundColor: '#E9EEF3',
    borderRadius: '16px',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem'
},
trcukPathBox: {
    display: 'flex',
    gap: '5rem',
    alignItems: 'center'
},
truckDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
},
fontStyle_7: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    color: "#0F172A"
},
fontStyle_8: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    color: "#1C1917",
    textAlign: 'Left'
},
fontStyle_9: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    color: "#475569"
},
  addresText: {
    fontSize: '18px', fontFamily: 'Inter', fontWeight: 700, marginTop: '10px', marginBottom: '10px'
  },
  cancelButton: {
    height:'56px',
    fontFamily: 'Inter',
    width:'120px',
    borderRadius:'8px',
    color: '#B57730',
    fontWeight: 700,
    fontSize: '16px',
    backgroundColor: '#FDF1E2',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#FDF1E2',
    }
},
LogoutButton: {
  height:'56px',
  width:'120px',
  borderRadius:'8px',
  fontSize: '16px',
  color: '#FFFFFF',
    fontFamily: 'Inter',
    cursor: 'pointer',
    fontWeight: 700,
    background: 'linear-gradient(99.09deg, #915F26 2.64%, #F19F40 100%)',
    '&:hover': {
        background: 'linear-gradient(99.09deg, #915F26 2.64%, #F19F40 100%)',
    }
},
}
// Customizable Area End
