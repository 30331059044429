import React from "react";
import { StyleSheet, TextStyle, View } from "react-native";

import { Input, InputProps } from "@builder/component-library";

import { AuthPageTitle } from "./AuthPageTitle.web";

interface ICustomTextInputProps extends InputProps {
  label?: string;
}

export default function CustomTextInput({ label, ...props }: ICustomTextInputProps) {
  return (
    <View style={{ width: '100%' }}>
      {label && <AuthPageTitle>{label} <span style={{color:'red'}}>*</span></AuthPageTitle>}
      <Input
        {...props}
        borderColor="#CBD5E1"
        borderErrorColor="#F87171"
        borderHighlightColor="#FBE1C4"
        placeholderTextColor="#94A3B8"
        style={{ flex: 1, outline: 'none' } as TextStyle}
        containerStyle={styles.container}
        textStyle={styles.inputText}
        errorStyle={{ marginLeft: '0',fontSize:12,fontFamily:'Inter' }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: '8px', 
    paddingVertical: '10px', 
    height: '56px', 
    borderRadius: 8, 
    borderStyle: 'solid', 
  },
  inputText : {
    fontSize: 16, 
    fontFamily: 'Inter', 
    fontWeight: '400',
  }
});