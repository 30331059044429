import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
interface ApiRes {
    email_notification_setting:boolean
}
interface ApiRes2{
    message: string,
    email_notification_setting: boolean
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  switch:boolean;
  successMessage:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailNotificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataId:string = "";
  updateStateId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      switch:true,
      successMessage:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.getDataId){
        this.handleGetDataRes(responseJson)
    }
    if(apiRequestCallId === this.updateStateId){
      this.handleState(responseJson)
    }
    
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getData()
  }
handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({switch:!this.state.switch})
    this.updateState(event.target.checked)
  };

  getData = () => {
    const header = {
      token: localStorage.getItem('LoginToken')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_settings/email_notification_setting'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleGetDataRes = (resposne:ApiRes)=>{
    this.setState({switch:resposne.email_notification_setting})
  }

  updateState = (response:boolean) => {
    
        const httpBody = {
            email_notification_setting:response
        };
    
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("LoginToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_settings/update_email_notification_setting'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.updateStateId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleState = (response:ApiRes2)=>{
    this.setState({successMessage:response.message})
  }
  // Customizable Area End
}
