import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Avatar,
  Divider,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from "@mui/material";

import {
  createTheme,
  styled
} from "@mui/material/styles";

import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import HeaderNavigation from "../../navigationmenu/src/HeaderNavigation.web";
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
// Customizable Area End

import TaskController, { Props, configJSON } from "./TaskController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Task extends TaskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const selectedCard = this.cards.find(card => card.id === this.state.selectedCardId);
    return (
      <Box sx={webStyles.container}>
        <HeaderNavigation navigation={this.props.navigation} id={""} />
        <Box sx={webStyles.content}>
          <NavigationMenu navigation={this.props.navigation} id={""} />
          <Box sx={webStyles.mainContent}>
            <Box sx={webStyles.tabContainer}>
              <Typography sx={webStyles.headerFont}>{configJSON.vehicaltracking}</Typography>
              <Box sx={webStyles.root}>
                <Tabs
                  value={this.state.tabValue}
                  onChange={this.handleChange}
                  data-testId={"tabChange"}
                  sx={webStyles.tabs}
                  TabIndicatorProps={{ style: { display: 'none' } }}
                >
                  <Tab label={configJSON.all} sx={webStyles.tab} />
                  <Tab label={configJSON.delivered} sx={webStyles.tab} />
                  <Tab label={configJSON.intransit} sx={webStyles.tab} />
                </Tabs>
              </Box>

              <Box sx={webStyles.cardContainer}>
                <Box sx={webStyles.styledCardContainer}>
                  {this.cards.map((cardItem) => (
                    <Box
                      sx={{
                        ...webStyles.cardStyle,
                        backgroundColor: this.isCardSelected(cardItem.id) ? '#215089' : '#FFFFFF',
                      }}
                      key={cardItem.id}
                      onClick={() => this.handleCardClick(cardItem.id)}
                    >
                      <Box sx={webStyles.cardHeader}>
                        <Box sx={webStyles.avatarContainer}>
                          <Avatar sx={webStyles.avatarStyle} alt="#avataricon" src={cardItem.avatarSrc} />

                          <Box sx={webStyles.nameContainer}>
                            <Typography
                              sx={{
                                ...webStyles.fontSyle_1,
                                color: this.isCardSelected(cardItem.id) ? "#FFFFFF" : '#1C1917'
                              }}>
                              {cardItem.driverName}
                            </Typography>
                            <Typography
                              sx={{
                                ...webStyles.fontSyle_2,
                                color: this.isCardSelected(cardItem.id) ? "#FFFFFF" : '#1C1917'

                              }}>
                              {cardItem.driverLocation}
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={webStyles.locationContainer}>
                          <Typography
                            sx={{
                              ...webStyles.fontSyle_2,
                              color: this.isCardSelected(cardItem.id) ? "#FFFFFF" : '#1C1917'
                            }}
                          >
                            {cardItem.from}
                          </Typography>
                          <CompareArrowsOutlinedIcon
                            sx={{
                              color: this.isCardSelected(cardItem.id) ? '#FFFFFF' : '#1C1917',
                              fontSize: '14px',
                            }}
                          />
                          <Typography
                            sx={{
                              ...webStyles.fontSyle_2,
                              color: this.isCardSelected(cardItem.id) ? "#FFFFFF" : '#1C1917'
                            }}
                          >
                            {cardItem.to}
                          </Typography>
                        </Box>

                      </Box>
                      <Divider sx={webStyles.dividerStyle} />

                      <Box display={'flex'} flexDirection={'column'} sx={webStyles.boxPadding}>
                        <Box display='flex' justifyContent='flex-end' alignItems='center'>
                          <Typography sx={{
                            ...webStyles.fontSyle_3,
                            color: this.isCardSelected(cardItem.id) ? "#FFFFFF" : '#1C1917'
                          }}
                          >
                            {cardItem.id}
                          </Typography>
                        </Box>

                        <Box display='flex' gap='10px' alignItems='center' >
                          <img alt="#truckicon" src={cardItem.truckIconSrc} />
                          <Typography sx={{
                            ...webStyles.fontSyle_4,
                            color: this.isCardSelected(cardItem.id) ? "#FFFFFF" : '#1C1917'
                          }}
                          >{cardItem.loadDetails}</Typography>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Box sx={webStyles.chatBoxStyle} >
                            <ChatBubbleOutlineOutlinedIcon />
                            <Typography>{cardItem.chatnow}</Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box sx={webStyles.statusBox}>
                        <Typography sx={webStyles.fontSyle_5}>{cardItem.chatnow}</Typography>
                      </Box>

                    </Box>
                  ))}
                </Box>

                <Box sx={webStyles.infoBox}>
                  {selectedCard && (
                    <Box sx={webStyles.driverInfoCard}>
                      <Box sx={webStyles.infoBoxPadding}>
                        <Box sx={webStyles.trackingBoxStyle}>
                          <Box sx={webStyles.boxColumn}>
                            <Box sx={webStyles.trackingDetails}>
                              <Typography sx={webStyles.fontStyle_5}>{configJSON.trackingId}</Typography>
                              <Typography sx={webStyles.fontStyle_6}>{selectedCard.truckInfo.trackingId}</Typography>
                            </Box>

                            <Box sx={webStyles.trackingDetails}>
                              <Typography sx={webStyles.fontStyle_5}>{configJSON.from}</Typography>
                              <Typography sx={webStyles.fontStyle_6}>{selectedCard.truckInfo.location_from}</Typography>
                            </Box>

                            <Box sx={webStyles.trackingDetails}>
                              <Typography sx={webStyles.fontStyle_5}>{configJSON.weight}</Typography>
                              <Typography sx={webStyles.fontStyle_6}>{selectedCard.truckInfo.weight}</Typography>
                            </Box>
                          </Box>

                          <Box sx={webStyles.boxColumn}>
                            <Box sx={webStyles.trackingDetails}>
                              <Typography sx={webStyles.fontStyle_5}>{configJSON.end_date}</Typography>
                              <Typography sx={webStyles.fontStyle_6}>{selectedCard.truckInfo.end_date}</Typography>
                            </Box>

                            <Box sx={webStyles.trackingDetails}>
                              <Typography sx={webStyles.fontStyle_5}>{configJSON.to}</Typography>
                              <Typography sx={webStyles.fontStyle_6}>{selectedCard.truckInfo.location_to}</Typography>
                            </Box>

                            <Box sx={webStyles.trackingDetails}>
                              <Typography sx={webStyles.fontStyle_5}>{configJSON.amount}</Typography>
                              <Typography sx={webStyles.fontStyle_6}>{selectedCard.truckInfo.amount}</Typography>
                            </Box>
                          </Box>
                        </Box>

                        <Box sx={webStyles.driverDetails}>
                          <Avatar sx={webStyles.driverPick} alt="#avataricon" src={selectedCard.avatarSrc} />
                          <Box sx={webStyles.nameContainer}>
                            <Typography sx={webStyles.fontSyle_5}>{configJSON.truckDriver}</Typography>
                            <Typography sx={webStyles.fontStyle_7}>{selectedCard.driverName}</Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box sx={webStyles.orderTrackingBox}>
                        <Typography sx={webStyles.fontStyle_6}>{"Tracking Status"}</Typography>
                        <Stepper
                          orientation="vertical"
                          connector={<CustomConnector />}
                        >
                          {selectedCard.steps.map((step, index) => (
                            <Step key={index} active={step.isCompleted} completed={step.isCompleted}>
                              <Box sx={webStyles.dateBox}>
                                <Typography sx={webStyles.dateFontSyle_1}>{step.date}</Typography>
                                <Typography sx={webStyles.dateFontSyle_2}>{step.time}</Typography>
                              </Box>
                              <StepLabel
                                icon={step.isCompleted ? <CircleIcon fontSize="small" /> : <CircleOutlinedIcon fontSize="small" />}
                              >
                                <Box>
                                  <Box>
                                    <Typography sx={webStyles.dateFontSyle_1}>{step.label}</Typography>
                                    <Typography sx={webStyles.dateFontSyle_2}>{step.location}</Typography>
                                  </Box>
                                </Box>
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderColor: theme.palette.mode === 'dark' ? '#E0E0E0' : '#BDBDBD',
    borderLeftWidth: 2,
    marginLeft: -3,
  },
}));

const webStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    overflowX: 'hidden'
  },
  content: {
    display: "flex",
    flex: 1,
  },
  mainContent: {
    flex: 1,
    padding: "2rem",
    backgroundColor: '#E2E8F0',
  },
  tabContainer: {
    backgroundColor: '#FFFFFF',
    border: '2px solid #215089',
    borderRadius: '16px',
    padding: '2rem',
  },
  infoBox: {
    width: '40%',
  },
  headerFont: {
    fontFamily: "Inter",
    fontSize: '30px',
    fontWeight: 700,
    color: "#215089"
  },
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
    display: 'flex',
    gap: '6px',
    marginTop: '2%',
  },
  tab: {
    width: '168px',
    padding: '6px 12px 6px 12px',
    borderRadius: '50px',
    fontFamily: "Inter",
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'initial',
    color: '#64748B',
    '&.Mui-selected': {
      backgroundColor: '#FBE1C4',
      color: '#B57730',
    },
  },
  tabs: {
    borderRadius: '50px',
    backgroundColor: '#BAC9DA',
    color: '#64748B',
    padding: '6px 12px 6px 12px',
  },
  cardStyle: {
    marginTop: '4%',
    boxShadow: '0px 4px 4px 0px #00000040',
    border: '1px solid #FBE1C4',
    borderRadius: '16px',
    cursor: 'pointer'
  },
  styledCardContainer: {
    width: '55%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  cardContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 20px 10px 20px'
  },
  fontSyle_1: {
    fontFamily: "Inter",
    fontSize: '18px',
    fontWeight: 700,
  },
  fontSyle_2: {
    fontFamily: "Inter",
    fontSize: '14px',
    fontWeight: 400,
    color: "#FFFFFF"
  },
  avatarContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  locationContainer: {
    display: 'flex',
    gap: '2px',
    alignItems: 'center'
  },
  avatarStyle: {
    height: '40px',
    width: '40px'
  },
  dividerStyle: {
    borderStyle: 'dotted',
    borderWidth: '1px',
    borderColor: '#BAC9DA',
  },
  boxPadding: {
    padding: '20px 20px 10px 20px'
  },
  fontSyle_3: {
    fontFamily: "Inter",
    fontSize: '16px',
    fontWeight: 400,
    color: "#FFFFFF"
  },
  fontSyle_4: {
    fontFamily: "Inter",
    fontSize: '18px',
    fontWeight: 700,
    color: "#FFFFFF"
  },
  fontSyle_5: {
    fontFamily: "Inter",
    fontSize: '18px',
    fontWeight: 400,
    color: "#FFFFFF"
  },
  statusBox: {
    backgroundColor: '#915F26',
    width: '198px',
    borderRadius: '0px 15px 0px 15px',
    height: '37px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  chatBoxStyle: {
    width: '130px',
    height: '40px',
    padding: '6px 10px 6px 10px ',
    backgroundColor: '#FBE1C4',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '5px'
  },
  infoBoxPadding: {
    padding: '4rem 5rem',
    backgroundColor: '#FBE1C4',
    marginTop: '2%',
    borderRadius: '16px',
  },
  trackingBoxStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
  },
  boxColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px'
  },
  trackingDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',
  },
  fontStyle_5: {
    fontFamily: "Inter",
    fontSize: '16px',
    fontWeight: 400,
    color: "#0F172A"
  },
  driverInfoCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px'
  },
  fontStyle_6: {
    fontFamily: "Inter",
    fontSize: '20px',
    fontWeight: 700,
    color: "#1C1917"
  },
  fontStyle_7: {
    fontFamily: "Inter",
    fontSize: '20px',
    fontWeight: 700,
    color: "#FFFFFF"
  },
  driverDetails: {
    backgroundColor: '#915F26',
    marginTop: '12%',
    padding: '10px',
    borderRadius: '50px',
    display: 'flex',
    gap: '1.5rem',
    alignItems: 'center'
  },
  driverPick: {
    width: '70px',
    height: '70px'
  },
  orderTrackingBox: {
    padding: '3rem 5rem',
    borderRadius: '16px',
    border: '2px solid #215089',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  CustomStepConnector: {
    height: '50px',
    borderLeft: '2px dotted #BAC9DA',
  },
  dateBox: {
    position: 'relative',
    right: '70px',
    top: '40px'
  },
  dateFontSyle_1: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#6E6E6E',
    fontFamily: "Inter"
  },
  dateFontSyle_2: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#BDBDBD',
    fontFamily: "Inter"
  },

}

// Customizable Area End
