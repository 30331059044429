import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Alert } from "react-native";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { OptionTypeBase, ValueType } from "react-select";

// Customizable Area Start
import moment from 'moment'

interface Field {
  key: keyof S;
  label: string;
  prefix?: string;
}

export interface CityList {
  name:string,
  code:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  PostData: [];
  token: string | null;
  name: string;
  description: string;
  currency: string;
  category_id: string;
  image: string | undefined;
  uploadedImages: [];
  AllCategory: [];
  id: string;
  refresh: boolean;
  file: string;
  profileImageData: { data: string | null | undefined, content_type: string, filename: string };
  selectedCategory: ValueType<OptionTypeBase, false>;
  allCategories: { value: string, label: string }[];


  weight: string;
  miles: string;
  commodity: string;
  originAddress: string;
  originCity: string;
  originState: CityList | null;
  originCountry: string;
  originZipcode: string;
  desAddress: string;
  desCity: string;
  desState: CityList | null;
  desCountry: string;
  desZipcode: string;
  startDate: Date | null;
  endDate: Date | null;
  rate: string;
  creditScore: string;
  phoneNo: string;
  email: string;
  loadDetails: string;
  errorMsg: {
    [key: string]: string;
  }
  [key: string]: any;
  showModal:boolean;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PostCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostItemCallId: string = "";
  apiGetCategoryCallID: string = "";
  PostApiCallId: string = "";
  DeleteApiCallId: string = "";;
  addpostApiCallId: string = "";;
  updatePostApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {

      token: localStorage.getItem('LoginToken'),
      PostData: [],
      name: "",
      description: "",
      currency: "$",
      category_id: "",
      image: "",
      id: "",
      uploadedImages: [],
      AllCategory: [],
      file: "",
      refresh: false,
      profileImageData: { data: null, content_type: "", filename: "" },
      selectedCategory: {},
      allCategories: [],

      weight: '',
      miles: '',
      commodity: '',
      originAddress: '',
      originCity: '',
      originState: null,
      originCountry: 'United States',
      originZipcode: '',
      desAddress: '',
      desCity: '',
      desState: null,
      desCountry: 'United States',
      desZipcode: '',
      startDate: null,
      endDate: null,
      rate: '',
      creditScore: '',
      phoneNo: '',
      email: '',
      loadDetails: '',
      errorMsg: {},
      showModal: false,
    };
    // Customizable Area End
    console.disableYellowBox = true;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    var authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(authTokenReq);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (responseJson && responseJson.errors) {
        const backendErrors = responseJson.errors;
        this.setBackendError(backendErrors)

      } else if (responseJson) {
        if (apiRequestCallId === this.addpostApiCallId) {
          this.setState({
            showModal:true,
            weight: '',
            miles: '',
            commodity: '',
            originAddress: '',
            originCity: '',
            originState: null,
            originCountry: '',
            originZipcode: '',
            desAddress: '',
            desCity: '',
            desState: null,
            desCountry: '',
            desZipcode: '',
            startDate: null,
            endDate: null,
            rate: '',
            creditScore: '',
            phoneNo: '',
            email: '',
            loadDetails: '',
            errorMsg: {},
          })
        }
      }


    }
  }

  setBackendError(err: {
    [key: string]: string;
  }) {
    if (err.start_date) {
      this.setState((prevState) => ({
        errorMsg: { ...prevState.errorMsg, startDateErrorMsg: err.start_date }
      }));
    }
    
    if (err.price) {
      this.setState((prevState) => ({
        errorMsg: { ...prevState.errorMsg, rateErrorMsg: err.price }
      }));
    }
    if (err.load_broker_phone_number) {
      this.setState((prevState) => ({
        errorMsg: { ...prevState.errorMsg, phoneNoErrorMsg: err.load_broker_phone_number }
      }));
    }
    if (err.load_weight) {
      this.setState((prevState) => ({
        errorMsg: { ...prevState.errorMsg, weightErrorMsg: err.load_weight }
      }));
    }
    if (err.total_miles) {
      this.setState((prevState) => ({
        errorMsg: { ...prevState.errorMsg, milesErrorMsg: err.total_miles }
      }));
    }
    if (err.origin_address_address) {
      this.setState((prevState) => ({
        errorMsg: { ...prevState.errorMsg, originAddressErrorMsg: err.origin_address_address }
      }));
    }
    if (err.destination_address_address) {
      this.setState((prevState) => ({
        errorMsg: { ...prevState.errorMsg, desAddressErrorMsg: err.destination_address_address }
      }));
    }

    if (err.origin_address_city) {
      this.setState((prevState) => ({
        errorMsg: { ...prevState.errorMsg, originCityErrorMsg: err.origin_address_city }
      }));
    }

    if (err.destination_address_city) {
      this.setState((prevState) => ({
        errorMsg: { ...prevState.errorMsg, desCityErrorMsg: err.destination_address_city }
      }));
    }

    if (err.origin_address_state) {
      this.setState((prevState) => ({
        errorMsg: { ...prevState.errorMsg, originStateErrorMsg: err.origin_address_state}
      }));
    }

    if (err.destination_address_state) {
      this.setState((prevState) => ({
        errorMsg: { ...prevState.errorMsg, desStateErrorMsg: err.destination_address_state}
      }));
    }

    if (err.origin_address_country) {
      this.setState((prevState) => ({
        errorMsg: { ...prevState.errorMsg, originCountryErrorMsg: err.origin_address_country }
      }));
    }
    if (err.destination_address_country) {
      this.setState((prevState) => ({
        errorMsg: { ...prevState.errorMsg, desCountryErrorMsg: err.destination_address_country }
      }));
    }

    if (err.origin_address_zip_code) {
      this.setState((prevState) => ({
        errorMsg: { ...prevState.errorMsg, originZipcodeErrorMsg: err.origin_address_zip_code }
      }));
    }

    if (err.destination_address_zip_code) {
      this.setState((prevState) => ({
        errorMsg: { ...prevState.errorMsg, desZipcodeErrorMsg: err.destination_address_zip_code }
      }));
    }
  }

  validateFields(): boolean {
    const {
      startDate, endDate
    } = this.state;

    const requiredFields: Field[] = [
      { key: 'weight', label: 'Weight' },
      { key: 'miles', label: 'Miles' },
      { key: 'commodity', label: 'Commodity' },
      { key: 'originAddress', label: 'Address', prefix: 'origin' },
      { key: 'originCity', label: 'City', prefix: 'origin' },
      { key: 'originState', label: 'State', prefix: 'origin' },
      { key: 'originCountry', label: 'Country', prefix: 'origin' },
      { key: 'originZipcode', label: 'Zipcode', prefix: 'origin' },
      { key: 'desAddress', label: 'Address', prefix: 'des' },
      { key: 'desCity', label: 'City', prefix: 'des' },
      { key: 'desState', label: 'State', prefix: 'des' },
      { key: 'desCountry', label: 'Country', prefix: 'des' },
      { key: 'desZipcode', label: 'Zipcode', prefix: 'des' },
      { key: 'startDate', label: 'Start date' },
      { key: 'endDate', label: 'End date' },
      { key: 'rate', label: 'Rate' },
      { key: 'creditScore', label: 'Credit score' },
      { key: 'phoneNo', label: 'Phone number' },
      { key: 'email', label: 'Email' },
      { key: 'loadDetails', label: 'Load Details' }
    ];

    const errors = requiredFields.reduce((acc: { [key: string]: string }, field: Field) => {
      const fieldValue = this.state[field.key];
      if (fieldValue === "") {
        const errorKey = field.prefix ? `${field.prefix}${field.label}ErrorMsg` : `${field.key}ErrorMsg`;
        acc[errorKey] = `${field.label} is required`;
      }
      return acc;
    }, {});

    let isValid = Object.keys(errors).length === 0;

    if (isValid && startDate && endDate && startDate > endDate) {
      errors.endDateErrorMsg = "End date should be greater than Start date";
      isValid = false;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(this.state.email)) {
      errors.emailErrorMsg = "Please enter a valid email address";
      isValid = false;
    }

    if(this.state.creditScore && this.state.creditScore.length !== 3){
      errors.creditScoreErrorMsg = "Creadit score should be of 3 digits"
      isValid = false;
    }

    this.setState({ errorMsg: errors });
    return isValid;
  }

  formateDate = (date: Date|null, formate: string) => {
    return moment(date).format(formate)
  }

  createPostCreation() {

    if(!this.validateFields()){
      return
    }

    this.AddPostCreation();

  }

  goToItemDetails(item: { attributes: { name: string, id: string } } | null, isEdit: boolean) {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      isEdit ? "PostCreation" : "PostDetails"
    );

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(getName(MessageEnum.PostDetailDataMessage), item);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  }

  editNavigation = (item: { attributes: { name: string, id: string } }) => {
    this.goToItemDetails(item, true);
  };

  navigateToDetails = (item: { attributes: { name: string, id: string } }) => {
    this.goToItemDetails(item, false);
  };

  AddPostCreation(): boolean {
    const { weight, miles, email, commodity, originAddress, loadDetails, originCity, originState, originCountry, originZipcode,
      desAddress, desCity, desState, desCountry, desZipcode, startDate, endDate, rate, creditScore, phoneNo
    } = this.state;

    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token,
    };

    const origin_address = {
      address: originAddress,
      city: originCity,
      state: originState?.code,
      zip_code: originZipcode,
      country: originCountry
    }

    const destination_address = {
      address: desAddress,
      city: desCity,
      state: desState?.code,
      zip_code: desZipcode,
      country: desCountry
    }

    const attrs = {
      data: {
        load_weight: `${weight} ton`,
        total_miles: `${miles} KM`,
        commodity: commodity,
        email: email,
        load_details: loadDetails,
        origin_address: origin_address,
        destination_address: destination_address,
        start_date: startDate,
        end_date: endDate,
        price: rate,
        load_broker_credit_score: creditScore,
        load_broker_phone_number: phoneNo
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addpostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getAllCategory() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCategoryCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCatergoryEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPostData(): boolean {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateCreatePostData(recordId: string) {
    if (
      this.state.category_id === "" ||
      this.state.description === "" ||
      this.state.name === "" ||
      this.state.rate === ""
    ) {
      this.showAlert(configJSON.Error, configJSON.FieldsErrorMassage);
      return false;
    } else {
      const header = {
        "Content-Type": configJSON.postContentType,
        token: this.state.token
      };
      const attrs = {
        name: this.state.name,
        description: this.state.description,
        body: this.state.description,
        sub_category_id: 1,
        price: this.state.rate,
        currency: "$",
        category_id: this.state.category_id,
        image: this.state.profileImageData
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updatePostApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postGetUrl + "/" + `${recordId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(attrs)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchPostAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  deleteRecord(recordId: string) {
    Alert.alert(
      configJSON.Warning,
      configJSON.MessageForDelete,
      [
        { text: "No", onPress: () => { }, style: "cancel" },
        {
          text: "Yes",
          onPress: () => {
            this.setState({ refresh: true });
            this.delete(recordId);
          }
        }
      ],
      { cancelable: false }
    );
  }

  delete(recordId: string) {
    this.setState({ refresh: true });
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl + "/" + `${recordId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.getPostData();
    return true;
  }

  txtInputProductNameProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ name: text });
      this.txtInputProductNameProps.value = text;
    }
  };

  txtInputProductDiscripationProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ description: text });
      this.txtInputProductDiscripationProps.value = text;
    }
  };

  txtInputProductPriceProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ price: text });
      this.txtInputProductPriceProps.value = text;
    }
  };

  chooseImage = () => {
    this.showAlert("Error", "Image Picker Not Implemented");
  };

  handleCatChangeChange = (selectedOption: ValueType<OptionTypeBase, false>) => {
    this.setState({
      selectedCategory: selectedOption,
      category_id: selectedOption?.value
    });
  };

  handleInputChange = (name: string, value: string) => {

    if(name === "originZipcode" || name === "desZipcode" || name === "creditScore"){
      if(!(/^\d*$/.test(value))) {
         return;
      }
    }

    this.setState((prevState) => ({
      [name]: value,
      errorMsg: { ...prevState.errorMsg, [`${name}ErrorMsg`]: '' },
    }))
  };

  handleOriginCityChange = (event: React.ChangeEvent<{}>, newValue: CityList | null) =>{
    newValue && this.setState((prevState) => ({
      originState: newValue,
      errorMsg: { ...prevState.errorMsg, [`originStateErrorMsg`]: '' },
    }))
  }

  handleDestinationCityChange = (event: React.ChangeEvent<{}>, newValue: CityList | null) =>{

    newValue && this.setState((prevState) => ({
      desState: newValue,
      errorMsg: { ...prevState.errorMsg, [`desStateErrorMsg`]: '' },
    }))
  }

  handleDateChange = (name: string, value: string) => {
    this.setState((prevState) => ({
      [name]: value,
      errorMsg: { ...prevState.errorMsg, [`${name}ErrorMsg`]: '' },
    }))
  };

  closeModal = () => {
    this.setState({showModal:false})
  }

  // Customizable Area End
}
