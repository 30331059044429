import React from "react";

// Customizable Area Start
import { View, StyleSheet,TextStyle,ViewStyle } from "react-native";

import Box from '@mui/system/Box';
import { AuthPageTitle } from "../../../components/src/AuthPageTitle.web";
import CustomButton from "../../../components/src/CustomButton.web.";
import UnauthLayout from "../../../components/src/UnauthLayout.web";
import { Input, Button} from '@builder/component-library';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CheckIcon from '@mui/icons-material/Check';
const config = require('./config')


const newStyles : { [key: string]: React.CSSProperties }  = {
  
  inputText: { fontSize: 16, fontFamily: 'Inter', fontWeight: '400'},
  actionWrapper: { 
    marginBottom: '24px', 
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center', 
    justifyContent: 'space-between'
  },
  normalText:{
    fontSize:'16px',
    fontWeight:400,
    color:'#0F172A',
    fontFamily:"Montserrat",
    width:"89%",
    lineHeight:'24px'
  },
  mainTitle:{
    fontWeight: 700,
    fontSize:'20px',
    fontFamily: 'Inter',
    marginTop:'60%',
    height:'30px',
    lineHeight:'24px'
    },

    successTitile:{
        fontSize:'36px',
        fontWeight:700,
        fontFamily:"Arial, Helvetica",
    },
    newBox:{
      textAlign:'center',
      display:'flex',
      flexDirection:'column',
      justifyContent:'space-evenly',
      alignItems:'center',
      gap:'30px',
      marginTop:'50%'
    },
    newText:{
      marginTop:'40px',
      fontFamily:"Arial, Helvetica",
      fontSize:'20px',
      fontWeight:400,
      lineHeight:'35px',
      width:'70%'
    } ,
    btn:{
      width:'327px',
      height:'56px',
      marginTop:'30px'
    }
    ,
    inputContainer: { 
      height: '56px', 
      borderRadius: 8, 
      borderStyle: 'solid', 
    },

}
// Customizable Area End

import ResetPasswordController, {
  Props,
} from "./ResetPasswordController";

export default class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <UnauthLayout currentWidth={this.state.width}>
               { !this.state.updateSuccess &&
               (
                <>
                <Box component="h2" sx={newStyles.mainTitle}>
                    Reset Password
                </Box>
                <Box sx={newStyles.normalText} >
                    {config.ResetPasswordTxt}
            </Box>
        <View style={{ marginTop: '22px' }}>
          <View style={{ marginBottom: '16px' }}>
          <AuthPageTitle>New Password</AuthPageTitle>
            <Input
              data-test-id="txtInputPassword"
              secureTextEntry={this.state.enablePasswordField}
              placeholder={"Your password"}
              borderColor="#CBD5E1"
              borderErrorColor="#F87171"
              borderHighlightColor="#FBE1C4"
              placeholderTextColor="#94A3B8"
              style={{ flex: 1, outline: 'none' } as TextStyle}
              containerStyle={newStyles.inputContainer as ViewStyle}
              textStyle={newStyles.inputText as TextStyle}
              value={this.state.password}
              error={this.state.passwordErrMessage ? this.state.passwordErrMessage : ""}
              errorStyle={{ fontFamily:'Inter',fontSize:12,margin:1,marginTop:5  }}
              onChangeText={(text) => this.handleChangePassword(text)}
              rightIcon={
                <Button
                  data-test-id="btnTogglePassword"
                  aria-label="toggle password visibility"
                  onPress={this.handleClickShowPassword}
                  style={[styles.removeBackground]}
                  textStyle={{ color: '#94A3B8' }}
                  icon={this.state.enablePasswordField ? (
                    <VisibilityOffOutlinedIcon style={{ color: "#94A3B8" }} />
                  ) : (
                    <RemoveRedEyeOutlinedIcon style={{ color: "#94A3B8" }} />
                  )}
                />
              }
            />
          </View>

          <View style={{ marginBottom: '16px' }}>
          <AuthPageTitle>Confirm Password</AuthPageTitle>
            <Input
              data-test-id="txtInputConfirmPassword"
              secureTextEntry={this.state.enableConfirmField}
              placeholder={"Your password"}
              borderColor="#CBD5E1"
              borderErrorColor="#F87171"
              borderHighlightColor="#FBE1C4"
              placeholderTextColor="#94A3B8"
              style={{ flex: 1, outline: 'none' } as TextStyle}
              containerStyle={newStyles.inputContainer as ViewStyle}
              textStyle={newStyles.inputText as TextStyle}
              value={this.state.confirmPassword}
              error={this.state.confirmPasswordError ? this.state.confirmPasswordError : ""}
              errorStyle={{ fontFamily:'Inter',fontSize:12,margin:1,marginTop:5  }}
              onChangeText={(text) => this.hanldeChangeConfirmPassword(text)}
              rightIcon={
                <Button
                  data-test-id="btnTogglePassword"
                  aria-label="toggle password visibility"
                  onPress={this.handleClickShowConfirmPassword}
                  style={[styles.removeBackground]}
                  textStyle={{ color: '#94A3B8' }}
                  icon={this.state.enableConfirmField ? (
                    <VisibilityOffOutlinedIcon style={{ color: "#94A3B8" }} />
                  ) : (
                    <RemoveRedEyeOutlinedIcon style={{ color: "#94A3B8" }} />
                  )}
                />
              }
            />
          </View>

          <Box sx={newStyles.actionWrapper}>
            
          </Box>
          <CustomButton 
            testId="resetButton" 
            onClick={this.newPass} 
            text="RESET PASSWORD"
          />
        </View>
        </>)}
        {
          this.state.updateSuccess &&
          (
            <>
              <Box sx={newStyles.newBox}>
              <Box component="h2" sx={newStyles.successTitile}>
                Success !
              </Box>
              <Box sx={newStyles.tickicons}>
                <CheckIcon style={{color:'white',width:'74px',height:'74px',border:'2px solid #34D399',borderRadius:'40px',background:'#34D399'}} />
              </Box>
              <Box sx={newStyles.newText}>
                  Your password has been successfully changed.
              </Box>

              <Box sx={newStyles.btn}>
                <CustomButton
                  testId="btnForgotP"
                  onClick={this.passwordChanged}
                  text="CONTINUE"
                  />
                  </Box>
              </Box>
            </>
          )

        }

      </UnauthLayout>
          
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  main: {
    display: "flex",
    alignItems: "center"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  errorText: {
    color: '#DC2626',
    fontSize:12,
    fontFamily:'Arial, Helvetica',
    marginTop:3
  },

});
